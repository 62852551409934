import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, Grid, InputAdornment, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";

import Modal from "@/components/Modal";
import ChipSelect from "@/components/ChipSelect";
import Button from "@/components/Button";
import DateSelect from "@/components/DateSelect";
import GetImages from "@/components/GetImages";
import RetakeSaveCaptureImage from "@/components/RetakeSaveCaptureImage";
import {
  AppointmentTab,
  AppointmentTabPanel,
  AppointmentTabsPanel,
} from "@/components/AppointmentTab";
import DropDownSelect from "@/components/DropDownSelect";
import { InputField } from "@/components/InputField";

import style from "./index.module.css";

import { IButtonVariant, IChipVariant } from "@/models/button";
import {
  IHealthCategory,
  IUploadFileToS3ResultDetail,
} from "@/models/ApiRequestResponse/doctor";
import {
  ICreateMedicalReportEnum,
  IDropDownSelectionType,
  IDocumentTypeEnum,
} from "@/models/doctor";
import { IMemberListDetail } from "@/models/ApiRequestResponse/patient";

import {
  fetchMedicalRecordList,
  resetMedicalReport,
  setMedicalReport,
} from "@/store/slices/doctor/medicalRecord";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchPrescriptionConfig,
  setIsCameraOn,
  setUploadedS3URL,
} from "@/store/slices/doctor/prescription";
import {
  fetchPatientMembers,
  setMemberModalOpenClose,
} from "@/store/slices/patient/member";
import { fetchPatientMedicalRecords } from "@/store/slices/patient/medicine";
import { setShowPermissionAlert } from "@/store/slices/permission";
import {
  addMedicalRecordIds,
  fetchSearchDiagnosticOrdersList,
  saveSelectedOrder,
  updateOrderList,
} from "@/store/slices/pharmacyClinicDiagnostic";

import {
  saveMedicalRecordApi,
  uploadFileToS3Api,
} from "@/utils/apis/doctor/prescription";
import { INotifyEnum, notify } from "@/utils/toaster";
import { convertDateYYYYMMDD, getPermissionAccess } from "@/utils";
import { updateMemberApi } from "@/utils/apis/patient/profile";
import { storage } from "@/utils/Storage";
import useIsMobile from "@/utils/hooks/useIsMobile";
import useIsTablet from "@/utils/hooks/useIsTablet";

import { IUploadFileType } from "./types";

import {
  IModalKeyEnums,
  IRolePermissionAccess,
  IUploadDocumentEnums,
} from "@/models/permission";
import { IRoleType } from "@/models/role";
import { IPharmacyDiagnosticOrderDetail } from "@/models/ApiRequestResponse/diagnostic";
import { IOrderStatusEnum } from "@/models/ApiRequestResponse/pharmacy";

const tabItems = [
  {
    type: IDocumentTypeEnum.PRESCRIPTION,
    title: "Prescription",
    selectedIcon: "PrescriptionSmallIcon",
    defaultIcon: "PrescriptionDefaultSmallIcon",
  },
  {
    type: IDocumentTypeEnum.REPORT,
    title: "Test Report",
    selectedIcon: "ReportSelectedSmallIcon",
    defaultIcon: "ReportDefaultSmallIcon",
  },
];

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  memberId: string;
  showOnlyPrescription?: boolean;
  onComplete?: (reports: Record<string, any>) => void;
  screen?: ICreateMedicalReportEnum;
}

const CreateMedicalReport: React.FC<IProps> = (props) => {
  const {
    isOpen,
    closeHandler,
    memberId,
    showOnlyPrescription = false,
    onComplete,
    screen,
  } = props;

  const [selectedMember, setSelectedMember] = useState<string>(memberId);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const isWebView = useMemo(
    () => !(isMobile || isTablet),
    [isMobile, isTablet]
  );
  const dispatch = useAppDispatch();
  const { config, uploadedS3URL } = useAppSelector(
    (state) => state.prescription
  );
  const { medicalReport } = useAppSelector((state) => state.medicalRecords);
  const { isMemberModalOpenClose, list } = useAppSelector(
    (state) => state.member
  );
  const { permission } = useAppSelector((state) => state.rolePermission);
  const {
    order,
    list: orderList,
    searchKeyword,
  } = useAppSelector((state) => state.pharmacyClinicDiagnostic);
  const { url, thumbnailUrl } = uploadedS3URL;

  const [isCapturedSave, setIsCapturedSave] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(
    medicalReport?.selectedTab ?? 0
  );
  const [selectedMemberName, setSelectedMemberName] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");

  const roleType = storage.getSessionToken("roleType");

  const medicalRecordFileUploadBtnsList = [
    {
      type: IUploadFileType.PDF,
      acceptType: ".pdf",
      label: "Upload PDF",
      icon: "PDFSmallIcon",
      handler: () => {},
    },
    {
      type: IUploadFileType.IMAGE,
      acceptType: "image/png, image/jpeg",
      label: "Upload Image",
      icon: "GallerySmallIcon",
      handler: () => {},
    },
    {
      type: IUploadFileType.CAMERA,
      acceptType: null,
      label: "Open Camera",
      icon: "CameraSmallIcon",
      handler: () => dispatch(setIsCameraOn()),
    },
  ];

  const medicalRecordsBtnWeb = medicalRecordFileUploadBtnsList.filter(
    (item) => item.type !== IUploadFileType.CAMERA
  );

  const handleAppointmentTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTab(newValue);
  };

  const receivedUploadedImageUrlHandler = (
    url: IUploadFileToS3ResultDetail
  ) => {
    dispatch(setUploadedS3URL(url));
    setIsCapturedSave(!isCapturedSave);
  };

  const createMedicalRecordHandler = async () => {
    const createPrescriptionResponse = await saveMedicalRecordApi({
      memberId,
      url,
      ...(thumbnailUrl ? { thumbnailUrl: thumbnailUrl } : {}),
      healthCategoryId: medicalReport?.healthCategoryId ?? selectedValue,
      documentDate: convertDateYYYYMMDD(medicalReport?.date?.$d),
      ...(roleType === IRoleType.DIAGNOSTIC_CENTER
        ? { type: IDocumentTypeEnum.REPORT }
        : {
            type:
              selectedTab === 0
                ? IDocumentTypeEnum.PRESCRIPTION
                : IDocumentTypeEnum.REPORT,
          }),
      ...(order?.id ? { bookingId: order?.id } : {}),
    });
    if ("result" in createPrescriptionResponse) {
      notify(INotifyEnum.SUCCESS, createPrescriptionResponse?.message);
      dispatch(fetchMedicalRecordList({ memberId: memberId }));
      dispatch(resetMedicalReport());
      if (searchKeyword) {
        dispatch(fetchSearchDiagnosticOrdersList({ keyword: searchKeyword }));
      }
      onComplete?.(createPrescriptionResponse.result);
      if (roleType !== IRoleType.DIAGNOSTIC_CENTER && showOnlyPrescription) {
        dispatch(fetchPatientMedicalRecords());
      } else {
        const newList = orderList?.map(
          (orderDetail: IPharmacyDiagnosticOrderDetail) => {
            if (orderDetail.id === order?.id) {
              return {
                ...orderDetail,
                status:
                  orderDetail.status === IOrderStatusEnum.COMPLETED
                    ? IOrderStatusEnum.UPLOADED
                    : orderDetail.status,
                reports: [
                  createPrescriptionResponse?.result,
                  ...(order?.reports || []),
                ],
              };
            }
            return orderDetail;
          }
        );
        dispatch(updateOrderList(newList));

        dispatch(addMedicalRecordIds(createPrescriptionResponse?.result?.id));
        dispatch(
          saveSelectedOrder({
            ...order,
            reports: [
              createPrescriptionResponse?.result,
              ...(order?.reports || []),
            ],
          })
        );
      }
      dispatch(setUploadedS3URL(""));
    } else {
      notify(INotifyEnum.ERROR, createPrescriptionResponse?.message);
    }
    closeHandler();
  };

  const pdfImageUploadHandler = (type: string) => {
    document.getElementById(type)?.click();
  };

  const uploadFileToS3 = async (
    type: IDocumentTypeEnum,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setIsFileUploading(true);
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.UPLOAD_DOCUMENT,
        IUploadDocumentEnums.UPLOAD_TO_S3,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      const file = event.target.files?.[0];
      const formData = new FormData();
      formData.append("file", file!);
      const uploadFileToS3Response = await uploadFileToS3Api(type, formData);

      if ("result" in uploadFileToS3Response) {
        const { thumbnailUrl, url } = uploadFileToS3Response?.result;
        receivedUploadedImageUrlHandler({ thumbnailUrl, url });
      }
    } finally {
      setIsFileUploading(false);
    }
  };

  const openCloseMemberModalHandler = () => dispatch(setMemberModalOpenClose());

  const saveSelectedMemberValues = async (
    value: string,
    type: IDropDownSelectionType
  ) => {
    openCloseMemberModalHandler();
    setSelectedMember(value);

    setSelectedMemberName(
      list?.filter((item) => item?.id === value)[0]?.name ?? ""
    );

    const updateMemberResponse = await updateMemberApi(value, {
      is_default: true,
    });
    if ("result" in updateMemberResponse) {
      dispatch(fetchPatientMembers());
      notify(INotifyEnum.SUCCESS, updateMemberResponse?.message);
    } else {
      notify(INotifyEnum.ERROR, updateMemberResponse?.message);
    }
    return;
  };

  const handleClose = () => {
    setSelectedMemberName("");
    dispatch(resetMedicalReport());
    dispatch(setUploadedS3URL(""));
    closeHandler();
  };

  const chooseMemberInput = () => (
    <Grid item className={style.memberContainer}>
      <InputField
        type={"text"}
        label={"Choose Member"}
        placeholder={"Choose Member"}
        disabled
        value={selectedMemberName}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography
                component={"p"}
                className={style.selectText}
                onClick={openCloseMemberModalHandler}
              >
                <FormattedMessage id="select" defaultMessage="Select" />
              </Typography>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </Grid>
  );

  const isButtonDisabled = useMemo(() => {
    return !(medicalReport.healthCategoryId && medicalReport.date && url);
  }, [medicalReport, selectedMemberName, url]);

  const reportFileUpload = useCallback(() => {
    const medicalRecordFileUploadBtns = isWebView
      ? medicalRecordsBtnWeb
      : medicalRecordFileUploadBtnsList;

    return (
      <Grid item>
        <DateSelect
          value={medicalReport?.date}
          onChange={(event: any) =>
            dispatch(setMedicalReport({ identifier: "date", value: event }))
          }
          maxDate={dayjs()}
        />
        {roleType !== IRoleType.DIAGNOSTIC_CENTER &&
          showOnlyPrescription &&
          chooseMemberInput()}
        <Grid item className={style.healthCategoryContainer}>
          <Typography component={"p"}>Health categories</Typography>
          <ChipSelect
            showSelectedChip={false}
            variant={IChipVariant.ROUNDED}
            data={config?.healthCategory?.map((category: IHealthCategory) => ({
              label: (
                <Grid item className={style.categoryContainer}>
                  <img
                    src={category.icon}
                    alt="Category icon"
                    width={22}
                    height={16}
                  />
                  {category.name}
                </Grid>
              ),
              value: category.id,
            }))}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </Grid>

        <Grid item className={style.addReportContainer}>
          <Typography component={"p"}>Add File</Typography>
          <Grid item className={style.uploadReportBtns}>
            {url?.length > 0 ? (
              <Grid item className={style.uploadedFileContainer}>
                <Grid
                  item
                  className={style.closeIconContainer}
                  onClick={() => dispatch(setUploadedS3URL(""))}
                >
                  <GetImages name="CloseSmallIcon" width="12" height="12" />
                </Grid>
                <img
                  src={thumbnailUrl ?? url}
                  alt={"Uploaded Doc"}
                  width={105}
                  height={75}
                />
              </Grid>
            ) : (
              medicalRecordFileUploadBtns.map((btn, index) => (
                <>
                  {btn.type === IUploadFileType.CAMERA ? (
                    <Button
                      variant={IButtonVariant.WHITE}
                      key={index}
                      btnTrigger={btn.handler}
                    >
                      <GetImages name={btn.icon} width="26" height="20" />
                      {btn.label}
                    </Button>
                  ) : (
                    <>
                      <input
                        id={btn.type}
                        type="file"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          uploadFileToS3(
                            selectedTab === 0
                              ? IDocumentTypeEnum.PRESCRIPTION
                              : IDocumentTypeEnum.REPORT,
                            event
                          )
                        }
                        accept={btn.acceptType!}
                        style={{ display: "none" }}
                      />
                      <Button
                        variant={IButtonVariant.WHITE}
                        key={index}
                        btnTrigger={() => pdfImageUploadHandler(btn.type)}
                      >
                        <GetImages name={btn.icon} width="26" height="20" />
                        {btn.label}
                      </Button>
                    </>
                  )}
                </>
              ))
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Button
            disabled={isButtonDisabled}
            isLoading={isFileUploading}
            btnTrigger={createMedicalRecordHandler}
          >
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>
        </Grid>
      </Grid>
    );
  }, [
    selectedTab,
    selectedValue,
    config,
    uploadedS3URL,
    selectedMember,
    selectedMemberName,
    isWebView,
    isFileUploading,
    isButtonDisabled,
  ]);

  const getMainBody = () => (
    <Grid item className={style.container}>
      <Typography component={"p"}>
        {screen === ICreateMedicalReportEnum.MEDICINE
          ? "Add New Prescription"
          : "Add New Medical Record"}
      </Typography>
      <Grid item className={style.tabSwitchContainer}>
        {!showOnlyPrescription && (
          <AppointmentTabsPanel
            value={selectedTab}
            onChange={handleAppointmentTab}
            aria-label="basic tabs example"
            className={style.tabBtnContainer}
          >
            {tabItems.map((tab, index) => (
              <AppointmentTabPanel
                label={
                  <Grid item className={style.tabInnerContainer}>
                    <GetImages
                      name={
                        selectedTab === index
                          ? tab.selectedIcon
                          : tab.defaultIcon
                      }
                      width="20"
                      height="20"
                    />
                    <Typography component={"p"}>{tab.title}</Typography>
                  </Grid>
                }
              />
            ))}
          </AppointmentTabsPanel>
        )}
      </Grid>
      {tabItems.map((tab, index) => (
        <AppointmentTab key={`tab_${index}`} value={selectedTab} index={index}>
          {reportFileUpload()}
        </AppointmentTab>
      ))}
    </Grid>
  );

  useEffect(() => {
    if (Object.keys(config).length === 0) {
      dispatch(fetchPrescriptionConfig());
    }
  }, []);

  useEffect(() => {
    if (selectedValue?.length === 0) {
      setSelectedValue(medicalReport?.healthCategoryId);
      return;
    }
    dispatch(
      setMedicalReport({
        identifier: "healthCategoryId",
        value: selectedValue,
      })
    );
  }, [selectedValue]);

  useEffect(() => {
    if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
      // select the report tab for diagnostic center while creating the report
      setSelectedTab(1);
    }
  }, [roleType]);

  if (medicalReport?.isCapturedSave) {
    return (
      <RetakeSaveCaptureImage
        type={
          selectedTab === 0
            ? IDocumentTypeEnum.PRESCRIPTION
            : IDocumentTypeEnum.REPORT
        }
        imageUrl={medicalReport?.image}
        uploadedS3URL={receivedUploadedImageUrlHandler}
      />
    );
  }

  if (isWebView) {
    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          {getMainBody()}
        </Dialog>
        {isMemberModalOpenClose && (
          <DropDownSelect
            btnLabel="Save"
            type={IDropDownSelectionType.CHANGE_NAME}
            isOpen={isMemberModalOpenClose}
            closeHandler={openCloseMemberModalHandler}
            title="Select Member"
            values={list?.map((member: IMemberListDetail, index: number) => ({
              label: `${member.name}(${member.age!})`,
              value: member.id,
            }))}
            selectedValue={selectedMember}
            saveSelectedValues={saveSelectedMemberValues}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} closeHandler={closeHandler}>
        {getMainBody()}
      </Modal>
      {isMemberModalOpenClose && (
        <DropDownSelect
          btnLabel="Save"
          type={IDropDownSelectionType.CHANGE_NAME}
          isOpen={isMemberModalOpenClose}
          closeHandler={openCloseMemberModalHandler}
          title="Select Member"
          values={list?.map((member: IMemberListDetail, index: number) => ({
            label: `${member.name}(${member.age!})`,
            value: member.id,
          }))}
          selectedValue={selectedMember}
          saveSelectedValues={saveSelectedMemberValues}
        />
      )}
    </>
  );
};

export default CreateMedicalReport;
