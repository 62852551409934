import React from "react";
import { Box, Grid, styled, Tab, Tabs, Typography } from "@mui/material";

import style from "./index.module.css";

const DateSelectTabs = styled(Tabs)(() => ({
  margin: "0rem 1.6rem",
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const DateSelectTab = styled(Tab)(({ theme }) => ({
  fontSize: "1.4rem",
  fontWeight: 500,
  padding: "0rem",
  textTransform: "none",
  minWidth: "6.3rem",
  "&.Mui-selected": {
    color: "var(--black-900)",
    borderBottom: "0.4rem solid var(--orange-500)",
  },
  [theme.breakpoints.up("sm")]: {
    minWidth: "8.3rem",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "6.3rem",
  },
}));

interface IDateSelection {
  date: string;
  day: string;
  isAvailable: boolean;
}

interface IProps {
  selectedDateTab: number;
  data: IDateSelection[];
  dateSelectionHandler: (event: React.SyntheticEvent, newValue: number) => void;
}

const DateSelectionTab: React.FC<IProps> = (props) => {
  const { data, selectedDateTab, dateSelectionHandler } = props;

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <DateSelectTabs
        value={selectedDateTab}
        onChange={(event, value) => dateSelectionHandler(event, value)}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
      >
        {data.map((info, index) => (
          <DateSelectTab
            label={
              <Grid item>
                <Typography component={"p"} className={style.dayText}>
                  {info.day}
                </Typography>
                <Typography
                  component={"p"}
                  className={`${style.dateText} ${
                    !info?.isAvailable && style.unavailable
                  }`}
                >
                  {info.date}
                </Typography>
              </Grid>
            }
            key={index}
          />
        ))}
      </DateSelectTabs>
    </Box>
  );
};

export default DateSelectionTab;
