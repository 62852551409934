export const devBaseUrl = "https://gbsc-dev-api.appskeeper.in/";
export const devBasicAuth = {
  username: "test",
  password: "password",
};

export const mapUrl = "https://www.google.com/maps?q=";
export const openStreetMapUrl = "https://nominatim.openstreetmap.org/reverse";

export const socialPlatformLinks = {
  instagram: "https://www.instagram.com/thebrainspineapp/?hl=en",
  facebook: "https://www.facebook.com/thebrainspineapp/",
  youtube: "https://www.youtube.com/channel/UCUB2kt9x1aKEob8jDOdLAOw",
  linkedin:
    "https://www.linkedin.com/posts/the-brain-spine-app-418969229_activity-7262047383499255808-TSR_",
};
