import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  SxProps,
  Theme,
} from "@mui/material";

import style from "./index.module.css";

import { transformClasses } from "@/utils";

interface ICheckBoxValues {
  value: string;
  available: boolean;
  shouldWrap?: boolean;
}
interface IProps {
  value?: string[];
  list: ICheckBoxValues[];
  selectedValuesList: (value: string[]) => void;
  shouldWrap?: boolean;
  children?: React.ReactNode;
  containerStyles?: SxProps<Theme>;
}
const ChipMultiSelect: React.FC<IProps> = (props) => {
  const {
    list,
    selectedValuesList,
    value,
    shouldWrap = true,
    children,
    containerStyles,
  } = props;

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const handleCheckboxChange = (value: string, isAvailable: boolean) => {
    if (!isAvailable) return;
    if (selectedValues && selectedValues?.length > 0) {
      setSelectedValues((prevSelected: any) =>
        prevSelected.includes(value)
          ? prevSelected.filter((item: any) => item !== value)
          : [...prevSelected, value]
      );
    } else {
      setSelectedValues([value]);
    }
  };

  useEffect(() => {
    if (selectedValues) {
      selectedValuesList(selectedValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (value?.length !== selectedValues.length) {
      setSelectedValues(value!);
    }
  }, [value]);
  return (
    <Grid
      item
      className={style.slotSelectionContainer}
      sx={{
        flexWrap: shouldWrap ? "wrap" : "nowrap",
        overflow: shouldWrap ? "hidden" : "scroll",
      }}
    >
      {list.map((info, index) => (
        <Grid
          item
          className={transformClasses(
            style.checkboxBtn,
            !info.available ? style.unavailableSlot : "",
            selectedValues && selectedValues?.includes(info.value)
              ? style.checkboxSelectedItem
              : style.checkboxItemDefault
          )}
          sx={{
            ...containerStyles,
          }}
          key={`${info.value}-${index}`}
        >
          <FormControlLabel
            control={
              <Checkbox
                value={info.value}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  !info.available
                    ? null
                    : handleCheckboxChange(event?.target?.value, info.available)
                }
                className={style.checkBox}
              />
            }
            label={info.value}
            key={`${info.value}-${index}`}
            sx={{
              margin: "0rem",
              "& .MuiFormControlLabel-label": {
                fontSize: "1.2rem",
                color:
                  selectedValues && selectedValues?.includes(info.value)
                    ? "var(--neutral-100)"
                    : !info.available
                    ? "var(--gray-400)"
                    : "var(--black-900)",
                ...(!info.available
                  ? {
                      textDecoration: "line-through",
                    }
                  : {}),
              },
            }}
          />
        </Grid>
      ))}
      {children}
    </Grid>
  );
};

export default ChipMultiSelect;
