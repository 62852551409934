import React from "react";
import { Dialog as DialogMUI, DialogProps, Grid } from "@mui/material";

import GetImages from "@/components/GetImages";

import style from "./index.module.css";

interface IProps {
  children: React.ReactNode;
  isOpen?: boolean;
  closeHandler?: () => void;
  isCloseIcon?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: boolean;
  paperProps?: any;
}
const Dialog: React.FC<IProps> = (props) => {
  const {
    children,
    isOpen = true,
    closeHandler,
    isCloseIcon = false,
    maxWidth = "md",
    fullWidth = false,
    paperProps = {},
  } = props;

  return (
    <DialogMUI
      fullWidth={fullWidth}
      open={isOpen}
      onClose={closeHandler}
      maxWidth={maxWidth}
      PaperProps={{
        ...paperProps,
      }}
      //this is removing backdrop from all dialog which is not intended hence commenting it
      //done by shubham jain, Fixed BSA-387
      // slotProps={{
      //   backdrop: {
      //     style: { backgroundColor: "transparent" },
      //   },
      // }}
    >
      {isCloseIcon && (
        <Grid item className={style.closeIconContainer} onClick={closeHandler}>
          <GetImages name="CloseIcon" width="14" height="14" />
        </Grid>
      )}
      {children}
    </DialogMUI>
  );
};

export default Dialog;
