import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";

import { styles } from "./style";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setMessageNotification } from "@/store/slices/chat";

const Notification: React.FC = () => {
  const { messageNotification } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setMessageNotification(null));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000); // Auto-close after 5 seconds
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <Grid item sx={styles.notification}>
      <Grid item sx={styles.notificationContent}>
        <Typography component={"h4"}>
          {messageNotification?.notification?.title}
        </Typography>
        <Typography component={"p"}>
          {messageNotification?.notification?.body}
        </Typography>
      </Grid>
      <button style={styles.notificationClose} onClick={onClose}>
        &times;
      </button>
    </Grid>
  );
};

export default Notification;
