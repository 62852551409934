import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import style from "./index.module.css";

import {
  IOrderStatus,
  ITestBookingDetail,
} from "@/models/ApiRequestResponse/patient";
import { IButtonVariant } from "@/models/button";
import { IOrderStatusEnum } from "@/models/ApiRequestResponse/pharmacy";
import { IAllOrderListProps } from "@/models/patient";

import GenericCardHeader from "@/components/GenericCardHeader";
import GetImages from "@/components/GetImages";
import Button from "@/components/Button";
import Confirmation from "@/components/Confirmation";

import {
  allOrders,
  fetchAllOrdersList,
  fetchHomeModules,
  setAllOrders,
  setAllOrderStatusUpdate,
} from "@/store/slices/patient/home";
import { handleIsInfiniteScrollFilterChanges } from "@/store/slices/config";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import {
  openClinicMapLocation,
  transformClasses,
  transformDateToDDMMYYYY,
} from "@/utils";
import { INotifyEnum, notify } from "@/utils/toaster";
import { storage } from "@/utils/Storage";
import { updateDiagnosticOrderApi } from "@/utils/apis/diagnostic";

interface ITestBookingCardProps extends ITestBookingDetail {
  filters?: IAllOrderListProps;
}

const TestBookingCard: React.FC<ITestBookingCardProps> = (props) => {
  const {
    id,
    status,
    name,
    date,
    address,
    city,
    pincode,
    tests,
    phone_number,
    latitude,
    longitude,
    filters,
  } = props;
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const allOrdersList = useAppSelector(allOrders);

  const intl = useIntl();

  const userId = storage.getSessionToken("user_id");

  const cancelOrderModalHandler = (): void =>
    setIsCancelModalOpen(!isCancelModalOpen);

  const getDirectionHandler = (): void => {
    openClinicMapLocation(latitude ?? 0, longitude ?? 0);
  };

  const testBookingOrderCancelHandler: () => Promise<void> = async () => {
    const cancelOrderResponse = await updateDiagnosticOrderApi({
      id,
      status: IOrderStatusEnum.CANCELLED,
    });
    if ("result" in cancelOrderResponse) {
      notify(INotifyEnum.SUCCESS, cancelOrderResponse?.message);
      cancelOrderModalHandler();
      if (filters) {
        dispatch(setAllOrders([]));
        dispatch(handleIsInfiniteScrollFilterChanges());
        dispatch(fetchAllOrdersList({ ...filters, offset: "0" }));
      } else if (allOrdersList?.length === 0) {
        dispatch(fetchHomeModules({ userId: userId ?? "" }));
      } else {
        dispatch(
          setAllOrderStatusUpdate({
            ...props,
            status: IOrderStatusEnum.CANCELLED,
          })
        );
      }
    } else {
      notify(INotifyEnum.ERROR, cancelOrderResponse?.message);
    }
  };

  return (
    <Grid item className={style.cardContainer}>
      <GenericCardHeader
        orderStatus={status}
        doctorId={""}
        name={name}
        bio={`
          ${intl.formatMessage({
            id: "test_at",
            defaultMessage: "Test at",
          })} 
          ${transformDateToDDMMYYYY(date)}`}
        imageSvg={
          <Grid item className={style.headerIcon}>
            <GetImages name="TestBookingIcon" height={"36"} width={"25"} />
          </Grid>
        }
      />

      <Grid item className={style.footer}>
        <Typography
          component={"p"}
          className={transformClasses(style.footerText, style.spacing)}
        >
          <GetImages name={"ClinicSmallIcon"} width="18" height="16" />
          {address}, {city} {pincode}
        </Typography>
        <Typography
          component={"p"}
          className={transformClasses(style.footerText, style.spacing)}
        >
          <GetImages name={"PrescriptionTestIcon"} width="18" height="16" />
          {tests?.join(", ")}
        </Typography>
        {phone_number && (
          <Typography component={"p"} className={style.footerText}>
            <GetImages name={"TelePhoneIcon"} width="18" height="16" />
            <FormattedMessage id="lab" defaultMessage="Lab" /> - {phone_number}
          </Typography>
        )}
        {status === IOrderStatus.PENDING && (
          <Grid item className={style.buttonContainer}>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={cancelOrderModalHandler}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Grid item>
              <Button btnTrigger={getDirectionHandler}>
                <GetImages
                  name={"LocationRedirectIcon"}
                  width="26"
                  height="20"
                />
                <FormattedMessage
                  id="open_in_maps"
                  defaultMessage="Open in maps"
                />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {isCancelModalOpen && (
        <Confirmation
          isOpen={isCancelModalOpen}
          closeHandler={cancelOrderModalHandler}
          title={"cancel_order"}
          alterMessage="cancel_order_alert_message"
          actionHandler={testBookingOrderCancelHandler}
        />
      )}
    </Grid>
  );
};

export default TestBookingCard;
