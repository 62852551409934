import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchHealthHubData,
  getAllCategories,
} from "@/store/slices/patient/healthHub";

import Loader from "@/components/Loader";

type HealthHubWrapperProps = {
  children: React.ReactNode;
};

const HealthHubWrapper = ({ children }: HealthHubWrapperProps) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  useEffect(() => {
    dispatch(fetchHealthHubData());
    dispatch(getAllCategories());
  }, [state?.videoId, state?.blogId, state?.diseaseId]);

  const { isLoading, isError } = useAppSelector((s) => s.healthHub);

  if (isLoading) {
    return <Loader height="100vh" />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return <>{children}</>;
};

export default HealthHubWrapper;
