import React from "react";
import { Grid, ListItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import { useAppDispatch } from "@/store/hooks";
import { resetHealthHubSearch } from "@/store/slices/patient/healthHub";

type HealthHubSearchCardProps = {
  title: string;
  category: string;
  thumbnailUrl: string;
  id: string;
  onClick?: () => void;
};

const HealthHubSearchCard: React.FC<HealthHubSearchCardProps> = ({
  title,
  category,
  thumbnailUrl,
  id,
  onClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigate = () => {
    switch (category) {
      case "Playlist":
        navigate("/health-hub/playlist", { state: { id } });
        break;
      case "Video":
      case "Short":
        navigate("/health-hub/video", { state: { videoId: id } });
        break;
      default:
        break;
    }

    clearSearch();
    if (onClick) {
      onClick();
    }
  };

  const clearSearch = () => {
    dispatch(resetHealthHubSearch());
  };

  return (
    <ListItem onClick={handleNavigate} className={style.listItem}>
      <Grid className={style.imgContainer}>
        <img src={thumbnailUrl} alt={title} />
      </Grid>
      <Grid className={style.contentContainer}>
        <Typography className={style.title}>{title}</Typography>
        <Typography className={style.type}>{category}</Typography>
      </Grid>
    </ListItem>
  );
};

export default HealthHubSearchCard;
