import React, { useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import GetImages from "../../../GetImages";

import style from "./index.module.css";

import DoctorImage from "../../../../assets/team-1.png";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  fetchRTCTokenAgora,
  fetchRTMTokenAgora,
  setCallerDetails,
  setChannelName,
} from "../../../../store/slices/videoAppointment";

const IncomingCall: React.FC = () => {
  const [isVideoCallJoined, setIsVideoCallJoined] = useState(false);

  const dispatch = useAppDispatch();
  const { callerDetails } = useAppSelector((state) => state.videoAppointment);

  const navigate = useNavigate();

  const joinVideoCall = async () => {
    setIsVideoCallJoined(true);
    await dispatch(fetchRTMTokenAgora(callerDetails?.bookingId));
    await dispatch(fetchRTCTokenAgora(callerDetails?.bookingId));
    dispatch(setChannelName(callerDetails?.bookingId));
    dispatch(setCallerDetails(null));
    navigate("/join-room");
  };

  const rejectCall = () => dispatch(setCallerDetails(null));

  return (
    <Grid container className={style.callingScreen}>
      <Typography component={"p"} className={style.statusText}>
        Incoming Video Call
      </Typography>
      <Grid item className={style.profileInfo}>
        <img
          src={
            callerDetails?.callerPhoto === "photo_url"
              ? DoctorImage
              : callerDetails?.callerPhoto
          }
          alt="Profile"
          className={style.profilePic}
        />
        <div className={style.doctorDetails}>
          <Typography component={"p"} className={style.profileName}>
            {callerDetails?.callerName}
          </Typography>
          {(callerDetails?.experience ||
            callerDetails?.speciality ||
            callerDetails?.qualification) && (
            <Typography component={"p"} className={style.profileDetails}>
              {callerDetails?.experience} yrs • {callerDetails?.speciality} •
              {JSON.parse(callerDetails?.qualification)[0]?.degree_name}
            </Typography>
          )}
        </div>
        <div className={style.ringingBox}>
          <GetImages name="ringingGreenIcon" height="14" width="14" />
          <Typography component={"p"} className={style.ringingText}>
            Ringing
          </Typography>
        </div>
      </Grid>
      <Grid item className={style.callActionBtns}>
        <button className={style.endCallButton} onClick={rejectCall}>
          <GetImages name="VideoCallIcon" height="28" width="28" />
          <Typography component={"p"} className={style.buttonText}>
            Cancel
          </Typography>
        </button>
        {isVideoCallJoined ? (
          <Grid item className={style.pickCallButton}>
            <CircularProgress sx={{ color: "var(--neutral-100)" }} />
          </Grid>
        ) : (
          <button className={style.pickCallButton} onClick={joinVideoCall}>
            <GetImages name="VideoCallIcon" height="28" width="28" />
            <Typography component={"p"} className={style.buttonText}>
              {" "}
              Join Call
            </Typography>
          </button>
        )}
      </Grid>
    </Grid>
  );
};

export default IncomingCall;
