import React, { useEffect, useMemo, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Button as MUIButton,
  Box,
  Dialog,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import GetImages from "@/components/GetImages";
import GenericCardHeader from "@/components/GenericCardHeader";
import Button from "@/components/Button";
import ChangePatient from "@/components/DoctorProfile/ChangePatient";
import AddEditProfile from "@/components/AddEditProfile";
import Header from "@/components/Header";

import style from "./index.module.css";

import {
  IDoctorAvailabilityPlatform,
  IDoctorConsultationSelection,
  IDoctorProfileStatus,
  IDoctorScheduleConsultationType,
} from "@/models/doctor";
import { IFilterType } from "@/models/patient";

import { fetchPatientMembers } from "@/store/slices/patient/member";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchConfiguration,
  handleIsShowSuccessModal,
} from "@/store/slices/config";
import { checkoutDetail } from "@/store/slices/patient/findDoctor";

import { INotifyEnum, notify } from "@/utils/toaster";
import { reserveAppointmentFromPatient } from "@/utils/apis/patient/doctor";
import useIsMobile from "@/utils/hooks/useIsMobile";
import useCreatePatientMember from "@/utils/hooks/useCreatePatientMember";
import SuccessBG from "@/assets/bg-success.png";
import useIsTablet from "@/utils/hooks/useIsTablet";
import { doctorDegreeName, doctorSpeakLanguages } from "@/utils";

interface IOpenModal {
  memberList: boolean;
  success: boolean;
}
interface IPaymentMethodConfig {
  config: Record<string, Array<{ id: string; frontendText: string }>>;
}

interface IProps {
  isOpen?: boolean;
  closeHandler?: () => void;
  type?: IFilterType;
}
const Checkout: React.FC<IProps> = (props) => {
  const { closeHandler, type } = props;
  const [paymentMode, setPaymentMode] = useState<string | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<IOpenModal>({
    memberList: false,
    success: false,
  });
  const [slotNo, setSlotNo] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();

  const {
    profileDetail,
    isAddMemberModalOpen,
    isGenderOpen,
    openCloseGenderModal,
    createPatientMember,
    openCloseAddMemberModal,
    profileDetailHandler,
    saveGenderValue,
  } = useCreatePatientMember();

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const dispatch = useAppDispatch();
  const paymentMethodConfig: any = useAppSelector((state) => state.config);
  const checkoutDetails = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const memberList = useAppSelector((state) => state.member);
  const doctorProfileInfo = useAppSelector((state) => state.doctorProfile);
  const doctorProfile = doctorProfileInfo?.detail;

  const isSmallScreen = useMemo(() => {
    return isMobile || isTablet;
  }, [isMobile, isTablet]);

  const handlePaymentSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMode((event.target as HTMLInputElement).value);
  };

  const closeModal = () => {
    const isModalOpen: any = {};
    for (let key in isOpenModal) {
      isModalOpen[key] = false;
    }
    setIsOpenModal(isModalOpen);
  };

  const fetchPatientList = async () => {
    await dispatch(fetchPatientMembers());
  };

  const handleReserveAppointment = async () => {
    const reserveAppointmentPatientResponse =
      await reserveAppointmentFromPatient({
        doctorId: doctorProfile?.id,
        memberId: checkoutDetails?.memberId,
        slotId: checkoutDetails?.slotId,
        consultationType:
          checkoutDetails?.type === IDoctorAvailabilityPlatform.VIDEO_CALL
            ? IDoctorScheduleConsultationType.VIDEO_CALL
            : IDoctorScheduleConsultationType.IN_CLINIC,
        paymentMethodId: +paymentMode!,
        paymentAmount: checkoutDetails?.fee,
        ...(checkoutDetails?.type === IDoctorAvailabilityPlatform.IN_CLINIC && {
          clinicId: checkoutDetails?.clinicId,
        }),
      });
    if ("result" in reserveAppointmentPatientResponse) {
      dispatch(handleIsShowSuccessModal()); //set state to true
      setSlotNo(reserveAppointmentPatientResponse.result.slotNo);
      notify(INotifyEnum.SUCCESS, reserveAppointmentPatientResponse.message);
      setIsOpenModal({ ...isOpenModal, success: true });
    } else {
      notify(INotifyEnum.ERROR, reserveAppointmentPatientResponse.message);
    }
  };

  const checkoutScreen = () => (
    <Grid container pb={{ xs: 2, lg: 0 }} py={{ lg: 2 }} gap={{ xs: 1, lg: 3 }}>
      <Grid item xs={12} px={{ lg: 2 }}>
        <Typography
          textAlign={"center"}
          variant="subtitle1"
          fontWeight={500}
          display={{ xs: "none", lg: "block" }}
        >
          <FormattedMessage id="checkout" defaultMessage={"Checkout"} />
        </Typography>
        <Box display={{ xs: "block", lg: "none" }}>
          <Header heading="Checkout" onGoBack={closeHandler} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        px={2}
        py={{ xs: 1, lg: 0 }}
        sx={{
          backgroundColor: { xs: "var(--neutral-100)", lg: "transparent" },
        }}
      >
        <GenericCardHeader
          image={doctorProfile?.photo_url}
          name={doctorProfile?.name ?? ""}
          bio={`${doctorProfile?.experience_years} yrs • ${
            doctorProfile?.speciality
          } • ${doctorDegreeName(doctorProfile?.qualification_details ?? [])}`}
          languages={doctorSpeakLanguages(doctorProfile?.language ?? [])}
          status={IDoctorProfileStatus.VERIFIED}
        />
      </Grid>
      <Grid
        item
        xs={12}
        px={2}
        py={{ xs: 1, lg: 0 }}
        sx={{
          backgroundColor: { xs: "var(--neutral-100)", lg: "transparent" },
        }}
      >
        <Stack direction={"column"} gap={2}>
          <Typography variant={"subtitle2"} textAlign={"left"}>
            <FormattedMessage
              id="consultation_detail"
              defaultMessage="Consultation Details"
            />
          </Typography>
          <Box>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <GetImages
                name="ScheduleSelectedSmallIcon"
                width="20"
                height="16"
              />
              <Typography
                variant={"subtitle2"}
                fontWeight={400}
                color={"var(--gray-500)"}
              >
                {checkoutDetails?.dateSlotTime}
              </Typography>
            </Stack>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <GetImages
                name={
                  checkoutDetails?.type ===
                  IDoctorAvailabilityPlatform.VIDEO_CALL
                    ? "VideoIcon"
                    : "ClinicSmallIcon"
                }
                width="20"
                height="16"
              />
              <Typography
                variant={"subtitle2"}
                fontWeight={400}
                color={"var(--gray-500)"}
              >
                {checkoutDetails?.type ===
                IDoctorAvailabilityPlatform.VIDEO_CALL
                  ? IDoctorConsultationSelection.VIDEO_CONSULTATION
                  : checkoutDetails?.clinicName}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: {
            xs: "var(--neutral-100)",
            lg: "var(--neutral-100)",
          },
          p: 2,
        }}
      >
        <Stack direction={"column"} gap={1}>
          <Typography variant={"subtitle2"} textAlign={"left"}>
            <FormattedMessage
              id="patient_details"
              defaultMessage="Patient Details"
            />
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              component={"p"}
              className={style.patientName}
              textAlign={"left"}
            >
              {checkoutDetails?.name} (Main)
            </Typography>
            <MUIButton
              variant="text"
              onClick={() =>
                setIsOpenModal({
                  ...isOpenModal,
                  memberList: !isOpenModal.memberList,
                })
              }
            >
              <FormattedMessage id="change" />
            </MUIButton>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        px={2}
        py={{ xs: 1, lg: 0 }}
        sx={{
          backgroundColor: { xs: "var(--neutral-100)", lg: "transparent" },
        }}
      >
        <Stack gap={1}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant={"subtitle2"}>
              <FormattedMessage
                id="total_payable"
                defaultMessage="Total Payable"
              />
            </Typography>
            <Typography variant={"subtitle2"}>
              ₹ {checkoutDetails?.fee}
            </Typography>
          </Stack>
          <RadioGroup value={paymentMode} onChange={handlePaymentSelection}>
            {paymentMethodConfig?.config[type ?? "0"]?.map(
              (item: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={item.id}
                  control={
                    <Radio
                      checkedIcon={
                        <GetImages
                          name="TickOrangeIcon"
                          width="20"
                          height="20"
                        />
                      }
                      icon={
                        <GetImages name="RadioBtnIcon" width="20" height="20" />
                      }
                    />
                  }
                  label={
                    <Typography
                      component={"p"}
                      className={style.paymentModeLabel}
                    >
                      {item.frontendText}
                    </Typography>
                  }
                />
              )
            )}
          </RadioGroup>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        px={2}
        sx={{
          width: "100%",
          ...(isMobile || isTablet
            ? {
                position: { xs: "fixed", lg: "static" },
                bottom: { xs: 10, lg: "auto" },
              }
            : {}),
        }}
      >
        <Button disabled={!paymentMode} btnTrigger={handleReserveAppointment}>
          <GetImages name="ChekoutBtnIcon" width="28" height="20" />
          <FormattedMessage id="next" defaultMessage="Next" />
        </Button>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    fetchPatientList();
    if (
      location.state === IFilterType.VIDEO_CALL ||
      location.state === IFilterType.IN_CLINIC ||
      type
    ) {
      dispatch(fetchConfiguration(type ? type : location.state));
    }
  }, [location, type]);

  useEffect(() => {
    const memberInfo = memberList.list.filter(
      (member, index) => member.is_default
    )[0];

    dispatch(
      checkoutDetail({
        ...checkoutDetails,
        name: memberInfo?.name,
      })
    );
  }, [memberList]);

  if (isSmallScreen) {
    if (isOpenModal.success) {
      return (
        <Dialog open={true} PaperProps={{ sx: { borderRadius: "1.2rem" } }}>
          <Stack gap={1} p={2}>
            <Stack justifyContent={"center"} alignItems={"center"} gap={0.5}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  backgroundImage: `url(${SuccessBG})`,
                  backgroundSize: "cover",
                  height: 70,
                  width: 70,
                }}
              >
                {type === IFilterType.VIDEO_CALL ? (
                  <GetImages
                    name="AppointmentBookedLikeIcon"
                    width="20"
                    height="20"
                  />
                ) : (
                  <Typography
                    textAlign={"center"}
                    variant="h6"
                    color={"var(--neutral-100)"}
                  >
                    {slotNo}
                  </Typography>
                )}
              </Stack>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                color={"secondary"}
              >
                <FormattedMessage
                  id="slot_alloted"
                  defaultMessage={"Slot Number Alloted"}
                />
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                color={"success.main"}
              >
                {type === IFilterType.VIDEO_CALL ? (
                  <FormattedMessage
                    id="video_consultation_booked"
                    defaultMessage={"Your Video Consultation is Confirmed"}
                  />
                ) : (
                  <FormattedMessage
                    id="clinit_appointment_success"
                    defaultMessage={"Your Clinic Visit is successful Booked"}
                  />
                )}
              </Typography>
            </Stack>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Typography variant="subtitle1" fontWeight={500}>
                {doctorProfile?.name}
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
                flexWrap={"wrap"}
                width={"100%"}
              >
                <Typography variant="caption" color={"secondary"} noWrap>
                  {doctorProfile?.experience_years} Yrs
                </Typography>
                &bull;
                <Typography variant="caption" color={"secondary"} noWrap>
                  {doctorProfile?.speciality}
                </Typography>
                &bull;
                {doctorProfile?.qualification_details.map((item) => (
                  <>
                    <Typography variant="caption" color={"secondary"} noWrap>
                      {item.certificate}
                    </Typography>
                    &bull;
                  </>
                ))}
                {doctorProfile?.language.map((item) => (
                  <>
                    <Typography variant="caption" color={"secondary"} noWrap>
                      {item}
                    </Typography>
                    &bull;
                  </>
                ))}
              </Stack>

              <Typography variant="subtitle1" fontWeight={500}>
                {checkoutDetails.dateSlotTime}
              </Typography>
            </Stack>
            <MUIButton
              variant={"contained"}
              color="primary"
              sx={{ py: 1, width: "80%", mx: "auto" }}
              onClick={() => navigate("/home")}
            >
              <FormattedMessage
                id="goto_homepage"
                defaultMessage={"Go to Home Page"}
              />
            </MUIButton>
          </Stack>
        </Dialog>
      );
    }

    return (
      <Dialog fullScreen open={true}>
        {checkoutScreen()}
        {isOpenModal.memberList && (
          <ChangePatient
            isOpen={isOpenModal.memberList}
            closeHandler={closeModal}
            handleAddMember={openCloseAddMemberModal}
          />
        )}
        {isAddMemberModalOpen && (
          <AddEditProfile
            isOpen={isAddMemberModalOpen}
            closeHandler={openCloseAddMemberModal}
            title="Add new Profile"
            age={profileDetail.age}
            gender={profileDetail.gender}
            name={profileDetail.name}
            isGenderOpen={isGenderOpen}
            saveGender={saveGenderValue}
            saveProfile={createPatientMember}
            openCloseGenderModal={openCloseGenderModal}
            profileDetailHandler={profileDetailHandler}
          />
        )}
      </Dialog>
    );
  }

  if (isOpenModal.success) {
    return (
      <Stack gap={1} p={2}>
        <Stack justifyContent={"center"} alignItems={"center"} gap={0.5}>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              backgroundImage: `url(${SuccessBG})`,
              backgroundSize: "cover",
              height: 70,
              width: 70,
            }}
          >
            {type === IFilterType.VIDEO_CALL ? (
              <GetImages
                name="AppointmentBookedLikeIcon"
                width="20"
                height="20"
              />
            ) : (
              <Typography
                textAlign={"center"}
                variant="h6"
                color={"var(--neutral-100)"}
              >
                {slotNo}
              </Typography>
            )}
          </Stack>
          <Typography variant="subtitle2" fontWeight={400} color={"secondary"}>
            <FormattedMessage
              id="slot_alloted"
              defaultMessage={"Slot Number Alloted"}
            />
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={400}
            color={"success.main"}
          >
            <FormattedMessage
              id="clinit_appointment_success"
              defaultMessage={"Your Clinic Visit is successful Booked"}
            />
          </Typography>
        </Stack>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography variant="subtitle1" fontWeight={500}>
            {doctorProfile?.name}
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <Typography variant="caption" color={"secondary"} noWrap>
              {doctorProfile?.experience_years} Yrs
            </Typography>
            &bull;
            <Typography variant="caption" color={"secondary"} noWrap>
              {doctorProfile?.speciality}
            </Typography>
            &bull;
            {doctorProfile?.qualification_details.map((item) => (
              <>
                <Typography variant="caption" color={"secondary"} noWrap>
                  {item.certificate}
                </Typography>
                &bull;
              </>
            ))}
            {doctorProfile?.language.map((item) => (
              <>
                <Typography variant="caption" color={"secondary"} noWrap>
                  {item}
                </Typography>
                &bull;
              </>
            ))}
          </Stack>

          <Typography variant="subtitle1" fontWeight={500}>
            {checkoutDetails.dateSlotTime}
          </Typography>
        </Stack>
        <MUIButton
          variant={"contained"}
          color="primary"
          sx={{ py: 1, width: "80%", mx: "auto" }}
          onClick={() => navigate("/home")}
        >
          <FormattedMessage
            id="goto_homepage"
            defaultMessage={"Go to Home Page"}
          />
        </MUIButton>
      </Stack>
    );
  }

  return (
    <>
      {!isOpenModal.memberList && checkoutScreen()}
      {isOpenModal.memberList && (
        <ChangePatient
          isOpen={isOpenModal.memberList}
          closeHandler={closeModal}
          handleAddMember={openCloseAddMemberModal}
        />
      )}
      <AddEditProfile
        isOpen={isAddMemberModalOpen}
        closeHandler={openCloseAddMemberModal}
        title="Add new Profile"
        age={profileDetail.age}
        gender={profileDetail.gender}
        name={profileDetail.name}
        isGenderOpen={isGenderOpen}
        saveGender={saveGenderValue}
        saveProfile={createPatientMember}
        openCloseGenderModal={openCloseGenderModal}
        profileDetailHandler={profileDetailHandler}
      />
    </>
  );
};

export default Checkout;
