import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IFetchUserDetailsParams } from "../../../models/chat";

import { fetchUserDetails } from "../../../utils/apis/chat";

export const fetchUser = createAsyncThunk(
  "chat/fetch-user-details",
  async ({
    user_ids,
    doctor_ids,
    ip_dash_id,
    device_token_user_id,
  }: IFetchUserDetailsParams) => {
    const response = await fetchUserDetails({
      user_ids,
      doctor_ids,
      ip_dash_id,
      device_token_user_id,
    });
    return response;
  }
);

const initialState: any = {
  selectedChat: null,
  messageNotification: null,
  userDetails: [],
  isInitiateFetchUserDetail: true,
  isUserDetailLoading: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetChatState: () => initialState,
    setSelectedChat: (
      state,
      action: PayloadAction<Record<string, unknown>>
    ) => {
      state.selectedChat = action.payload;
    },
    setInitiateFetchUserDetail: (state, action: PayloadAction<boolean>) => {
      state.isInitiateFetchUserDetail = action.payload;
    },
    setMessageNotification: (state, action) => {
      state.messageNotification = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.isUserDetailLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isUserDetailLoading = false;
      state.userDetails = action.payload.result;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.isUserDetailLoading = false;
    });
  },
});

export const {
  setSelectedChat,
  resetChatState,
  setInitiateFetchUserDetail,
  setMessageNotification,
} = chatSlice.actions;

export default chatSlice.reducer;
