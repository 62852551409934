import React, { forwardRef, useImperativeHandle } from "react";
import { Breakpoint, DialogContent } from "@mui/material";

import CreateEditTemplate from "../../../../../../views/Doctor/Prescription/CreateEditTemplate";
import AvailabilitySettingWeb from "../../../../../../views/Doctor/AvailabilitySettings/AvailabilitySettingWeb";
import BeforeAdmitPatientPopUps from "../../../../../../views/Doctor/IPDashboard/PreAdmitPatientPopUps";
import AdmitPatient from "../../../../../../views/Doctor/IPDashboard/AdmitPatient";
import UniversalSearch from "../../../../../../views/Common/UniversalSearch";
import Confirmation from "../../../../../Confirmation";
import HelpSupport from "../../../../../../views/Doctor/HelpSupport";
import MakeAppointment from "../../../../../MakeAppointment";
import VerifyOTP from "../../../../../Verification/VerifyOTP";
import EnterPatientDetail from "../../../../../MakeAppointment/EnterPatientDetail";
import GenericSuccessAlert from "../../../../../GenericSuccessAlert";
import Dialog from "../../../../../Dialog";
import DoctorProfileDetails from "../../../../../../views/Common/DoctorProfile";
import ProfileDetail from "../../../../../../views/Common/Profile";
import Profile from "../../../../../../views/Doctor/Profile";

import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { resetPatientDetailErrorMessages } from "../../../../../../store/slices/doctor/checkMobilePatientExistOrNot";

import useLogout from "../../../../../../utils/hooks/useLogout";
import useMakeAppointment from "../../../../../../utils/hooks/useMakeAppointment";
import { storage } from "../../../../../../utils/Storage";

import { IRoleType } from "../../../../../../models/role";
import {
  IAddPatient,
  IDialogMappings,
  IDoctorAvailabilityPlatform,
  IKeyPairsValues,
  IMakeAppointmentEvent,
  IProfileDropdownEnums,
} from "../../../../../../models/doctor";

import { ITopBarItems } from "../../../../../../store/slices/doctor/models/topNavigationSliceModel";

interface IProps {
  openCloseDoctorProfileDropdown: (identifier: IProfileDropdownEnums) => void;
  openCloseMobileCheckOTPVerify: (field: IAddPatient, value: boolean) => void;
}
const Overlays = forwardRef((props: IProps, ref) => {
  const { openCloseDoctorProfileDropdown, openCloseMobileCheckOTPVerify } =
    props;

  const topBarItems: ITopBarItems = useAppSelector(
    (state) => state.topNavigation.topBarItems
  );
  const { mobileCheck, admiPatient } = useAppSelector(
    (state) => state.topNavigation.addPatient
  );
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);
  const appointmentDetail = useAppSelector((state) => state.appointment);
  const dispatch = useAppDispatch();

  const { logout } = useLogout();
  const {
    makeAppointment,
    slotNo,
    openScheduleModalHandler,
    navigateToMobileVerify,
    handleCreatePatientVerifyOTP,
    createAppointmentHandler,
    sendOtpToCreatePatient,
    otpId,
    clearEnterPatientStates,
    setSelectedTab,
    selectedTab,
    backMakeAppointmentHandler,
  } = useMakeAppointment();

  const roleType = storage.getSessionToken("roleType");

  const dialogKeys: IKeyPairsValues = {
    isProfileOpen: IProfileDropdownEnums.PROFILE,
    isReviewOpen: IProfileDropdownEnums.REVIEW,
    isTemplatesOpen: IProfileDropdownEnums.TEMPLATES,
    isAvailabilityOpen: IProfileDropdownEnums.AVAILABILITY_SETTINGS,
  };
  const { isCreateTemplate } = useAppSelector(
    (state) => state.prescription.template
  );
  const activeDialogKey = Object.keys(dialogKeys).find(
    (key) => topBarItems?.[key as keyof ITopBarItems]
  );

  const dialogMappings: IDialogMappings = {
    isProfileOpen: {
      component:
        roleType === IRoleType.DOCTOR ? (
          <DoctorProfileDetails />
        ) : (
          <ProfileDetail />
        ),
      size: roleType === IRoleType.DOCTOR ? "sm" : "md",
    },
    isReviewOpen: { component: <Profile />, size: "sm" },
    isTemplatesOpen: { component: <CreateEditTemplate />, size: "lg" },
    isAvailabilityOpen: { component: <AvailabilitySettingWeb />, size: "lg" },
  };

  const activeKey = Object.keys(dialogMappings).find(
    (key) => topBarItems?.[key as keyof ITopBarItems]
  );

  const dialogConfig = activeKey
    ? dialogMappings[activeKey as keyof IDialogMappings]
    : { component: <></>, size: "md" };

  useImperativeHandle(ref, () => ({
    createAppointmentHandler,
  }));

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={dialogConfig?.size as Breakpoint}
        isOpen={!!activeDialogKey}
        closeHandler={
          activeKey === "isTemplatesOpen" && isCreateTemplate
            ? undefined
            : () => {
                if (activeDialogKey) {
                  openCloseDoctorProfileDropdown(
                    dialogKeys[activeDialogKey] as IProfileDropdownEnums
                  );
                }
              }
        }
        paperProps={{
          sx: {
            ...((activeKey === "isAvailabilityOpen" ||
              activeKey === "isTemplatesOpen") && {
              borderRadius: "2.4rem !important",
            }),
          },
        }}
      >
        <DialogContent
          sx={{
            p: 0,
            ...(activeKey === "isAvailabilityOpen" && {
              overflowY: "hidden",
            }),
          }}
        >
          {dialogConfig?.component}
        </DialogContent>
      </Dialog>

      {mobileCheck && <BeforeAdmitPatientPopUps />}
      {admiPatient && (
        <AdmitPatient
          isOpen={admiPatient}
          closeHandler={() => {
            openCloseMobileCheckOTPVerify(IAddPatient.ADMIT_PATIENT, false);
            dispatch(resetPatientDetailErrorMessages());
          }}
        />
      )}
      {isShowUniversalSearch && <UniversalSearch />}

      <Confirmation
        isOpen={topBarItems.isLogoutOpen}
        closeHandler={() =>
          openCloseDoctorProfileDropdown(IProfileDropdownEnums.LOGOUT)
        }
        title="logout"
        alterMessage={"logout_alert_message"}
        actionHandler={logout}
      />
      {topBarItems?.isHelpSupportOpen && (
        <HelpSupport
          isOpen={topBarItems?.isHelpSupportOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(IProfileDropdownEnums.HELP_SUPPORT)
          }
        />
      )}
      {topBarItems?.isMakeAppointmentFromNavBar && makeAppointment.creation && (
        <MakeAppointment
          selectedTab={
            roleType === IRoleType.DOCTOR
              ? selectedTab
              : IMakeAppointmentEvent.IN_CLINIC
          }
          platform={
            roleType === IRoleType.DOCTOR
              ? IDoctorAvailabilityPlatform.BOTH
              : IDoctorAvailabilityPlatform.IN_CLINIC
          }
          setSelectedTab={setSelectedTab}
          closeScheduleModal={clearEnterPatientStates}
          openScheduleModalHandler={openScheduleModalHandler}
          isOpen={makeAppointment.creation}
        />
      )}
      {topBarItems?.isMakeAppointmentFromNavBar && makeAppointment.otp && (
        <VerifyOTP
          handleOTPSubmit={handleCreatePatientVerifyOTP}
          mobileNo={appointmentDetail?.makePatientAppointment?.mobileNo}
          navigateToMobileVerify={navigateToMobileVerify}
          resendOTP={sendOtpToCreatePatient}
        />
      )}
      {topBarItems?.isMakeAppointmentFromNavBar &&
        (makeAppointment.mobileCheck || makeAppointment.patientDetail) && (
          <EnterPatientDetail
            notExistPatient={otpId}
            backHandler={backMakeAppointmentHandler}
            closeModalHandler={clearEnterPatientStates}
            openScheduleModalHandler={openScheduleModalHandler}
            isScheduleCreateOpen={makeAppointment}
            isOpen={
              makeAppointment.mobileCheck || makeAppointment.patientDetail!
            }
          />
        )}
      {topBarItems?.isMakeAppointmentFromNavBar && makeAppointment.success && (
        <GenericSuccessAlert
          type={appointmentDetail?.makePatientAppointment?.type}
          slotNumber={+slotNo}
          name={appointmentDetail?.makePatientAppointment?.name}
          age={appointmentDetail?.makePatientAppointment?.age}
          date={appointmentDetail?.makePatientAppointment?.dateSlotTime}
          isOpen={makeAppointment.success}
          closeScheduleModal={clearEnterPatientStates}
        />
      )}
    </>
  );
});

export default Overlays;
