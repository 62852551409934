import React, { useCallback, useEffect, useMemo, useState, memo } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Stack, Box, CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import DateSelectionTab from "@/components/DateSelectionTab";
import GetImages from "@/components/GetImages";
import Button from "@/components/Button";
import ChipSelect from "@/components/ChipSelect";
import Checkout from "@/components/DoctorProfile/Checkout";
import Modal from "@/components/Modal";
import {
  AppointmentTabPanel,
  AppointmentTabsPanel,
} from "@/components/AppointmentTab";

import {
  IAppointmetEnums,
  IDoctorAvailabilityPlatform,
  IMakeAppointmentEvent,
} from "@/models/doctor";
import {
  IClinicAppointment,
  IClinicAppointmentSegment,
  IVideoAppointment,
} from "@/models/ApiRequestResponse/doctor";
import { IRoleType } from "@/models/role";
import { IFilterType } from "@/models/patient";
import {
  IRolePermissionAccess,
  IScheduleSlotsPermissionEnums,
  IModalKeyEnums,
} from "@/models/permission";

import { storage } from "@/utils/Storage";
import {
  clinicAppointmentSegregation,
  getAppointmentSlotDetail,
  getNextSixDates,
  getPermissionAccess,
  openClinicMapLocation,
  timeInAmPmFormat,
} from "@/utils";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchScheduleSlots,
  makePatientAppointment,
} from "@/store/slices/doctor/appointment";
import { checkoutDetail } from "@/store/slices/patient/findDoctor";
import { setShowPermissionAlert } from "@/store/slices/permission";
import useIsMobile from "@/utils/hooks/useIsMobile";
import useIsTablet from "@/utils/hooks/useIsTablet";

const enum StepForm {
  BOOK_APPOINTMENT,
  CHECKOUT,
}

interface IProps {
  doctorId?: string;
  openScheduleModalHandler: (type: IAppointmetEnums) => void;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  platform?: IDoctorAvailabilityPlatform;
  isReschedule?: boolean;
  onClose?: () => void;
  open?: boolean;
}

const MakeAppointment: React.FC<IProps> = (props) => {
  const {
    doctorId,
    selectedTab,
    setSelectedTab,
    platform = IDoctorAvailabilityPlatform.BOTH,
    isReschedule = false,
    onClose,
    open = false,
  } = props;

  const [selectedDateTab, setSelectedDateTab] = useState<number>(0);
  const [slotsDetail, setSlotsDetail] = useState<{
    videoAppointment: IVideoAppointment[];
    clinicAppointment: IClinicAppointmentSegment[];
  }>({ videoAppointment: [], clinicAppointment: [] });
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] =
    useState<string>("");
  const [isCheckoutShowDesktop, setIsCheckoutShowDesktop] =
    useState<boolean>(false);
  const [sixDays, setSixDays] = useState<
    { date: string; day: string; fullDate: string }[]
  >([]);

  const roleType = storage.getSessionToken("roleType");

  const [step, setStep] = useState(StepForm.BOOK_APPOINTMENT);

  const dispatch = useAppDispatch();
  const checkoutDetailInfo = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const makePatientAppointmentInfo = useAppSelector(
    (state) => state.appointment.makePatientAppointment
  );
  const scheduleSlots = useAppSelector((state) => state.appointment);
  const { permission } = useAppSelector((state) => state.rolePermission);

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const dateSelectionHandler = useCallback(
    (newValue = selectedDateTab) => {
      if (!scheduleSlots?.slot || Object.keys(scheduleSlots.slot).length === 0)
        return;

      const appointmentType =
        selectedTab === IMakeAppointmentEvent.VIDEO_CALL
          ? "videoAppointment"
          : "clinicAppointment";

      const selectedDate = nextSixDays[newValue]?.date;

      const filteredSlots = scheduleSlots.slot[appointmentType].filter(
        (slot: any) => {
          const [month, date] = slot.date.split("-");
          return (
            dayjs(`${date}-${month}`, "MM-DD").format("DD MMM") === selectedDate
          );
        }
      );

      setSlotsDetail((state) => ({
        ...state,
        [appointmentType]:
          selectedTab === IMakeAppointmentEvent.VIDEO_CALL
            ? filteredSlots
            : clinicAppointmentSegregation(
                filteredSlots as IClinicAppointment[]
              ),
      }));
      setSelectedDateTab(newValue);
    },
    [selectedTab, scheduleSlots, slotsDetail]
  );

  const handleAppointmentTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedAppointmentSlot("");
    setSelectedTab(newValue);
  };

  const dispatchActionSlotSelection = () => {
    const appointmentSlotDetail = getAppointmentSlotDetail(
      selectedTab,
      slotsDetail,
      selectedAppointmentSlot,
      nextSixDays,
      selectedDateTab
    );
    const dateSlotTime = `${appointmentSlotDetail?.date} ${appointmentSlotDetail?.startTime} - ${appointmentSlotDetail?.endTime}`;
    const isVideoCall = selectedTab === IMakeAppointmentEvent.VIDEO_CALL;
    const platformType = isVideoCall
      ? IDoctorAvailabilityPlatform.VIDEO_CALL
      : IDoctorAvailabilityPlatform.IN_CLINIC;
    const commonDetails = {
      dateSlotTime,
      type: platformType,
      slotId: selectedAppointmentSlot,
      ...(platformType === IDoctorAvailabilityPlatform.IN_CLINIC && {
        clinicId: appointmentSlotDetail?.clinicId,
        clinicName: `${appointmentSlotDetail?.clinicName}, ${appointmentSlotDetail?.clinicAddress}, ${appointmentSlotDetail?.clinicCity}`,
      }),
      fee: appointmentSlotDetail?.consultationFee ?? 0,
    };
    if (roleType === IRoleType.PATIENT) {
      dispatch(
        checkoutDetail({
          ...checkoutDetailInfo,
          ...commonDetails,
        })
      );
    } else {
      dispatch(
        makePatientAppointment({
          ...makePatientAppointmentInfo,
          ...commonDetails,
        })
      );
    }
  };

  const handleNext = () => {
    setStep((state) => state + 1);
  };

  const handleBack = () => {
    setStep((state) => state - 1);
  };

  const isSmallScreen = useMemo(() => {
    return isMobile || isTablet;
  }, [isMobile, isTablet]);

  const getScreen = () => {
    if (scheduleSlots.isLoading) {
      return (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"10rem"}
        >
          <CircularProgress sx={{ color: "var(--orange-500)" }} />
        </Stack>
      );
    }
    return (
      <Stack alignItems={"center"} py={2} gap={2}>
        <Typography variant="subtitle1" fontWeight={500} gutterBottom>
          <FormattedMessage
            id={isReschedule ? "select_slot_reschedule" : "book_appointment"}
            defaultMessage={
              isReschedule ? "Select Slot to Reschedule" : "Book an appointment"
            }
          />
        </Typography>
        <Grid container gap={2} height={"100%"}>
          {platform === IDoctorAvailabilityPlatform.BOTH && (
            <Grid item xs={12}>
              <AppointmentTabsPanel
                value={selectedTab}
                onChange={handleAppointmentTab}
                aria-label="basic tabs example"
                className={style.tabBtnContainer}
              >
                <AppointmentTabPanel
                  label={
                    <Grid item className={style.tabInnerContainer}>
                      <GetImages
                        name={selectedTab ? "VideoIcon" : "VideoWhiteIcon"}
                        width="20"
                        height="20"
                      />
                      <Typography component={"p"}>
                        <FormattedMessage
                          id="video_call"
                          defaultMessage="Video Call"
                        />
                      </Typography>
                    </Grid>
                  }
                />
                <AppointmentTabPanel
                  label={
                    <Grid item className={style.tabInnerContainer}>
                      <GetImages
                        name={selectedTab ? "ClinicWhiteIcon" : "ClinicIcon"}
                        width="20"
                        height="20"
                      />
                      <Typography component={"p"}>
                        <FormattedMessage
                          id="in_clinic"
                          defaultMessage="In-Clinic"
                        />
                      </Typography>
                    </Grid>
                  }
                />
              </AppointmentTabsPanel>
            </Grid>
          )}
          <Grid item xs={12} borderRadius={4} height={"40vh"}>
            <DateSelectionTab
              data={nextSixDays}
              selectedDateTab={selectedDateTab}
              dateSelectionHandler={(event, date) => dateSelectionHandler(date)}
            />
            {appointmentSlots}
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems={"center"}>
              <Box width={"90%"}>
                <Button
                  disabled={!selectedAppointmentSlot}
                  btnTrigger={handleNext}
                >
                  <GetImages name="ChekoutBtnIcon" width="28" height="20" />
                  <FormattedMessage id="next" defaultMessage="Next" />
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const appointmentSlots = useMemo(() => {
    if (
      selectedTab === IMakeAppointmentEvent.VIDEO_CALL &&
      slotsDetail?.videoAppointment?.length > 0
    ) {
      return (
        <Stack height={"90%"} width={"100%"}>
          <Typography
            textAlign={"center"}
            component={"p"}
            className={style.slotFeeText}
          >
            <FormattedMessage id="slot_fee" defaultMessage="Slot fee" />
            <Typography component={"span"} className={style.slotPrice}>
              ₹{slotsDetail.videoAppointment[0]?.slots[0]?.consultationFee}
            </Typography>
          </Typography>
          <Grid
            container
            rowGap={1}
            columnGap={1}
            sx={{
              mx: "auto",
              overflowY: "scroll",
              paddingInline: "1.6rem",
              maxHeight: "inherit",
              pb: 1,
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {slotsDetail?.videoAppointment[0]?.slots?.map((slot) => (
              <Grid item key={slot.slotId}>
                <Stack alignItems={"center"} width={"100%"}>
                  <ChipSelect
                    data={[
                      {
                        label: timeInAmPmFormat(slot.slotStartTime),
                        value: slot.slotId,
                        isBooked: slot.isBooked,
                      },
                    ]}
                    selectedValue={selectedAppointmentSlot}
                    setSelectedValue={setSelectedAppointmentSlot}
                    showSlots={true}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      );
    } else if (
      selectedTab === IMakeAppointmentEvent.IN_CLINIC &&
      slotsDetail?.clinicAppointment?.length > 0
    ) {
      return (
        <Grid item className={style.inClinicContainer}>
          {slotsDetail?.clinicAppointment[0]?.slots?.map((info, index) => (
            <Grid
              item
              key={`${info.clinicName}-${index}`}
              className={style.clinicInfoWrapper}
            >
              <Grid item className={style.clinicDetailContainer}>
                <Grid item>
                  <Typography component={"h6"} className={style.clinicName}>
                    {info.clinicName}
                  </Typography>
                  <Typography component={"p"} className={style.priceBranch}>
                    ₹{info.consultationFee} • {info.clinicAddress},{" "}
                    {info.clinicCity}
                  </Typography>
                </Grid>
                {roleType === IRoleType.PATIENT && (
                  <Grid
                    item
                    className={style.directionIcon}
                    onClick={() =>
                      openClinicMapLocation(info.latitude, info.longitude)
                    }
                  >
                    <GetImages
                      name="LocationAccessSmallIcon"
                      width="20"
                      height="20"
                    />
                    <Typography component={"p"} className={style.mapText}>
                      <FormattedMessage id="maps" defaultMessage="Maps" />
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Stack gap={2} direction={"row"} flexWrap={"wrap"}>
                {info.slots.map((slot) => (
                  <ChipSelect
                    key={slot.slotId}
                    data={[
                      {
                        label: `${timeInAmPmFormat(
                          slot.slotStartTime
                        )} - ${timeInAmPmFormat(slot.slotEndTime)}`,
                        value: slot.slotId,
                        isBooked: slot.isBooked,
                      },
                    ]}
                    selectedValue={selectedAppointmentSlot}
                    setSelectedValue={setSelectedAppointmentSlot}
                    showSlots={true}
                  />
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      );
    }

    return (
      <Typography component={"h1"} align="center" padding={"4rem 0rem"}>
        <FormattedMessage
          id="no_slot_available"
          defaultMessage="No Slot Available"
        />
      </Typography>
    );
  }, [selectedTab, selectedDateTab, slotsDetail, selectedAppointmentSlot]);

  const nextSixDays = useMemo(() => {
    const appointments =
      selectedTab === IMakeAppointmentEvent.VIDEO_CALL
        ? scheduleSlots?.slot?.videoAppointment
        : scheduleSlots?.slot?.clinicAppointment;

    const mergedDates = sixDays?.map((item) => {
      // Find matching date in secondArray
      const matchingSlot = appointments?.find((slot) => {
        const parsedDate1 = dayjs(slot.date, "DD-MM-YYYY");
        const parsedDate2 = dayjs(item.fullDate, "YYYY MM DD");

        return parsedDate1.isSame(parsedDate2, "day");
      });

      return {
        date: item.date,
        day: item.day,
        isAvailable: matchingSlot ? matchingSlot.slots.length > 0 : false,
      };
    });

    return mergedDates;
  }, [scheduleSlots, sixDays, selectedTab]);

  useEffect(() => {
    if (
      roleType === IRoleType.DOCTOR ||
      roleType === IRoleType.JR_DOCTOR ||
      roleType === IRoleType.PHARMACY
    ) {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.SCHEDULE_SLOTS,
        IScheduleSlotsPermissionEnums.VIEW_SCHEDULE_SLOT_BY_DOCTOR,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      dispatch(fetchScheduleSlots({}));
    } else if (roleType === IRoleType.PATIENT) {
      dispatch(fetchScheduleSlots({ doctorId }));
    }
    setSixDays(getNextSixDates(6));
  }, []);

  useEffect(() => {
    dateSelectionHandler();
  }, [scheduleSlots, selectedTab]);

  useEffect(() => {
    dispatchActionSlotSelection();
  }, [selectedAppointmentSlot, selectedDateTab, selectedTab]);

  if (isSmallScreen) {
    if (step === StepForm.CHECKOUT) {
      return (
        <Checkout
          isOpen={isCheckoutShowDesktop}
          closeHandler={handleBack}
          type={
            selectedTab === 0 ? IFilterType.VIDEO_CALL : IFilterType.IN_CLINIC
          }
        />
      );
    } else {
      return (
        <Modal isOpen={open} closeHandler={onClose}>
          {getScreen()}
        </Modal>
      );
    }
  }

  if (step === StepForm.CHECKOUT) {
    return (
      <Checkout
        isOpen={isCheckoutShowDesktop}
        closeHandler={handleBack}
        type={
          selectedTab === 0 ? IFilterType.VIDEO_CALL : IFilterType.IN_CLINIC
        }
      />
    );
  } else {
    return getScreen();
  }
};

export default memo(MakeAppointment);
