import { useEffect, useRef, useState } from "react";
import { Typography, Button, Box } from "@mui/material";

import GetImages from "@/components/GetImages";

interface Props {
  text: string;
  color?: string;
  fontWeight?: number;
}

const LINE_COUNT = 4;

const CollapsibleText = ({
  text,
  color = "black",
  fontWeight = 500,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const computedStyle = window.getComputedStyle(textRef.current);
        const lineHeight = parseFloat(computedStyle.lineHeight);
        const maxHeight = lineHeight * LINE_COUNT;
        setIsOverflowing(textRef.current.scrollHeight > maxHeight);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow); // Recalculate on resize
    return () => window.removeEventListener("resize", checkOverflow);
  }, [text]); // Runs when text changes

  return (
    <Box>
      <Typography
        ref={textRef}
        variant="body2"
        color={color}
        fontWeight={fontWeight}
        lineHeight={"2.1rem"}
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: isExpanded ? "unset" : LINE_COUNT, // Show lines when collapsed
          overflow: "hidden",
        }}
      >
        {text}
      </Typography>
      {/** Show button ONLY if text is overflowing */}
      {isOverflowing && (
        <Button onClick={toggleExpanded} size="small" sx={{ mt: 1 }}>
          <GetImages name="EyeSmallIcon" width="20" height="16" />
          {isExpanded ? "View Less" : "View More"}
        </Button>
      )}
    </Box>
  );
};

export default CollapsibleText;
