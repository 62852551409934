import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

import style from "./index.module.css";

import GetImages from "@/components/GetImages";
import DischargedPatientDetail from "../DischargedPatientDetail";
import AdmitPatientShortDetail from "../AdmitPatientShortDetail";

import useIsTablet from "@/utils/hooks/useIsTablet";
import {
  calculateDayFromTargetDate,
  getLastMessageTime,
  transformClasses,
} from "@/utils";
import useIsMobile from "@/utils/hooks/useIsMobile";

import {
  IAdmitPatientPopUpsEnums,
  IPDashboardPatientAdmitStatus,
} from "@/models/doctor";
import { IHospitalPatient } from "@/models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchAdmitPatientDetails,
  setOpenCloseAdmittedDischargeModal,
  setReAdmitPatient,
} from "@/store/slices/doctor/ipDashboard";

import { useFirebaseMessagingContext } from "@/context/Chat";

import { storage } from "@/utils/Storage";

const IPDashboardCard: React.FC = () => {
  const [chatDetails, setChatDetails] = useState<{ [key: string]: any }>({});

  const { hospitalPatients, admitPatientPopUps, patientDetail } =
    useAppSelector((state) => state.ipDashboard);
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);
  const dispatch = useAppDispatch();
  const { channelList, listType } = useFirebaseMessagingContext();

  const currentUserId = storage.getSessionToken("user_id");

  const { isTablet } = useIsTablet();
  const { isMobile } = useIsMobile();

  // Update chatDetails whenever channelList changes
  useEffect(() => {
    const updatedChatDetails: { [key: string]: any } = {};
    hospitalPatients?.forEach((patient: IHospitalPatient) => {
      const channelDetail = channelList?.find(
        (channel: any) => channel.id === patient.id
      );
      if (channelDetail) {
        updatedChatDetails[patient.id] = channelDetail;
      }
    });
    setChatDetails(updatedChatDetails);
  }, [channelList, hospitalPatients, currentUserId, listType]);

  const openCloseAdmitPatientHandler = (type: IAdmitPatientPopUpsEnums) => {
    dispatch(setOpenCloseAdmittedDischargeModal(type));
  };

  const patientDetailHandler = (patientDetail: IHospitalPatient) => {
    dispatch(fetchAdmitPatientDetails({ admitPatientId: patientDetail?.id }));
    dispatch(setReAdmitPatient(false));
    if (isMobile || isTablet) {
      openCloseAdmitPatientHandler(
        patientDetail?.status === IPDashboardPatientAdmitStatus.DISCHARGED
          ? IAdmitPatientPopUpsEnums.DISCHARGED
          : IAdmitPatientPopUpsEnums.ADMITTED
      );
    }
  };

  const renderPatientCard = (patient: IHospitalPatient) => {
    const chatDetail = chatDetails[patient.id];
    return (
      <Grid
        item
        className={transformClasses(
          style.container,
          patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
            ? style.dischargeContainer
            : "",
          !(isMobile || isTablet) && patient?.id === patientDetail?.id
            ? style.active
            : ""
        )}
        sx={{
          backgroundColor:
            patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
              ? "var(--warm-neutral-50)"
              : "",
        }}
        key={patient.id}
        onClick={() => patientDetailHandler(patient)}
      >
        <Grid item className={style.patientDetail}>
          <Typography component={"p"} className={style.patientName}>
            {patient?.name}
          </Typography>
          <Typography component={"p"} className={style.admittedDateDuration}>
            {patient.status === IPDashboardPatientAdmitStatus.ADMIT ? (
              <>
                <GetImages name="GreenDotIcon" width="8" height="6" />
                {dayjs(patient?.admission_date).format("DD MMM")}
                &nbsp;
                {`(${calculateDayFromTargetDate(patient?.admission_date)})`}
              </>
            ) : (
              dayjs(patient?.discharged_date).format("DD MMM")
            )}
          </Typography>
        </Grid>
        <Grid item className={style.roomCaseHistory}>
          <Typography
            component={"p"}
            className={transformClasses(
              style.roomNo,
              patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                ? style.dischargedPatient
                : ""
            )}
            sx={{
              backgroundColor:
                patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                  ? "var(--peach-100)"
                  : "",
            }}
          >
            {patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
              ? "Discharged"
              : `${patient?.room_name} - Bed ${patient?.number}`}
          </Typography>

          {chatDetail ? (
            <Grid item display={"flex"} alignItems={"center"}>
              {(chatDetail?.lastMessage?.mentions?.length > 0 ||
                chatDetail?.lastMessage?.unReadMessageCount > 0) &&
                chatDetail?.lastMessage?.sender_id !== currentUserId && (
                  <Typography component={"p"} className={style.messageTime}>
                    {getLastMessageTime(chatDetail?.lastMessage?.timestamp)}
                  </Typography>
                )}
              {chatDetail?.lastMessage?.mentions?.length > 0 &&
              chatDetail?.lastMessage?.mentions?.some(
                (mention: any) => mention.userId === currentUserId
              ) ? (
                <Grid item className={style.messageStartMentionUnReadCount}>
                  <Grid item>
                    <GetImages name="StarMentionIcon" width="11" height="10" />
                  </Grid>
                  <Typography component={"p"}>
                    {chatDetail?.lastMessage?.mentions?.length}
                  </Typography>
                </Grid>
              ) : chatDetail?.lastMessage?.unReadMessageCount > 0 &&
                chatDetail?.lastMessage?.sender_id !== currentUserId ? (
                <Grid item className={style.messageUnReadCount}>
                  <Typography component={"p"}>
                    {chatDetail?.lastMessage?.unReadMessageCount}
                  </Typography>
                </Grid>
              ) : (
                <Typography component={"p"} className={style.caseHistory}>
                  {patient?.case_history}
                </Typography>
              )}
            </Grid>
          ) : (
            <Typography component={"p"} className={style.caseHistory}>
              {patient?.case_history}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  if (isMobile || isTablet) {
    return (
      <>
        {hospitalPatients?.map((patient: IHospitalPatient) =>
          renderPatientCard(patient)
        )}
        {admitPatientPopUps.isDischarged && (
          <DischargedPatientDetail
            isOpen={admitPatientPopUps.isDischarged}
            closeHandler={() =>
              openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.DISCHARGED)
            }
          />
        )}
        {admitPatientPopUps.isAdmitted && (
          <AdmitPatientShortDetail
            isOpen={admitPatientPopUps.isAdmitted}
            closeHandler={() =>
              openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.ADMITTED)
            }
          />
        )}
      </>
    );
  }

  return (
    <>
      {hospitalPatients?.map((patient: IHospitalPatient) =>
        renderPatientCard(patient)
      )}
      {admitPatientPopUps.isDischarged && isShowUniversalSearch && (
        <DischargedPatientDetail
          isOpen={admitPatientPopUps.isDischarged}
          closeHandler={() =>
            openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.DISCHARGED)
          }
        />
      )}
      {admitPatientPopUps.isAdmitted && isShowUniversalSearch && (
        <AdmitPatientShortDetail
          isOpen={admitPatientPopUps.isAdmitted}
          closeHandler={() =>
            openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.ADMITTED)
          }
        />
      )}
    </>
  );
};

export default IPDashboardCard;
