import React, { useState } from "react";
import dayjs from "dayjs";
import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import GetImages from "@/components/GetImages";

import style from "./index.module.css";

import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { getPermissionAccess, transformDateToMMDDYYY } from "@/utils";
import { storage } from "@/utils/Storage";
import { postReplyToPatientReviewApi } from "@/utils/apis/doctor/profile";
import { INotifyEnum, notify } from "@/utils/toaster";
import useIsTablet from "@/utils/hooks/useIsTablet";
import useIsMobile from "@/utils/hooks/useIsMobile";

import { IRoleType } from "@/models/role";
import {
  IReviewPermissionEnums,
  IRolePermissionAccess,
  IModalKeyEnums,
} from "@/models/permission";

import { fetchDoctorReviewRatingList } from "@/store/slices/doctor/profile";
import { setShowPermissionAlert } from "@/store/slices/permission";

const DoctorReviews: React.FC = () => {
  const [showReplySection, setShowReplySection] = useState<number | null>(null);
  const { isTablet } = useIsTablet();
  const { isMobile } = useIsMobile();
  const doctorProile = useAppSelector((state) => state.doctorProfile);
  const { permission } = useAppSelector((state) => state.rolePermission);
  const dispatch = useAppDispatch();

  const roleType = storage.getSessionToken("roleType");

  const intl = useIntl();

  const replyToReviewHandler = async (event: any, reviewId: string) => {
    if (event.key === "Enter") {
      const postReplyToPatientReviewResponse =
        await postReplyToPatientReviewApi({
          reply: event.target.value,
          reviewId,
        });
      notify(INotifyEnum.SUCCESS, postReplyToPatientReviewResponse.message);
      setShowReplySection(null);
      dispatch(fetchDoctorReviewRatingList({}));
    }
  };

  const replyToPatientReviewHandler = (indexValue: number) => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.REVIEW,
      IReviewPermissionEnums.REPLY_TO_REVIEW,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    setShowReplySection(indexValue);
  };

  return (
    <Grid item>
      {doctorProile?.ratingReviewList?.review?.length > 0 ? (
        doctorProile?.ratingReviewList?.review?.map((ratingList, index) => (
          <Grid
            item
            sx={{
              backgroundColor:
                roleType === IRoleType.DOCTOR && !ratingList.doctor_reply
                  ? "var(--warm-neutral-50)"
                  : "var(--neutral-100)",
            }}
            className={style.container}
            key={`${ratingList.id}-${index}`}
          >
            <Grid container>
              <Grid item xs={12} className={style.reviewerReplyHeader}>
                <Typography component={"h6"}>{ratingList.name}</Typography>
                <Typography component={"p"}>
                  {transformDateToMMDDYYY(ratingList?.createdAt)}
                </Typography>
              </Grid>
              <Grid item xs={12} className={style.reviewStarContainer}>
                {Array(ratingList?.no_of_stars).fill(
                  <GetImages
                    name="YellowStarSmallIcon"
                    width="16"
                    height="16"
                  />
                )}
                {ratingList?.no_of_stars !== 5 &&
                  Array(5 - ratingList?.no_of_stars).fill(
                    <GetImages
                      name="GreyedStarSmallIcon"
                      width="16"
                      height="16"
                    />
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography component={"p"} className={style.reviewerText}>
                  {ratingList?.review_message}
                </Typography>
                {!ratingList?.doctor_reply && roleType === IRoleType.DOCTOR && (
                  <>
                    {index !== showReplySection && (
                      <Typography
                        component={"p"}
                        className={style.replayToReview}
                        sx={{
                          textAlign: isMobile || isTablet ? "right" : "left",
                        }}
                        onClick={() => replyToPatientReviewHandler(index)}
                      >
                        {intl.formatMessage({
                          id: "reply",
                          defaultMessage: "Reply",
                        })}
                      </Typography>
                    )}
                    {index === showReplySection && (
                      <Grid item className={style.replyContainer}>
                        <Grid item>
                          <Grid item className={style.reviewerReplyHeader}>
                            <Typography component={"h6"}>
                              {doctorProile?.detail?.name}
                            </Typography>
                            <Typography component={"p"}>
                              {transformDateToMMDDYYY(dayjs())}
                            </Typography>
                          </Grid>
                          <Typography
                            component={"p"}
                            className={style.repliedMessageContainer}
                          >
                            <input
                              type="text"
                              placeholder="Type here"
                              onKeyDown={(event) =>
                                replyToReviewHandler(event, ratingList?.id)
                              }
                              style={{
                                backgroundColor: "var(--warm-neutral-50)",
                              }}
                              autoFocus
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {ratingList?.doctor_reply && (
                  <Grid item className={style.replyContainer}>
                    <Grid item>
                      <Grid item className={style.reviewerReplyHeader}>
                        <Typography component={"h6"}>
                          {doctorProile?.detail?.name}
                        </Typography>
                        <Typography component={"p"}>
                          {transformDateToMMDDYYY(ratingList?.updatedAt)}
                        </Typography>
                      </Grid>
                      <Typography
                        component={"p"}
                        className={style.repliedMessageContainer}
                      >
                        {ratingList?.doctor_reply}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography component={"h1"} textAlign={"center"} padding={"3rem"}>
          {intl.formatMessage({
            id: "no_patient_reviews",
            defaultMessage: "No Patient reviews",
          })}
        </Typography>
      )}
    </Grid>
  );
};

export default DoctorReviews;
