import React, { useState } from "react";
import { Grid } from "@mui/material";

import style from "./index.module.css";

import FullScreenDialog from "../FullScreenDialog";
import Header from "../Header";
import Button from "../Button";
import Confirmation from "../Confirmation";

import {
  base64ToFile,
  getPermissionAccess,
  transformClasses,
} from "../../utils";
import { uploadFileToS3Api } from "../../utils/apis/doctor/prescription";
import { storage } from "../../utils/Storage";

import { IButtonVariant } from "../../models/button";
import { IDocumentTypeEnum } from "../../models/doctor";
import { IRoleType } from "../../models/role";
import {
  IRolePermissionAccess,
  IUploadDocumentEnums,
  IModalKeyEnums,
} from "../../models/permission";
import { IUploadFileToS3ResultDetail } from "../../models/ApiRequestResponse/doctor";

import {
  setIsCameraOn,
  setIsCreateCameraPrescription,
  setIsPrescriptionCaptured,
} from "../../store/slices/doctor/prescription";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setMedicalReport } from "../../store/slices/doctor/medicalRecord";
import { setShowPermissionAlert } from "../../store/slices/permission";

interface IProps {
  type: IDocumentTypeEnum;
  imageUrl: string;
  uploadedS3URL: (url: IUploadFileToS3ResultDetail) => void;
}
const RetakeSaveCaptureImage: React.FC<IProps> = (props) => {
  const { type, imageUrl, uploadedS3URL } = props;
  const [showSavePrescriptionReport, setShowSavePrescriptionReport] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { isVideoCallInitiated } = useAppSelector(
    (state) => state.videoAppointment
  );
  const { permission } = useAppSelector((state) => state.rolePermission);

  const roleType = storage.getSessionToken("roleType");

  const retakeCameraHandler = () => {
    dispatch(setIsCameraOn());
    dispatch(setIsCreateCameraPrescription());
  };

  const openCloseSaveMedicalRecordModal = () =>
    setShowSavePrescriptionReport(!showSavePrescriptionReport);

  const saveCapturedImageToS3Bucket = async () => {
    try {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.UPLOAD_DOCUMENT,
        IUploadDocumentEnums.UPLOAD_TO_S3,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      const file = base64ToFile(imageUrl, "prescrition.png");
      const formData = new FormData();
      formData.append("file", file);
      const uploadFileToS3Response = await uploadFileToS3Api(type, formData);
      if ("result" in uploadFileToS3Response) {
        const { thumbnailUrl, url } = uploadFileToS3Response?.result;
        uploadedS3URL({ thumbnailUrl, url });
        openCloseSaveMedicalRecordModal();
        dispatch(
          setIsPrescriptionCaptured({
            capturedImage: imageUrl,
            isCapturedSave: true,
          })
        );
        if (roleType === IRoleType.PATIENT) {
          dispatch(setIsCreateCameraPrescription());
          dispatch(
            setMedicalReport({
              identifier: "isCapturedSave",
              value: false,
            })
          );
          dispatch(
            setMedicalReport({
              identifier: "image",
              value: "",
            })
          );
        }
      } else {
        console.error(
          "Upload failed, no result found in response",
          uploadFileToS3Response
        );
        openCloseSaveMedicalRecordModal();
      }
    } catch (error) {
      openCloseSaveMedicalRecordModal();
      console.error("Error occurred during image upload:", error);
    }
  };
  return (
    <FullScreenDialog lineBackgroundColor="white">
      <Header
        onGoBack={() => {
          uploadedS3URL({ thumbnailUrl: "", url: "" });
          openCloseSaveMedicalRecordModal();
          dispatch(setIsCameraOn());
        }}
        heading={""}
        muiStyles={{
          borderBottom: "0rem",
          backgroundColor: "var(--gray-50)",
          padding: "4.6rem 0rem 0rem 1.6rem",
        }}
      />
      <Grid item className={style.cameraImageContainer}>
        <Grid item className={style.capturedImageContainer}>
          <img src={imageUrl} alt="capturedImage" />
        </Grid>
        <Grid
          item
          className={transformClasses(
            isVideoCallInitiated
              ? style.videoInitiated
              : transformClasses(
                  style.actionBtnContainer,
                  style.cameraPrescriptionActionBtns
                )
          )}
        >
          <Grid item className={style.retakeBtn}>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={retakeCameraHandler}
            >
              Retake
            </Button>
          </Grid>
          <Button btnTrigger={openCloseSaveMedicalRecordModal}>Save</Button>
        </Grid>
      </Grid>
      {showSavePrescriptionReport && (
        <Confirmation
          isOpen={showSavePrescriptionReport}
          closeHandler={openCloseSaveMedicalRecordModal}
          title="confirm_save_title"
          alterMessage="confirm_alert_msg"
          takeActionLabel="yes_save"
          dontActionLabel="dont_save"
          actionHandler={saveCapturedImageToS3Bucket}
        />
      )}
    </FullScreenDialog>
  );
};

export default RetakeSaveCaptureImage;
