import { lazy } from "react";

const ScheduleListing = lazy(() => import("../../views/Doctor/Schedule"));
const AvailabilityListing = lazy(
  () => import("../../views/Doctor/AvailabilitySettings")
);
const CreateEditAvailability = lazy(
  () => import("../../views/Doctor/AvailabilitySettings/CreateEditAvailability")
);
const DoctorProfile = lazy(() => import("../../views/Doctor/Profile"));
const DoctorProfileDetail = lazy(
  () => import("../../views/Common/DoctorProfile")
);
const IPDashboard = lazy(() => import("../../views/Doctor/IPDashboard"));
const Reception = lazy(() => import("../../views/Doctor/Reception"));
const AdmitPatient = lazy(
  () => import("../../views/Doctor/IPDashboard/AdmitPatient")
);
const MobileChat = lazy(() => import("../../views/Doctor/MobileChat"));

const VideoCall = lazy(() => import("../../components/VideoCall"));

const DoctorRoutes = [
  { path: "/schedule", component: <ScheduleListing /> },
  { path: "/ip-dash", component: <IPDashboard /> },
  { path: "/opd-reception", component: <Reception /> },
  { path: "/admit-patient", component: <AdmitPatient /> },
  { path: "/chats", component: <MobileChat /> },
  { path: "/profile", component: <DoctorProfile /> },
  { path: "/profile-detail", component: <DoctorProfileDetail /> },
  { path: "/availability-settings", component: <AvailabilityListing /> },
  { path: "/availability-create", component: <CreateEditAvailability /> },
  {
    path: "/join-room",
    component: <VideoCall />,
  },
];

export default DoctorRoutes;
