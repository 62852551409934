import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey:
    import.meta.env.VITE_FIREBASE_API_KEY ||
    "AIzaSyCEA5mfK4Yp8yExT4vgmY-F5VHbJxpr_zY",
  authDomain:
    import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || "gbsc-cff2d.firebaseapp.com",
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || "gbsc-cff2d",
  storageBucket:
    import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ||
    "gbsc-cff2d.firebasestorage.app",
  messagingSenderId:
    import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || "553627239243",
  appId:
    import.meta.env.VITE_FIREBASE_APP_ID ||
    "1:553627239243:web:1304f6277e86eeb85f5ece",
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID || "G-71YK85WS4J",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const messaging = getMessaging(app);

export { messaging, db };

export const generateTokenFCM = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");

      const token = await getToken(messaging, {
        vapidKey:
          "BHzPebRymUvEfFL_-j43TbPzYV92ItsjO4FnBrUDABtI4A26dMY2zk7alHMRZ-aWYVaRNPhhijTcN6Qk4kwjgPM",
      });

      if (token) {
        return token;
      } else {
        console.error(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.error("Notification permission not granted.");
    }
  } catch (error) {
    console.error("An error occurred while retrieving the token:", error);
  }
};
