import React, { useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import GetImages from "@/components/GetImages";
import Dialog from "@/components/Dialog";

import style from "./index.module.css";

import { IButtonVariant } from "@/models/button";
import { IDropDownSelectionType } from "@/models/doctor";

import useIsMobile from "@/utils/hooks/useIsMobile";
import useIsTablet from "@/utils/hooks/useIsTablet";
interface ILabelValue {
  label: any;
  value: string;
}

interface IProps {
  type: IDropDownSelectionType;
  isOpen: boolean;
  closeHandler: () => void;
  title: string;
  values: ILabelValue[];
  selectedValue: string;
  saveSelectedValues: (value: string, type: IDropDownSelectionType) => void;
  btnLabel?: string;
  showCancelBtn?: boolean;
  backgroundColor?: string;
  showViewBtn?: boolean;
  width?: number;
}

const DropDownSelect: React.FC<IProps> = (props) => {
  const {
    type,
    title,
    values,
    selectedValue,
    saveSelectedValues,
    isOpen,
    closeHandler,
    btnLabel = "save",
    showCancelBtn = true,
    backgroundColor,
    showViewBtn = false,
    width,
  } = props;

  const [value, setValue] = useState(
    selectedValue?.length > 0 ? selectedValue : values && values[0]?.value
  );

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const saveSelection = () => saveSelectedValues(value, type);

  const DropdownUI = () => {
    return (
      <Grid item className={style.container}>
        <Typography component={"h6"}>
          <FormattedMessage
            id={title}
            defaultMessage={title || "Dropdown Title"}
          />
        </Typography>
        <Grid item className={style.gridCardContainer}>
          <RadioGroup value={value} onChange={handleSelection}>
            {values?.map((info, index) => (
              <Grid item className={style.card} key={`${info.value}-${index}`}>
                <FormControlLabel
                  value={info.value}
                  control={
                    <Radio
                      checkedIcon={
                        <GetImages
                          name="TickOrangeIcon"
                          width="20"
                          height="20"
                        />
                      }
                      icon={
                        <GetImages name="RadioBtnIcon" width="20" height="20" />
                      }
                    />
                  }
                  label={
                    <Typography component={"p"} className={style.dropdownLabel}>
                      {info.label}
                    </Typography>
                  }
                />
                {showViewBtn && (
                  <Grid item className={style.viewBtn}>
                    <Button variant={IButtonVariant.WHITE}>
                      <FormattedMessage
                        id="view_team"
                        defaultMessage="View Team"
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}
          </RadioGroup>
        </Grid>
        <Grid item className={style.btnContainer}>
          {showCancelBtn && (
            <Grid item width={"100%"}>
              <Button variant={IButtonVariant.WHITE} btnTrigger={closeHandler}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
            </Grid>
          )}
          <Grid
            item
            className={showCancelBtn ? style.saveBtn : style.btnFullWidth}
          >
            <Button btnTrigger={saveSelection}>
              <FormattedMessage id={btnLabel} defaultMessage="Submit" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {isMobile || isTablet ? (
        <Modal
          isOpen={isOpen}
          closeHandler={closeHandler}
          backgroundColor={backgroundColor}
          lineBottomBackground={backgroundColor}
        >
          {DropdownUI()}
        </Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth="sm"
          isOpen={isOpen}
          closeHandler={closeHandler}
          paperProps={{
            sx: {
              width: `${width}rem`,
            },
          }}
        >
          {DropdownUI()}
        </Dialog>
      )}
    </>
  );
};

export default DropDownSelect;
