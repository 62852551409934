import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import styles from "./index.module.css";
import { Star, ThumbUp } from "@mui/icons-material";

import GetImages from "../../../../components/GetImages";
import ShareDialog from "../../VideoDetails/ShareDialog";

import {
  favouriteDiseaseApi,
  likeDiseaseApi,
} from "../../../../utils/apis/patient/healthHub";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";

type DiseaseActionsMobileProps = {
  isLiked?: boolean;
  isFavourited?: boolean;
  diseaseId: string;
  likes: number;
};

const DiseaseActionsMobile: React.FC<DiseaseActionsMobileProps> = ({
  isLiked,
  isFavourited,
  diseaseId,
  likes,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDiseaseLiked, setIsDiseaseLiked] = useState(isLiked);
  const [isDiseaseFavourited, setIsDiseaseFavourited] = useState(isFavourited);

  useEffect(() => {
    setIsDiseaseLiked(isLiked);
    setIsDiseaseFavourited(isFavourited);
  }, [diseaseId]);

  const handleLike = async () => {
    const response = await likeDiseaseApi(diseaseId);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseLiked(true);
    }
  };

  const handleUnlike = async () => {
    const response = await likeDiseaseApi(diseaseId, true);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseLiked(false);
    }
  };

  const handleFavourite = async () => {
    const response = await favouriteDiseaseApi(diseaseId);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseFavourited(true);
    }
  };

  const handleUnfavourite = async () => {
    const response = await favouriteDiseaseApi(diseaseId, true);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseFavourited(false);
    }
  };

  const handleShare = () => {
    setIsDialogOpen(true);
  };

  const shareUrl =
    import.meta.env.NODE_ENV === "development"
      ? "http://localhost:3000/health-hub/disease"
      : "https://gbsc-web-dev.appskeeper.in/health-hub/disease";

  return (
    <Grid className={styles.actionButtonsContainer}>
      <Grid className={styles.actionButtonsWrapper}>
        <div
          className={`${styles.likeButton} ${
            isDiseaseLiked ? styles.liked : ""
          }`}
          onClick={isLiked ? handleUnlike : handleLike}
        >
          <ThumbUp fontSize="small" className={styles.likeIcon} />
          <span>{likes}</span>
        </div>
        <div
          className={`${styles.actionButton} ${
            isDiseaseFavourited ? styles.favourited : ""
          }`}
          onClick={isFavourited ? handleUnfavourite : handleFavourite}
        >
          <Star fontSize="small" className={styles.favouriteIcon} />
        </div>
        <div className={styles.actionButton} onClick={handleShare}>
          <GetImages name="ShareLongVideoMobileIcon" height="20" width="20" />
        </div>
      </Grid>
      <ShareDialog
        url={`${shareUrl}?id=${diseaseId}`}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </Grid>
  );
};

export default DiseaseActionsMobile;
