import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IChipVariant } from "../../../../models/button";
import ChipSelect from "../../../../components/ChipSelect";
import { IHealthCategory } from "../../../../models/ApiRequestResponse/doctor";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import style from "./index.module.css";
import { IAccordionInputChipListEnums } from "../../../../models/doctor";
import { setPrescriptionValues } from "../../../../store/slices/doctor/prescription";
import Title from "../../../../components/Title";
import AccordionWithTitle from "../../../../components/Prescription/AccordionWithTitle";

type HealthCategoriesProps = {
  hasInputField: boolean;
  title: string;
  identifier: string;
};

const HealthCategories: React.FC<HealthCategoriesProps> = ({
  hasInputField,
  title,
  identifier,
}) => {
  const prescription = useAppSelector((state) => state.prescription);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [nextVisitNumber, setNextVisitNumber] = useState<string>("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (identifier === IAccordionInputChipListEnums.HEALTH_CATEGORIES) {
      setSelectedValue(prescription.create.healthCategoryId || "");
    } else if (identifier === IAccordionInputChipListEnums.NEXT_VISIT) {
      setSelectedValue(prescription.create.nextVisitType || "");
      setNextVisitNumber(prescription.create.nextVisitNo?.toString() || "");
    }
  }, [prescription.create]);

  const handleValueChange = (newValue: string) => {
    setSelectedValue(newValue);
    if (identifier === IAccordionInputChipListEnums.HEALTH_CATEGORIES) {
      dispatch(setPrescriptionValues({ identifier, value: newValue }));
    } else if (identifier === IAccordionInputChipListEnums.NEXT_VISIT) {
      dispatch(
        setPrescriptionValues({
          identifier,
          value: [newValue, nextVisitNumber],
        })
      );
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) >= 0) {
      setNextVisitNumber(value);
      if (
        identifier === IAccordionInputChipListEnums.NEXT_VISIT &&
        selectedValue
      ) {
        dispatch(
          setPrescriptionValues({
            identifier,
            value: [selectedValue, value],
          })
        );
      }
    }
  };

  const chipSelectData = hasInputField
    ? prescription?.config?.nextVisitType?.map((nextVisit: string) => ({
        label: nextVisit,
        value: nextVisit,
      }))
    : prescription?.config?.healthCategory?.map(
        (category: IHealthCategory) => ({
          label: (
            <Grid item className={style.categoryContainer}>
              <img
                src={category.icon}
                alt="Category icon"
                width={22}
                height={16}
              />
              {category.name}
            </Grid>
          ),
          value: category.id,
        })
      );

  return (
    <>
      <Stack
        direction="column"
        sx={{
          display: { xs: "none", md: "block" },
        }}
        gap={2}
        width="100%"
      >
        <Title>{title}</Title>

        <Stack
          direction="row"
          gap={2}
          width="100%"
          alignItems="center"
          flexWrap="wrap"
          className={style.healthCategoriesContainer}
          sx={{
            padding: { xs: "0rem", md: "1.6rem 0rem" },
          }}
        >
          {hasInputField && (
            <input
              type="number"
              placeholder="Type here"
              value={nextVisitNumber}
              onChange={handleNumberChange}
              className={style.nextVisitInput}
            />
          )}
          <ChipSelect
            variant={
              !hasInputField ? IChipVariant.ROUNDED : IChipVariant.DEFAULT
            }
            data={chipSelectData}
            showCarousel={!hasInputField}
            selectedValue={selectedValue}
            setSelectedValue={handleValueChange}
            muiclass={{
              border: "0.1rem solid var(--gray-200)",
            }}
          />
        </Stack>
      </Stack>
      <Grid
        sx={{
          display: { xs: "block", md: "none" },
        }}
        className={style.accordionContainer}
      >
        <AccordionWithTitle title={title}>
          {hasInputField && (
            <input
              type="number"
              placeholder="Type here"
              value={nextVisitNumber}
              onChange={handleNumberChange}
              className={style.nextVisitInput}
            />
          )}
          <Stack
            direction="row"
            gap={2}
            width="100%"
            alignItems="center"
            className={style.healthCategoriesContainer}
            sx={{
              padding: "1rem 0rem",
            }}
          >
            <ChipSelect
              variant={
                !hasInputField ? IChipVariant.ROUNDED : IChipVariant.DEFAULT
              }
              data={chipSelectData}
              showCarousel={!hasInputField}
              selectedValue={selectedValue}
              setSelectedValue={handleValueChange}
              muiclass={{
                border: "0.1rem solid var(--gray-200)",
              }}
            />
          </Stack>
        </AccordionWithTitle>
      </Grid>
    </>
  );
};

export default HealthCategories;
