import { useEffect, useMemo, useState, useCallback } from "react";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  query,
  addDoc,
  orderBy,
  where,
  updateDoc,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  getDocs,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { db } from "@/firebaseConfig";

import { storage } from "@/utils/Storage";

import { IRoleType } from "@/models/role";
import {
  Channel,
  CHAT_LIST_TYPE,
  Message,
  UseFirebaseMessagingProps,
  UseFirebaseMessagingReturn,
} from "@/models/chat";

const FIREBASE_COLLECTION = "conversations";

const messageConverter: FirestoreDataConverter<Message> = {
  toFirestore(message: Message) {
    const { uniqueId, ...data } = message;
    return data;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Message {
    const data = snapshot.data(options);
    return {
      ...data,
      uniqueId: snapshot.id,
    } as Message;
  },
};

const channelConverter: FirestoreDataConverter<Channel> = {
  toFirestore(channel: Channel) {
    const { uniqueId, ...data } = channel;
    return data;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Channel {
    const data = snapshot.data(options);
    return {
      ...data,
      uniqueId: snapshot.id,
    } as Channel;
  },
};

const useFirebaseMessaging = ({
  chatListType = CHAT_LIST_TYPE.INTERNAL,
}: UseFirebaseMessagingProps): UseFirebaseMessagingReturn => {
  const currentUserId = storage.getSessionToken("user_id");
  const roleType = storage.getSessionToken("roleType");

  const [listType, setListType] = useState<CHAT_LIST_TYPE>(chatListType);
  const [channelId, setChannelId] = useState<string | null>(null);

  // Memoize the base query
  const baseQuery = useMemo(() => {
    const conversationRef = collection(db, FIREBASE_COLLECTION);
    return query(
      conversationRef.withConverter(channelConverter),
      ...(roleType === IRoleType.DOCTOR || roleType === IRoleType.JR_DOCTOR
        ? [where("users.staff_members", "array-contains", currentUserId ?? "")]
        : [
            where(
              "users.family_members",
              "array-contains",
              currentUserId ?? ""
            ),
          ])
    );
  }, [listType, currentUserId]);

  // Memoize chat collection references
  const chatRefs = useMemo(
    () => ({
      internal: channelId
        ? collection(db, FIREBASE_COLLECTION, channelId, "internal")
        : null,
      patient: channelId
        ? collection(db, FIREBASE_COLLECTION, channelId, "patient")
        : null,
    }),
    [channelId]
  );

  // Memoize chat queries
  const chatQueries = useMemo(
    () => ({
      internal: chatRefs.internal
        ? query(
            chatRefs.internal.withConverter(messageConverter),
            orderBy("timestamp", "asc")
          )
        : null,
      patient: chatRefs.patient
        ? query(
            chatRefs.patient.withConverter(messageConverter),
            orderBy("timestamp", "asc")
          )
        : null,
      patientMedia: chatRefs.patient
        ? query(
            chatRefs.patient.withConverter(messageConverter),
            orderBy("timestamp", "desc")
          )
        : null,
    }),
    [chatRefs]
  );

  // Use collection data hooks with proper typing
  const [channelList] = useCollectionData<Channel>(baseQuery);
  const [internalChatList] = useCollectionData<Message>(chatQueries.internal);
  const [patientChatList] = useCollectionData<Message>(chatQueries.patient);
  const [patientChatMediaListing] = useCollectionData<Message>(
    chatQueries.patientMedia
  );

  // Memoize filtered media list
  const patientChatMediaList = useMemo(
    () => patientChatMediaListing?.filter((item) => item.type === 2) ?? [],
    [patientChatMediaListing]
  );

  // Memoize messages based on list type
  const messages = useMemo(
    () =>
      listType === CHAT_LIST_TYPE.INTERNAL
        ? internalChatList ?? []
        : patientChatList ?? [],
    [internalChatList, patientChatList, listType]
  );

  // Memoized handlers
  const handleList = useCallback((list: CHAT_LIST_TYPE) => {
    setListType(list);
  }, []);

  const handleChannel = useCallback((id: string) => {
    setChannelId(id);
  }, []);

  const handleSendMessage = useCallback(
    async (message: Record<string, any> = {}) => {
      if (!channelId?.trim()) return;

      try {
        let mentions = [];
        const documentRef = doc(db, FIREBASE_COLLECTION, channelId);
        const docSnap = await getDoc(documentRef);
        const newLastMessage = {
          message: message.message,
          sender_id: message.sender_id,
          timestamp: message.timestamp,
          type: message.type,
          mentions: message.mentions || [],
          unReadMessageCount: 0,
        };
        const { message: msg, ...documentData } = message;

        delete documentData.message;
        delete documentData.users;
        if (docSnap.exists()) {
          const messagesRef = collection(
            db,
            FIREBASE_COLLECTION,
            channelId,
            listType === CHAT_LIST_TYPE.INTERNAL ? "internal" : "patient"
          );

          await addDocumentToCollection(message.message, listType);
          const unreadQuery = query(
            messagesRef.withConverter(messageConverter),
            where("isSeen", "==", false)
          );

          const unreadSnapshot = await getDocs(unreadQuery);

          const userData = docSnap.data();

          unreadSnapshot?.docs?.forEach((doc) =>
            mentions.push(...(doc.data()?.mentions || []))
          );
          // Update root document with new message counts and lastMessage
          await updateDoc(documentRef, {
            ...documentData,
            ...userData,
            lastMessage: {
              mentions: mentions,
              message: newLastMessage.message?.message,
              unReadMessageCount: unreadSnapshot?.docs?.length,
              sender_id: newLastMessage.message?.sender_id,
              timestamp: newLastMessage.message?.timestamp,
              type: newLastMessage.message?.type,
            },
          });
        } else {
          await setDoc(documentRef, {
            ...documentData,
            lastMessage: {
              ...documentData,
              ...newLastMessage,
            },
          });
          await addDocumentToCollection(message.message, listType);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
    [channelId, listType, currentUserId]
  );

  const addDocumentToCollection = async (
    message: Record<string, any>,
    type: CHAT_LIST_TYPE
  ) => {
    if (!channelId) return;

    try {
      const collectionRef = collection(
        db,
        FIREBASE_COLLECTION,
        channelId,
        type === CHAT_LIST_TYPE.INTERNAL ? "internal" : "patient"
      );
      await addDoc(collectionRef, message);
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  const markMessageAsRead = useCallback(
    async (
      messageId: string,
      channelID: string,
      subcollection: "internal" | "patient"
    ) => {
      if (!channelID) return;

      try {
        const documentRef = doc(db, FIREBASE_COLLECTION, channelID);
        const documentSnap = await getDoc(documentRef);

        const messageRef = doc(
          db,
          FIREBASE_COLLECTION,
          channelID,
          subcollection,
          messageId
        );
        const docSnap = await getDoc(messageRef);

        if (docSnap.exists()) {
          await updateDoc(messageRef, { isSeen: true });
        }
        if (documentSnap.exists()) {
          await updateDoc(documentRef, {
            lastMessage: {
              ...documentSnap.data().lastMessage,
              unReadMessageCount: 0,
              mentions: [],
            },
          });
        }
      } catch (error) {
        console.error("Error marking message as read:", error);
      }
    },
    []
  );

  const markAllMessagesAsRead = useCallback(
    async (channelID: string, subcollection: "internal" | "patient") => {
      if (!channelID) return;

      try {
        const documentRef = doc(db, FIREBASE_COLLECTION, channelID);
        const documentSnap = await getDoc(documentRef);

        const messagesRef = collection(
          db,
          FIREBASE_COLLECTION,
          channelID,
          subcollection
        );
        const unreadQuery = query(
          messagesRef.withConverter(messageConverter),
          where("isSeen", "==", false)
        );

        const unreadSnapshot = await getDocs(unreadQuery);
        await Promise.all(
          unreadSnapshot.docs.map((doc) => updateDoc(doc.ref, { isSeen: true }))
        );
        if (documentSnap.exists()) {
          await updateDoc(documentRef, {
            lastMessage: {
              ...documentSnap.data().lastMessage,
              unReadMessageCount: 0,
              mentions: [],
            },
          });
        }
      } catch (error) {
        console.error("Error marking all messages as read:", error);
      }
    },
    []
  );

  const removeMemberFromChat = useCallback(
    async (familyMemberId: string) => {
      if (!channelId) return;

      try {
        const channelRef = doc(db, FIREBASE_COLLECTION, channelId);
        const docSnap = await getDoc(channelRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const updatedFamilyMembers = data?.users?.family_members?.filter(
            (memberId: string) => memberId !== familyMemberId
          );

          await updateDoc(channelRef, {
            users: {
              ...data.users,
              family_members: updatedFamilyMembers,
            },
          });
        }
      } catch (error) {
        console.error("Error removing member from chat:", error);
      }
    },
    [channelId]
  );

  const getConversationData = useCallback(async () => {
    if (!channelId) return null;

    try {
      const documentRef = doc(db, FIREBASE_COLLECTION, channelId);
      const docSnap = await getDoc(documentRef);
      return docSnap.exists() ? docSnap.data() : null;
    } catch (error) {
      console.error("Error getting conversation data:", error);
      return null;
    }
  }, [channelId]);

  // Set list type based on role
  useEffect(() => {
    if (roleType === IRoleType.DOCTOR || roleType === IRoleType.JR_DOCTOR) {
      setListType(CHAT_LIST_TYPE.INTERNAL);
    } else {
      setListType(CHAT_LIST_TYPE.PATIENT);
    }
  }, [roleType]);

  return {
    listType,
    messages,
    patientChatMediaList,
    channelList,
    channelId,
    handleList,
    handleChannel,
    handleSendMessage,
    markMessageAsRead,
    markAllMessagesAsRead,
    removeMemberFromChat,
    getConversationData,
  };
};

export default useFirebaseMessaging;
