import {
  IAddFamilyMemberRequest,
  IAddFamilyMemberResponse,
} from "../../../models/ApiRequestResponse/chat";
import { GBSCError } from "../../../models/GBSCError";

import { patch, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const fetchUserDetails = async ({
  user_ids = [],
  doctor_ids = [],
  ip_dash_id = [],
  device_token_user_id = "",
}: Record<string, any>) => {
  try {
    const response = await post<any>(APIEndpoint.patient.fetchUserDetails, {
      user_ids,
      doctor_ids,
      ip_dash_id,
      device_token_user_id,
    });

    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }

    const { errorMessage, message, statusCode } = response;

    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
};

export const addFamilyMemberApi = async (
  requestBody: IAddFamilyMemberRequest
): Promise<IAddFamilyMemberResponse | GBSCError> => {
  try {
    const response = await patch<IAddFamilyMemberResponse>(
      APIEndpoint.doctor.addFamilyMember,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
