import { DocumentData } from "firebase/firestore";

export enum IMessageType {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export interface IFetchUserDetailsParams {
  user_ids: string[];
  doctor_ids: string[];
  ip_dash_id: string[];
  device_token_user_id: string;
}

export enum MESSAGE_TYPE {
  TEXT,
  AUDIO,
  ATTACHMENT,
  VIDEO_CALL,
  ORDER_MEDICINE,
  CONSULTATION_END,
}

export enum CONVERSATION_STATUS {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  COMPLETED = "COMPLETED",
  DISABLED = "DISABLED",
}

export enum CONVERSATION_TYPE {
  APPOINTMENT = "APPOINTMENT",
  IN_PATIENT = "IN_PATIENT",
  SUPPORT = "SUPPORT",
}

export interface Message {
  uniqueId: string;
  timestamp: number;
  sender_id: string;
  isSeen: boolean;
  mentions: Array<{ userId: string }>;
  type?: number;
  [key: string]: any;
}

export interface Channel {
  uniqueId: string;
  lastMessage: Message | null;
  unReadMessageCount: number;
  users: {
    staff_members: string[];
    family_members: string[];
  };
  [key: string]: any;
}

export enum CHAT_TYPE {
  CHAT,
  MEDIA,
  RECORDS,
}

export enum CHAT_LIST_TYPE {
  INTERNAL,
  PATIENT,
}
export interface UseFirebaseMessagingProps {
  chatListType: CHAT_LIST_TYPE;
}

export enum ITabIndex {
  APPOINTMENTS,
  IN_PATIENTS,
}

export interface UseFirebaseMessagingReturn {
  listType: CHAT_LIST_TYPE;
  messages: Message[];
  patientChatMediaList: Message[];
  channelList: Channel[];
  channelId: string | null;
  handleList: (list: CHAT_LIST_TYPE) => void;
  handleChannel: (channelId: string) => void;
  handleSendMessage: (message: Record<string, any>) => Promise<void>;
  markMessageAsRead: (
    messageId: string,
    channelID: string,
    subcollection: "internal" | "patient"
  ) => Promise<void>;
  markAllMessagesAsRead: (
    channelID: string,
    subcollection: "internal" | "patient"
  ) => Promise<void>;
  removeMemberFromChat: (familyMemberId: string) => Promise<void>;
  getConversationData: () => Promise<DocumentData | null>;
}
