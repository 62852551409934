import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button as MUIButton,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import MemberDetail from "@/components/MemberDetail";
import GetImages from "@/components/GetImages";

import style from "./index.module.css";

import { IPatientMemberDetailEnums } from "@/models/patient";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { checkoutDetail } from "@/store/slices/patient/findDoctor";
import { fetchPatientMembers } from "@/store/slices/patient/member";

import { updateMemberApi } from "@/utils/apis/patient/profile";
import { INotifyEnum, notify } from "@/utils/toaster";
import useIsMobile from "@/utils/hooks/useIsMobile";
import useIsTablet from "@/utils/hooks/useIsTablet";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  handleAddMember: () => void;
}

const ChangePatient: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler, handleAddMember } = props;

  const [selectedMember, setSelectedMember] = useState<string>("");

  const dispatch = useAppDispatch();
  const memberList = useAppSelector((state) => state.member);
  const checkoutPatientDetail = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMember((event.target as HTMLInputElement).value);
  };

  const changeSaveHandler = async () => {
    const filteredNameMemberById = memberList.list.filter(
      (member, index) => member.id === selectedMember
    )[0].name;
    dispatch(
      checkoutDetail({
        ...checkoutPatientDetail,
        memberId: selectedMember,
        name: filteredNameMemberById,
      })
    );
    closeHandler();
    const updateMemberResponse = await updateMemberApi(selectedMember, {
      is_default: true,
    });
    if ("result" in updateMemberResponse) {
      notify(INotifyEnum.SUCCESS, updateMemberResponse?.message);
    }
  };

  const changePatientMemberScreen = () => (
    <Grid item className={style.container}>
      <Typography component={"h6"}>
        <FormattedMessage id="choose_member" defaultMessage="Choose Member" />
      </Typography>
      <Grid item className={style.memberDetailContainer}>
        {memberList?.list.map((member, index) => (
          <MemberDetail
            type={IPatientMemberDetailEnums.CHECKOUT}
            key={`${member.id}-${index}`}
            memberId={member?.id}
            age={member.age!}
            name={member.name!}
            handleSelection={handleSelection}
            selectedMember={selectedMember}
          />
        ))}
        <Grid
          item
          className={style.addMemberContainer}
          onClick={handleAddMember}
        >
          <GetImages name="SmileAddIcon" width="24" height="24" />
          <Typography component={"p"}>
            <FormattedMessage id="add" defaultMessage="Add" />
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={style.btnsContainer}>
        <Grid item className={style.changeSaveBtn}>
          <Button btnTrigger={changeSaveHandler}>
            <FormattedMessage
              id="change_and_save"
              defaultMessage="Change and Save"
            />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    const memberListInfo = memberList?.list.filter((item) => item.is_default)[0]
      ?.id;
    setSelectedMember(memberListInfo);
  }, [memberList]);

  useEffect(() => {
    if (isOpen) dispatch(fetchPatientMembers());
  }, []);

  if (isOpen) {
    return (
      <>
        {isMobile || isTablet ? (
          <Modal
            isOpen={isOpen}
            closeHandler={closeHandler}
            backgroundColor="white"
            lineBottomBackground="white"
          >
            {changePatientMemberScreen()}
          </Modal>
        ) : (
          <>
            <DialogTitle>
              <Typography
                variant={"subtitle1"}
                fontWeight={500}
                textAlign={"center"}
              >
                <FormattedMessage
                  id="choose_member"
                  defaultMessage="Choose Member"
                />
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ height: "50rem", overflowY: "auto" }}>
              <Grid container gap={"1.2rem"}>
                {memberList?.list.map((member, index) => (
                  <Grid item xs={3.5} key={`${member.id}-${index}`}>
                    <MemberDetail
                      type={IPatientMemberDetailEnums.CHECKOUT}
                      memberId={member?.id}
                      age={member.age!}
                      name={member.name!}
                      handleSelection={handleSelection}
                      selectedMember={selectedMember}
                    />
                  </Grid>
                ))}
                <Grid
                  item
                  className={style.addMemberContainer}
                  xs={3.5}
                  onClick={handleAddMember}
                  component={MUIButton}
                >
                  <GetImages name="SmileAddIcon" width="24" height="24" />
                  <Typography component={"p"}>
                    <FormattedMessage id="add" defaultMessage="Add" />
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button btnTrigger={changeSaveHandler}>
                <FormattedMessage
                  id="change_and_save"
                  defaultMessage="Change and Save"
                />
              </Button>
            </DialogActions>
          </>
        )}
      </>
    );
  }
  return null;
};

export default ChangePatient;
