import React, { useState, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import GridIconText from "@/components/GridIconText";
import GetImages from "@/components/GetImages";
import Dialog from "@/components/Dialog";

import style from "./index.module.css";

import { transformClasses } from "@/utils";
import { styles } from "@/utils/styles";
import { storage } from "@/utils/Storage";
import useIsMobile from "@/utils/hooks/useIsMobile";
import { locationAccessDetail } from "@/utils/common";
import useIsTablet from "@/utils/hooks/useIsTablet";
import useLocationAddress from "@/utils/hooks/useLocationAddress";
import { INotifyEnum, notify } from "@/utils/toaster";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
}
const LocationAccess: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { fetchAddress } = useLocationAddress();
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const errorGetLocationAccess = (error: any) => {
    setIsLoading(false);
    let errorMessage = "";

    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage =
          "Please enable location permissions in your browser settings to continue.";
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        errorMessage = "Unable to detect your location. Please try again.";
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        errorMessage = "Location request timed out. Please try again.";
        console.log("The request to get user location timed out.");
        break;
      default:
        errorMessage =
          "An error occurred while getting your location. Please try again.";
        console.log("An unknown error occurred.");
        break;
    }
    notify(INotifyEnum.ERROR, errorMessage);
  };

  const successGetLocationAccess = async (position: GeolocationPosition) => {
    try {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      await fetchAddress({ latitude, longitude });
      storage.setSessionToken(
        "location",
        JSON.stringify({ latitude, longitude })
      );
      closeHandler();
      navigate("/find-doctor", { state: { navItem: 1 } });
    } catch (error) {
      console.error("Error processing location:", error);
      setIsLoading(false);
    }
  };

  const getLocation = () => {
    setIsLoading(true);
    // Clear any previous errors if you implement error state

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        successGetLocationAccess,
        errorGetLocationAccess,
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setIsLoading(false);
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const locationAccessScreen = useCallback(() => {
    return (
      <>
        <Grid item className={style.locationHeader}>
          <Grid item>
            <Grid item className={style.header}>
              <GetImages name="LocationAccessIcon" width="36" height="36" />
              <Typography component={"h3"} className={style.heading}>
                <FormattedMessage
                  id="enableLocationAccess"
                  defaultMessage="Enable Location Access"
                />
              </Typography>
            </Grid>
            <Typography component={"p"} className={style.subHeading}>
              <FormattedMessage
                id="locationAccessSubHead"
                defaultMessage="To provide you with the best experience, we need access to your
              location. This helps us to:"
              />
            </Typography>
          </Grid>
          <Grid item className={style.locationGridContainer}>
            {locationAccessDetail.map((info, index) => (
              <GridIconText key={`${info.title}-${index}`} {...info} />
            ))}
          </Grid>
          <Grid item className={style.locationMiddleText}>
            <Typography
              component={"p"}
              className={transformClasses(
                style.locationAccessText,
                style.locationLabeling
              )}
            >
              <FormattedMessage
                id="privacyLocationText"
                defaultMessage="We respect your privacy. Your location data will be used only for
              these purposes and will not be shared with third parties."
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid item className={style.buttonContainer}>
          <Typography
            component={"p"}
            className={transformClasses(
              style.locationAccessText,
              style.locationLabeling
            )}
            sx={styles.mb20}
          >
            <FormattedMessage
              id="allowAccessText"
              defaultMessage="By allowing access, you agree to our"
            />
            <Typography
              component={"span"}
              className={transformClasses(
                style.orangeText,
                style.locationLabeling
              )}
            >
              {" "}
              <FormattedMessage
                id="privacyPolicyText"
                defaultMessage="Privacy Policy"
              />
            </Typography>
          </Typography>
          <Button isLoading={isLoading} btnTrigger={getLocation}>
            <FormattedMessage
              id="allowLocationBtn"
              defaultMessage="Allow Location Access"
            />
          </Button>
        </Grid>
      </>
    );
  }, []);
  return (
    <>
      {isMobile || isTablet ? (
        <Modal lineBottomBackground="white">{locationAccessScreen()}</Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth="xs"
          isOpen={isOpen}
          closeHandler={closeHandler}
        >
          {locationAccessScreen()}
        </Dialog>
      )}
    </>
  );
};

export default LocationAccess;
