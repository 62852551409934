import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { FiberManualRecord } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import MobileTopNavstyle from "../../components/Navigation/Mobile/MobileTopNavigation/index.module.css";

import GetImages from "../../components/GetImages";

import {
  IChangeHospitalModuleName,
  IProfileDropdownEnums,
} from "../../models/doctor";
import { IRoleType } from "../../models/role";

import { isClinic, topBarDropdownDispatchingEnums } from "..";
import { detail } from "../../store/slices/doctor/availabilitySettings";
import {
  setChangeHospitalModuleName,
  setTopBarDropdownItems,
} from "../../store/slices/doctor/topNavigation";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setIsPendingOrderRequestsOpen } from "../../store/slices/pharmacyClinicDiagnostic";
import { setStepsTest } from "../../store/slices/patient/tests";
import { storage } from "../Storage";

const useMobileTopNavigation = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const availabilityDetail = useAppSelector(
    (state) => state.availability.detail
  );
  const dispatch = useAppDispatch();
  const { cartDetails } = useAppSelector((state) => state.tests);
  const { location } = useAppSelector((state) => state.config);

  const isClinicRole = isClinic();

  const generateNavItems = (
    setConfirmDeleteAvailability: React.Dispatch<React.SetStateAction<boolean>>,
    selectedHospital: string,
    pendingOrderList: any
  ) => {
    const doctorItem = [
      {
        path: "/availability-settings",
        icon: <GetImages name="SettingOrangeIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "availability_settings",
          defaultMessage: "Availability Settings",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item onClick={() => navigate(-1)}>
            <CloseIcon sx={{ height: 24, weight: 24 }} />
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/availability-create",
        icon: <GetImages name="SettingGreyedIcon" width="24" height="24" />,
        title: intl.formatMessage({
          id: "availability_settings",
          defaultMessage: "Availability Settings",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item className={MobileTopNavstyle.availabilityBtnsContainer}>
            {availabilityDetail && (
              <Grid
                item
                className={MobileTopNavstyle.trashBtn}
                onClick={() =>
                  setConfirmDeleteAvailability((prev: boolean) => !prev)
                }
              >
                <GetImages
                  name="TrashIcon"
                  width="24"
                  height="24"
                  fill="var(--red-600)"
                />
              </Grid>
            )}
            <Grid
              item
              onClick={() => {
                navigate(-1);
                dispatch(detail(null));
              }}
            >
              <CloseIcon sx={{ height: 24, weight: 24 }} />
            </Grid>
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/schedule",
        icon: <GetImages name="ScheduleNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "schedule",
          defaultMessage: "Schedule",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item className={MobileTopNavstyle.scheduleTopIconContainer}>
            <Link to={"/availability-settings"}>
              <GetImages name="SettingGreyedIcon" width="24" height="24" />
            </Link>
          </Grid>
        ),
        isShowSearch: true,
      },
      {
        path: "/ip-dash",
        icon: <GetImages name="IpDashNavIcon" width="36" height="36" />,
        title: selectedHospital,
        locationIcon: false,
        subTextHandler: () => {
          dispatch(
            setChangeHospitalModuleName(IChangeHospitalModuleName.FROM_LIST)
          );
          dispatch(
            setTopBarDropdownItems(
              topBarDropdownDispatchingEnums[
                IProfileDropdownEnums.CHANGE_HOSPITAL
              ]
            )
          );
        },
        subText: "Change",
        secondIcon: null,
        isShowSearch: true,
      },
      {
        path: "/chats",
        icon: <GetImages name="ChatNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "chats",
          defaultMessage: "Chats",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: null,
        isShowSearch: true,
      },
    ];

    const pharmacyItem = [
      {
        path: "/orders",
        icon: (
          <Grid item className={MobileTopNavstyle.pharmacyOrders}>
            <GetImages name="PharamcyOrdersIcon" width="55" height="16" />
            <Grid item className={MobileTopNavstyle.verticalLine}>
              <GetImages name="VerticalLineImage" width="1" height="22" />
            </Grid>
            <Grid
              item
              className={MobileTopNavstyle.request}
              onClick={() => dispatch(setIsPendingOrderRequestsOpen())}
            >
              <GetImages
                name={
                  pendingOrderList?.length > 0
                    ? "RequestNotificationDotIcon"
                    : "EyeOrangeIcon"
                }
                width="28"
                height="24"
              />
              <Typography component={"p"}>Request</Typography>
            </Grid>
          </Grid>
        ),
        title: "",
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item className={MobileTopNavstyle.scheduleTopIconContainer}>
            {!isClinicRole && (
              <Grid item onClick={() => navigate("/profile-details")}>
                <GetImages name="ProfileSmileIcon" width="24" height="24" />
              </Grid>
            )}
          </Grid>
        ),
        isShowSearch: true,
      },
      {
        path: "/clinic-patient",
        icon: <GetImages name="PatientsModuleIcon" width="95" height="14" />,
        title: "",
        locationIcon: false,
        subText: "",
        secondIcon: null,
        isShowSearch: true,
      },
    ];
    return {
      [IRoleType.PATIENT]: [
        {
          path: "/home",
          icon: <GetImages name="HomeNavIcon" width="36" height="36" />,
          title: storage.getSessionToken("name") ?? "Unknown",
          locationIcon: true,
          subText: location,
          secondIcon: (
            <Grid item onClick={() => navigate("/chat")}>
              <GetImages name="MessageNavIcon" width="24" height="24" />
            </Grid>
          ),
          isShowSearch: true,
        },
        {
          path: "/find-doctor",
          icon: <GetImages name="DoctorNavIcon" width="36" height="36" />,
          title: intl.formatMessage({
            id: "find_doctors",
            defaultMessage: "Find Doctors",
          }),
          locationIcon: true,
          subText: location,
          secondIcon: null,
          isShowSearch: true,
        },
        {
          path: "/medicines",
          icon: <GetImages name="MedicineNavIcon" width="36" height="36" />,
          title: intl.formatMessage({
            id: "get_medicines",
            defaultMessage: "Get Medicines",
          }),
          locationIcon: true,
          subText: location,
          secondIcon: null,
          isShowSearch: true,
        },
        {
          path: "/test-reports",
          icon: <GetImages name="TestNavIcon" width="36" height="36" />,
          title: intl.formatMessage({
            id: "book_tests",
            defaultMessage: "Book Tests",
          }),
          locationIcon: true,
          subText: location,
          secondIcon: (
            <Grid
              item
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                dispatch(setStepsTest(3));
              }}
            >
              <GetImages name="CartNavIcon" width="24" height="24" />
              <Typography className={MobileTopNavstyle.redMark}>
                {cartDetails?.tests?.length > 0 && (
                  <FiberManualRecord
                    style={{ fontSize: "small", color: "var(--red-400)" }}
                  />
                )}
              </Typography>
            </Grid>
          ),
          isShowSearch: true,
        },
        {
          path: "/health-hub",
          icon: <GetImages name="TvNavIcon" width="36" height="36" />,
          title: intl.formatMessage({
            id: "health_hub",
            defaultMessage: "Health Hub",
          }),
          secondIcon: null,
          locationIcon: false,
          subText: "",
          isShowSearch: true,
        },
      ],
      [IRoleType.DOCTOR]: doctorItem,
      [IRoleType.JR_DOCTOR]: doctorItem,
      [IRoleType.PHARMACY]: pharmacyItem,
      [IRoleType.DIAGNOSTIC_CENTER]: pharmacyItem,
    };
  };
  return { generateNavItems };
};

export default useMobileTopNavigation;
