import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Typography, Stack, CircularProgress } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import Header from "../../../../components/Header";
import GetImages from "../../../../components/GetImages";
import { InputField } from "../../../../components/InputField";
import Title from "../../../../components/Title";
import TitleInputValueList from "../../../../components/Prescription/TitleInputValueList";
import FullScreenDialog from "../../../../components/FullScreenDialog";
import Button from "../../../../components/Button";
import CreateMedicineTestModal from "../../../../components/Prescription/CreateMedicineTestModal";
import Confirmation from "../../../../components/Confirmation";
import SharePrescription from "../../../../components/Prescription/SharePrescription";
import ChipSelect from "../../../../components/ChipSelect";
import Dialog from "../../../../components/Dialog";
import Camera from "../../../Common/Camera";
import CreatePrescriptionByCamera from "./CreatePrescriptionByCamera";
import MedicineTestInputSuggestion from "../../../../components/Prescription/MedicineTestInputSuggestion";
import HealthCategories from "../HealthCategories";
import TemplateSelector from "../TemplateSelector";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  fetchPrescriptionConfig,
  fetchPrescriptionTemplateList,
  setIsCameraOn,
  setIsCameraPrescriptionInitiated,
  setPrescriptionTemplateValues,
  setVitals,
} from "../../../../store/slices/doctor/prescription";
import {
  setIsVideoMinimizedOrPrescInitiated,
  setVideoAppointmentDetail,
} from "../../../../store/slices/videoAppointment";
import { setShowPermissionAlert } from "../../../../store/slices/permission";

import { IButtonVariant, IChipVariant } from "../../../../models/button";
import {
  IDocumentTypeEnum,
  IMedicineTestModalShow,
  IPatientAppointmentStatus,
  ISaveTypePrescription,
  ITitleInputValuesListEnums,
  IVitalsIdentifiers,
} from "../../../../models/doctor";
import { IPrescriptionTemplateValue } from "../../../../models/ApiRequestResponse/doctor";
import {
  IRolePermissionAccess,
  IUploadDocumentEnums,
  IModalKeyEnums,
  IPrescriptionPermissionEnums,
} from "../../../../models/permission";

import {
  accordionInputChipList,
  titleInputValueList,
  vitalsInputList,
  uploadDataList,
} from "../config";

import { INotifyEnum, notify } from "../../../../utils/toaster";
import {
  getActivePrescriptionApi,
  getLastPrescriptionApi,
  uploadFileToS3Api,
} from "../../../../utils/apis/doctor/prescription";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import usePrescriptionCreate from "../../../../utils/hooks/usePrescriptionCreate";
import { getPermissionAccess } from "../../../../utils";

import { IHealthCategory } from "../../../../models/ApiRequestResponse/doctor";
import useIsTablet from "../../../../utils/hooks/useIsTablet";
import { setAppointmentDetail } from "../../../../store/slices/doctor/appointment";

const MemoizedChipSelect = React.memo(ChipSelect);

interface ICreatePrescriptionProps {
  onClose?: () => void;
}

const CreatePrescription: React.FC<ICreatePrescriptionProps> = ({
  onClose,
}) => {
  const [isAddMedicineTestShow, setIsAddMedicineTestShow] =
    useState<IMedicineTestModalShow>({
      medicine: false,
      test: false,
    });
  const [showAddFileModal, setShowAddFileModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filePreview, setFilePreview] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [browseTemp, setBrowseTemp] =
    React.useState<IPrescriptionTemplateValue | null>(null);
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const intl = useIntl();

  const {
    isSavePrescriptionLoading,
    isEndPrescriptionLoading,
    pdfUrl,
    openCloseSavePrescriptionModal,
    showSavePrescription,
    handleCreatePrescriptionHandler,
    sharePrescription,
    openCloseSharePrescriptionModal,
    createPrescriptionByUploadHandler,
    isSaved,
  } = usePrescriptionCreate();
  const { template } = useAppSelector((state) => state.prescription);
  const { list }: any = template;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSaved) {
      onClose && onClose();
      navigate("/schedule");
    }
  }, [isSaved]);

  const appointmentDetail = useAppSelector(
    (state) => state.appointment.appointmentDetail
  );
  const prescription = useAppSelector((state) => state.prescription);
  const {
    isVideoMinimizedOrPrescInitiated,
    isVideoCallInitiated,
    isCameraOn,
    appointmentDetail: videoAppointmentDetail,
  } = useAppSelector((state) => state.videoAppointment);
  const { permission } = useAppSelector((state) => state.rolePermission);

  const dispatch = useAppDispatch();
  const healthCategorys = prescription?.config?.healthCategory?.map(
    (category: IHealthCategory) => ({
      label: (
        <Grid item className={style.categoryContainer}>
          <img src={category.icon} alt="Category icon" width={22} height={16} />
          {category.name}
        </Grid>
      ),
      value: category.id,
    })
  );
  const openCamera = () => {
    if (isVideoCallInitiated && isCameraOn) {
      notify(
        INotifyEnum.ERROR,
        "Turn off your camera before capturing the prescription"
      );
      return;
    }
    dispatch(setIsCameraPrescriptionInitiated(true));
    dispatch(setIsCameraOn());
  };

  const closeHandler = () => {
    const closeMedicineTestModal: any = {};
    for (let key in isAddMedicineTestShow) {
      closeMedicineTestModal[key] = false;
    }
    setIsAddMedicineTestShow(closeMedicineTestModal);
  };

  const capturePrescription = useCallback(() => {
    return (
      <Grid item className={style.capturePrescription}>
        <GetImages name="CameraIcon" width="28" height="24" />
        <Typography component={"p"}>Camera</Typography>
      </Grid>
    );
  }, []);

  const handleVitalsInput = (value: string, identifier: IVitalsIdentifiers) => {
    dispatch(setVitals({ identifier, value }));
  };

  const pdfImageUploadHandler = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
      ];
      if (!allowedTypes.includes(file.type)) {
        notify(INotifyEnum.ERROR, "Only image files are allowed.");
      } else {
        try {
          const permissionAccess = getPermissionAccess(
            IModalKeyEnums.UPLOAD_DOCUMENT,
            IUploadDocumentEnums.UPLOAD_TO_S3,
            permission
          );
          if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
            dispatch(setShowPermissionAlert());
            return;
          }

          const formData = new FormData();
          const type = IDocumentTypeEnum.PRESCRIPTION;
          formData.append("file", file);
          const uploadFileToS3Response = await uploadFileToS3Api(
            type,
            formData
          );
          if ("result" in uploadFileToS3Response) {
            const { thumbnailUrl, url } = uploadFileToS3Response.result;
            setFilePreview(thumbnailUrl ?? url);
          } else {
            console.error(
              "Upload failed, no result found in response",
              uploadFileToS3Response
            );
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error("Error occurred during image upload:", error);
        }
      }
      fileInputRef.current!.value = ""; // Clear the input
    }
  };

  const addFileModal = useCallback(
    () => (
      <Stack gap={2} p={2}>
        <Typography className={style.title}>Add File</Typography>

        {filePreview ? (
          <Stack alignItems="flex-start" width="100%" gap={2}>
            <div className={style.previewFileSection}>
              {isLoading ? (
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <CircularProgress sx={{ color: "var(--orange-500)" }} />
                </Stack>
              ) : (
                <img src={filePreview} alt="Preview" />
              )}
              <Grid className={style.changeImageButtonContainer}>
                <button onClick={pdfImageUploadHandler}>
                  <GetImages name="UploadIcon" width="20" height="20" />
                  Change
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept="image/*,application/pdf"
                />
              </Grid>
            </div>
            <Stack alignItems="flex-start" width="100%" gap={1}>
              <Typography className={style.title}>Health Categories</Typography>
              <Grid item className={style.nextVisitTypeSelector}>
                <MemoizedChipSelect
                  variant={IChipVariant.DEFAULT}
                  data={healthCategorys}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  muiclass={{
                    borderRadius: "3rem",
                    border: "0.1rem solid var(--gray-200)",
                  }}
                />
              </Grid>
            </Stack>
          </Stack>
        ) : (
          <Grid item className={style.uploadButton}>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={() => pdfImageUploadHandler()}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "var(--orange-500)" }} />
              ) : (
                <>
                  <GetImages name="UploadIcon" width="20" height="20" />
                  Click here to upload
                </>
              )}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*,application/pdf"
            />
          </Grid>
        )}
        <Stack alignItems="center" width="100%" direction="row" mt={2}>
          <Button
            disabled={(!filePreview && !selectedValue) || isLoading}
            variant={
              !filePreview && !selectedValue
                ? IButtonVariant.GREY
                : IButtonVariant.ORANGE
            }
            btnTrigger={() => {
              createPrescriptionByUploadHandler(
                selectedValue,
                filePreview,
                handleClose
              );
              dispatch(
                setAppointmentDetail({
                  ...appointmentDetail,
                  status: IPatientAppointmentStatus.COMPLETED,
                })
              );
              dispatch(
                setVideoAppointmentDetail({
                  ...appointmentDetail,
                  status: IPatientAppointmentStatus.COMPLETED,
                })
              );
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    ),
    [filePreview, selectedValue, isLoading]
  );

  const handleClose = () => {
    setShowAddFileModal(!showAddFileModal);
    setFilePreview("");
    setSelectedValue("");
  };

  // Browse Templates change
  const handleChange = (value: IPrescriptionTemplateValue) => {
    // Destructure to exclude the id, doctorId, name
    const { id, doctorId, name, ...rest } = value;

    setBrowseTemp(value);
    dispatch(setPrescriptionTemplateValues(rest));
  };

  const createPrescriptionGrid = () => {
    return (
      <>
        <Grid item className={style.container}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              padding: {
                xs: "1.6rem 0rem 0rem 1.6rem",
                md: "1.6rem 1.6rem 0rem 1.6rem",
              },
            }}
            className={style.topActionBtnContainer}
          >
            <Grid item className={style.saveTemplate}>
              <TemplateSelector onSelect={handleChange} />
            </Grid>
            {!(isMobile || isTablet) && (
              <Grid
                item
                className={style.uploadPrescription}
                sx={{
                  width: "14rem",
                  height: "4.8rem",
                  borderBlockColor: "var(--gray-200)",
                }}
              >
                <Button
                  variant={IButtonVariant.WHITE}
                  btnTrigger={() => setShowAddFileModal(!showAddFileModal)}
                >
                  <GetImages name="UploadIcon" width="20" height="20" />
                  Upload
                </Button>
              </Grid>
            )}
          </Stack>
          <Dialog
            maxWidth="xs"
            fullWidth
            isOpen={showAddFileModal}
            closeHandler={() => handleClose()}
            paperProps={{
              sx: {
                width: "50rem",
                borderRadius: "1.6rem !important",
              },
            }}
          >
            {addFileModal()}
          </Dialog>
          <Grid item className={style.vitalsContainer}>
            <Title>
              <FormattedMessage id="vitals" defaultMessage="Vitals" />
            </Title>
            <Grid item className={style.vitalsInputsContainer}>
              {vitalsInputList.map((input, index) => (
                <InputField
                  key={`${input.label}-${index}`}
                  type={"text"}
                  value={prescription?.create[input.identifier] || ""}
                  label={intl.formatMessage({
                    id: input.label,
                    defaultMessage: input.label,
                  })}
                  placeholder={intl.formatMessage({
                    id: input.label,
                    defaultMessage: input.label,
                  })}
                  onChange={(event) =>
                    handleVitalsInput(event.target.value, input.identifier)
                  }
                />
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            className={style.inputContainer}
            sx={{ padding: { md: "0rem 1.6rem" } }}
          >
            <Grid sx={{ display: { md: "block", xs: "none" }, width: "100%" }}>
              <Title>
                <FormattedMessage id="details" defaultMessage="Details" />
              </Title>
            </Grid>
            <Grid
              className={style.innerInputContainer}
              sx={{
                gap: { md: "1.6rem", xs: "2.5rem" },
              }}
            >
              {titleInputValueList?.slice(0, 4).map((item, index) => (
                <TitleInputValueList {...item} key={`${index}-${item.title}`} />
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            className={style.medicineTestContainer}
            sx={{ padding: { md: "0rem 1.6rem" } }}
          >
            <Grid sx={{ display: { xs: "none", md: "block" }, width: "100%" }}>
              <MedicineTestInputSuggestion />
            </Grid>
            <Grid
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: "column",
                gap: "2.5rem",
              }}
            >
              {uploadDataList.map((item, index) => (
                <TitleInputValueList
                  isAddMedicineTestShow={isAddMedicineTestShow}
                  setIsAddMedicineTestShow={setIsAddMedicineTestShow}
                  {...item}
                  key={`${index}-${item.title}`}
                />
              ))}
              {(isAddMedicineTestShow.medicine ||
                isAddMedicineTestShow.test) && (
                <CreateMedicineTestModal
                  type={
                    isAddMedicineTestShow.medicine
                      ? ITitleInputValuesListEnums.MEDICINE
                      : ITitleInputValuesListEnums.TEST
                  }
                  isOpen={
                    isAddMedicineTestShow.medicine || isAddMedicineTestShow.test
                  }
                  closeHandler={closeHandler}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            item
            className={style.inputContainer}
            sx={{ padding: { md: "0rem 1.6rem" } }}
          >
            <Grid
              className={style.innerInputContainer}
              sx={{ gap: { md: "1.6rem", xs: "2.5rem" } }}
            >
              {titleInputValueList
                .slice(4, titleInputValueList.length)
                .map((item, index) => (
                  <TitleInputValueList
                    {...item}
                    key={`${index}-${item.title}`}
                  />
                ))}
            </Grid>
          </Grid>
          <Grid
            className={style.healthCategoriesContainer}
            sx={{
              padding: { xs: "0rem", md: "0rem 1.6rem" },
            }}
          >
            <HealthCategories {...accordionInputChipList[0]} />
          </Grid>

          <Grid
            className={style.healthCategoriesContainer}
            sx={{
              padding: { xs: "0rem", md: "0rem 1.6rem" },
            }}
          >
            <HealthCategories {...accordionInputChipList[1]} />
          </Grid>

          {!isVideoMinimizedOrPrescInitiated && (
            <Grid
              item
              className={style.actionBtnContainer}
              sx={{
                position: { xs: "sticky", md: "relative" },
                padding: {
                  xs: "1.6rem 1.6rem 2.5rem 1.6rem",
                  md: "1.6rem 1.6rem 1.6rem 1.6rem",
                },
                justifyContent: { xs: "space-between", md: "flex-end" },
                borderTop: { xs: "0.1rem solid var(--gray-200)", md: "none" },
                backgroundColor: "var(--gray-50)",
              }}
            >
              <Stack
                direction="row"
                width="100%"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item className={style.savePrescription}>
                  <Button
                    isLoading={isSavePrescriptionLoading}
                    disabled={!prescription?.create?.healthCategoryId}
                    variant={
                      !prescription?.create?.healthCategoryId
                        ? IButtonVariant.GREY
                        : IButtonVariant.WHITE
                    }
                    btnTrigger={() =>
                      handleCreatePrescriptionHandler(
                        ISaveTypePrescription.SAVE_PRESCRIPTION,
                        false
                      )
                    }
                  >
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </Button>
                </Grid>
                <Grid item className={style.endPrescription}>
                  <Button
                    isLoading={isEndPrescriptionLoading}
                    disabled={!prescription?.create?.healthCategoryId}
                    variant={
                      !prescription?.create?.healthCategoryId
                        ? IButtonVariant.GREY
                        : IButtonVariant.ORANGE
                    }
                    btnTrigger={() =>
                      handleCreatePrescriptionHandler(
                        ISaveTypePrescription.END_CONSULTATION,
                        true
                      )
                    }
                  >
                    <GetImages
                      name="PrescriptionSmallIcon"
                      width="20"
                      height="20"
                    />
                    <FormattedMessage
                      id="end_consultation"
                      defaultMessage="End Consultation"
                    />
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          )}
        </Grid>
        {showSavePrescription && (
          <Confirmation
            isOpen={showSavePrescription}
            closeHandler={openCloseSavePrescriptionModal}
            title="confirm_save_title"
            alterMessage="end_consultation_msg"
            takeActionLabel="yes_save"
            dontActionLabel="dont_save"
            actionHandler={() =>
              handleCreatePrescriptionHandler(
                ISaveTypePrescription.SAVE_PRESCRIPTION,
                true
              )
            }
          />
        )}
        {sharePrescription && (
          <SharePrescription
            isOpen={true}
            closeHandler={() => {
              openCloseSharePrescriptionModal();
              onClose && onClose();
              navigate("/schedule");
            }}
            pdfUrl={pdfUrl}
          />
        )}
      </>
    );
  };

  const getActivePrescription = async () => {
    const currentAppointment = isVideoCallInitiated
      ? videoAppointmentDetail
      : appointmentDetail;
    const patientId = currentAppointment?.patient?.patientId;
    const memberId = currentAppointment?.patient?.memberId;
    const activePrescriptionResponse = await getActivePrescriptionApi(
      patientId,
      memberId
    );
    if ("result" in activePrescriptionResponse) {
      dispatch(
        setPrescriptionTemplateValues(activePrescriptionResponse?.result)
      );
    } else {
      console.error(activePrescriptionResponse?.message);
    }
  };

  const getLastPrescription = async () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.PRESCRIPTION,
      IPrescriptionPermissionEnums.LAST_PRESCRIPTION,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }

    const bookingId = appointmentDetail?.id;
    const lastPrescriptionResponse = await getLastPrescriptionApi(bookingId);
    if ("result" in lastPrescriptionResponse) {
      dispatch(setPrescriptionTemplateValues(lastPrescriptionResponse?.result));
    }
  };

  const backHandler = () => {
    if (isVideoMinimizedOrPrescInitiated) {
      dispatch(setIsVideoMinimizedOrPrescInitiated(false));
    } else {
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (Object.keys(prescription.config).length === 0) {
      dispatch(fetchPrescriptionConfig());
    }
    dispatch(fetchPrescriptionTemplateList());
  }, []);

  useEffect(() => {
    const currentAppointment = isVideoCallInitiated
      ? videoAppointmentDetail
      : appointmentDetail;
    if (currentAppointment?.activePrescription) {
      getActivePrescription();
    }
    if (
      !appointmentDetail?.activePrescription &&
      appointmentDetail?.status === IPatientAppointmentStatus.PENDING
    ) {
      getLastPrescription();
    }
  }, [appointmentDetail]);

  if (prescription?.isCameraOn) {
    return <Camera />;
  }
  if (prescription?.isCreateCameraPrescription) {
    return (
      <CreatePrescriptionByCamera
        onClose={() => {
          dispatch(setIsCameraPrescriptionInitiated(false));
          dispatch(setIsCameraOn());
        }}
      />
    );
  }
  return (
    <>
      {isMobile || isTablet ? (
        <FullScreenDialog backgroundColor="var(--gray-50)">
          <Header
            heading="Create Prescription"
            subHeading={appointmentDetail?.patient?.name}
            secondOption={capturePrescription()}
            secondOptionHandler={openCamera}
            previousPage={backHandler}
          />
          {createPrescriptionGrid()}
        </FullScreenDialog>
      ) : (
        <>{createPrescriptionGrid()}</>
      )}
    </>
  );
};

export default CreatePrescription;
