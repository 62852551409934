import React from "react";
import { Grid } from "@mui/material";

import GetImages from "@/components/GetImages";

import style from "./index.module.css";

interface IProps {
  createHandler: () => void;
}
const CreateAction: React.FC<IProps> = (props) => {
  const { createHandler } = props;
  return (
    <Grid item className={style.createAction} onClick={createHandler}>
      <GetImages name="BlackWhitePlusIcon" width="26" height="26" />
    </Grid>
  );
};

export default CreateAction;
