import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import FullScreenDialog from "../../../../../components/FullScreenDialog";
import GetImages from "../../../../../components/GetImages";
import Button from "../../../../../components/Button";
import AccordionInputChip from "../../../../../components/Prescription/AccordionInputChip";
import Header from "../../../../../components/Header";
import RetakeSaveCaptureImage from "../../../../../components/RetakeSaveCaptureImage";
import SharePrescription from "../../../../../components/Prescription/SharePrescription";

import style from "../index.module.css";

import { IButtonVariant } from "../../../../../models/button";
import {
  IAccordionInputChipListEnums,
  IDocumentTypeEnum,
} from "../../../../../models/doctor";
import { IUploadFileToS3ResultDetail } from "../../../../../models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  fetchPrescriptionConfig,
  setIsCreateCameraPrescription,
  setUploadedS3URL,
} from "../../../../../store/slices/doctor/prescription";

import { transformClasses } from "../../../../../utils";
import usePrescriptionCreate from "../../../../../utils/hooks/usePrescriptionCreate";

interface ICreatePrescriptionByCameraProps {
  onClose: () => void;
}

const CreatePrescriptionByCamera: React.FC<
  ICreatePrescriptionByCameraProps
> = ({ onClose }) => {
  const navigate = useNavigate();

  const {
    createPrescriptionByUploadHandler,
    pdfUrl,
    openCloseSharePrescriptionModal,
    sharePrescription,
  } = usePrescriptionCreate();

  const dispatch = useAppDispatch();
  const prescription = useAppSelector((state) => state.prescription);
  const appointmentDetail = useAppSelector(
    (state) => state.appointment.appointmentDetail
  );
  const { isVideoCallInitiated } = useAppSelector(
    (state) => state.videoAppointment
  );

  const receivedUploadedImageUrlHandler = (
    url: IUploadFileToS3ResultDetail
  ) => {
    dispatch(setUploadedS3URL(url));
  };

  useEffect(() => {
    if (Object.keys(prescription.config).length === 0) {
      dispatch(fetchPrescriptionConfig());
    }
  }, []);

  if (!prescription.isCapturedSave) {
    return (
      <RetakeSaveCaptureImage
        type={IDocumentTypeEnum.PRESCRIPTION}
        imageUrl={prescription?.capturedImage}
        uploadedS3URL={receivedUploadedImageUrlHandler}
      />
    );
  }
  return (
    <FullScreenDialog>
      <Header
        onGoBack={onClose}
        muiStyles={{
          borderBottom: "0rem",
          padding: "4.6rem 0rem 0rem 2.6rem",
        }}
        heading={`${appointmentDetail?.patient?.name}, ${appointmentDetail?.patient?.age} yrs`}
      />
      <Grid item className={style.cameraImageContainer}>
        <Grid className={style.capturedImageContainer}>
          <img
            src={
              prescription?.uploadedS3URL?.thumbnailUrl ??
              prescription?.uploadedS3URL?.url
            }
            alt="capturedImage"
          />
        </Grid>
        <Grid item>
          <AccordionInputChip
            hasInputField={false}
            title={"Health categories"}
            identifier={IAccordionInputChipListEnums.HEALTH_CATEGORIES}
          />
        </Grid>
        {!isVideoCallInitiated && (
          <Grid
            item
            className={transformClasses(
              style.actionBtnContainer,
              style.cameraPrescriptionActionBtns
            )}
            sx={{
              backgroundColor: { xs: "var(--neutral-100)", md: "transparent" },
              position: { xs: "sticky", md: "relative" },
              borderTop: { xs: "0.1rem solid var(--gray-200)", md: "none" },
            }}
          >
            <Grid item className={style.savePrescription}>
              <Button
                variant={IButtonVariant.WHITE}
                btnTrigger={() => dispatch(setIsCreateCameraPrescription())}
              >
                Cancel
              </Button>
            </Grid>
            <Button
              variant={
                prescription?.create?.healthCategoryId?.length === 0
                  ? IButtonVariant.GREY
                  : IButtonVariant.ORANGE
              }
              disabled={prescription?.create?.healthCategoryId?.length === 0}
              btnTrigger={() => {
                createPrescriptionByUploadHandler(
                  prescription?.create?.healthCategoryId,
                  prescription?.uploadedS3URL?.thumbnailUrl ??
                    prescription?.uploadedS3URL?.url
                );
                dispatch(setIsCreateCameraPrescription());
              }}
            >
              <GetImages name="PrescriptionSmallIcon" width="20" height="20" />
              <Typography component={"span"} sx={{ ml: "0.5rem" }}>
                End Consultation
              </Typography>{" "}
            </Button>
          </Grid>
        )}
      </Grid>
      {sharePrescription && (
        <SharePrescription
          isOpen={sharePrescription}
          closeHandler={openCloseSharePrescriptionModal}
          pdfUrl={pdfUrl}
        />
      )}
    </FullScreenDialog>
  );
};

export default CreatePrescriptionByCamera;
