import React, { useEffect, useRef } from "react";
import { Chip, Grid, SxProps, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import { IChipVariant } from "@/models/button";
import { IKeyValueChip } from "@/models";

import { transformClasses } from "@/utils";

import GetImages from "@/components/GetImages";

interface IProps {
  data: IKeyValueChip[];
  variant?: IChipVariant;
  showCarousel?: boolean;
  showClearBtn?: boolean;
  selectedValue: string;
  setSelectedValue: (value: string, identifier?: string) => void;
  clear?: () => void;
  showSlots?: boolean;
  showSelectedChip?: boolean;
  muiclass?: SxProps<Theme>;
}
const ChipSelect: React.FC<IProps> = (props) => {
  const {
    muiclass,
    data,
    variant = "",
    showCarousel = false,
    showClearBtn = false,
    selectedValue,
    setSelectedValue,
    clear,
    showSlots = false,
    showSelectedChip = true,
  } = props;

  const carouselRef = useRef<any>(null);

  const handleClearBtn = () => {
    clear && clear();
    carouselRef.current.scrollLeft = 0;
    if (showSelectedChip) {
      setSelectedValue(data[0]?.value);
    } else {
      setSelectedValue("");
    }
  };

  const clearBtn = () => {
    if (showSelectedChip && selectedValue === data[0]?.value) return null;
    return (
      <>
        {selectedValue.length > 0 &&
          (selectedValue !== data[0]?.value || !showSelectedChip) && (
            <Grid item className={style.clearBtn} onClick={handleClearBtn}>
              <CloseIcon
                sx={{ width: 16, height: 16, color: "var(--neutral-100)" }}
              />
              <Typography component={"p"} className={style.clearText}>
                <FormattedMessage id="clear" defaultMessage="Clear" />
              </Typography>
            </Grid>
          )}
      </>
    );
  };

  const handleChip = (event: React.ChangeEvent<any>, chipValue: any) => {
    if (chipValue?.isBooked) return;
    setSelectedValue(chipValue.value);
  };

  useEffect(() => {
    if (showSlots) return;

    if (showSelectedChip && data && data[0]?.value) {
      setSelectedValue(data[0].value);
    } else {
      setSelectedValue("");
    }
  }, [showSelectedChip]);

  return (
    <Grid
      item
      className={transformClasses(
        style.chipContainer,
        showCarousel ? style.showCarousel : style.nonCarousel
      )}
      ref={carouselRef}
      sx={{
        maxWidth: { xs: "100%" },
        overflow: { xs: "scroll" },
      }}
    >
      {data?.map((chip: any, index: number) => (
        <Chip
          key={`${chip.label}-${index}`}
          sx={{
            border: "0.1rem solid var(--gray-200)",
            height: "min-content",
            padding: "0.8rem 1.2rem",
            width: "max-content",
            borderRadius: "0.8rem",
            ...muiclass,
          }}
          label={
            <>
              {chip.label}{" "}
              {chip.isSelection && (
                <>
                  {" "}
                  {selectedValue === chip.value ? (
                    <GetImages
                      name="DownArrowWhiteIcon"
                      width="10"
                      height="6"
                    />
                  ) : selectedValue !== chip.value ? (
                    <GetImages name="DownArrowIcon" width="10" height="6" />
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          }
          onClick={(event) => handleChip(event, chip)}
          className={transformClasses(
            variant === IChipVariant.ROUNDED ? style.roundChip : "",
            chip.isBooked ? transformClasses(style.notAvailable) : "",
            chip.value === selectedValue
              ? style.selected
              : style.selectionDefault,
            style.chipSelectHover
          )}
        />
      ))}
      {showClearBtn && clearBtn()}
    </Grid>
  );
};

export default ChipSelect;
