import { styled, TextField as InputField } from "@mui/material";

const style = {
  textArea: {
    "& textarea": {
      width: "-webkit-fill-available",
      fontFamily: "Poppins",
      color: "var(--black-900)",
      border: "none",
      padding: "2.6rem 0.8rem",
      borderRadius: "0.8rem",
      borderBottom: "0.1rem solid var(--gray-200)",
      background: "var(--neutral-100)",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "var(--gray-400)",
        fontSize: "1.4rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
  },
};
const TextArea = styled("textarea")(
  ({ theme }) => style.textArea["& textarea"]
);

export { InputField, TextArea };
