import React, { Suspense, useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { onMessage } from "firebase/messaging";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";

import Router from "./routes/Router";

import "./App.css";
import "./colors.css";

import { LanguageProvider } from "./i18n";

import Loader from "./components/Loader";
import IncomingCall from "./components/VideoCall/CallingScreen/IncomingCall";
import Confirmation from "./components/Confirmation";
import ScrollToTop from "./components/ScrollToTop";
import Notification from "./components/Chat/Notification";

import { IAPIStatusCode } from "./utils/apis/APIEndpointConfig";
import { getAccessTokenApi } from "./utils/apis/onboarding/OnBoardingApis";
import { storage } from "./utils/Storage";
import { getPermissionAccess } from "./utils";
import useLogout from "./utils/hooks/useLogout";
import useLocationAddress from "./utils/hooks/useLocationAddress";

import incomingRingtone from "./assets/incoming_ring.mp3";

import { generateTokenFCM, messaging } from "./firebaseConfig";

import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  setCallerDetails,
  setFCMDeviceToken,
} from "./store/slices/videoAppointment";
import {
  setRoleAndPermission,
  setShowPermissionAlert,
} from "./store/slices/permission";
import { setMessageNotification } from "./store/slices/chat";

import {
  IModalKeyEnums,
  IRolePermissionAccess,
  IUserPermssionEnums,
} from "./models/permission";

import { theme } from "./theme";

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  const { callerDetails } = useAppSelector((state) => state.videoAppointment);
  const { messageNotification } = useAppSelector((state) => state.chat);
  const { showAlert, permission } = useAppSelector(
    (state) => state.rolePermission
  );

  const currentUserId = storage.getSessionToken("user_id");

  const { logoutOnExpire } = useLogout();
  const { fetchAddress } = useLocationAddress();
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const getAccessTokenDetail = async () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.USER,
      IUserPermssionEnums.GET_USER_BASIC_INFO,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    const getAccessTokenRes = await getAccessTokenApi();
    if (
      getAccessTokenRes.statusCode === IAPIStatusCode.SUCCESS &&
      "result" in getAccessTokenRes
    ) {
      dispatch(setRoleAndPermission(getAccessTokenRes?.result?.permissions));
      storage.setSessionToken("user_id", getAccessTokenRes?.result?.user_id);
      storage.setSessionToken("roleType", getAccessTokenRes?.result?.role);
    }
  };

  const interceptCalls401 = () => {
    return axios.interceptors.response.use(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
        }
        return response;
      },
      (error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === IAPIStatusCode.UNAUTHENTICATED) {
            logoutOnExpire();
            setLoading(false);
          }
        }
        return Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    const interceptorId = interceptCalls401();
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const deviceToken = await generateTokenFCM();
      console.log("deviceTokens", deviceToken);
      dispatch(setFCMDeviceToken(deviceToken));
      await fetchAddress();
    })();
  }, []);

  useEffect(() => {
    getAccessTokenDetail();
    onMessage(messaging, (payload: any) => {
      if (!payload?.data?.conversationId) {
        dispatch(setCallerDetails(payload?.data));
        // Play ringtone for incoming call
        if (!audioRef.current) {
          audioRef.current = new Audio(incomingRingtone);
          audioRef.current.loop = true;
        }
        audioRef.current.play().catch((err) => {
          console.warn("Could not play ringtone:", err);
        });
      } else {
        dispatch(setMessageNotification(payload));
      }
      console.log("Foreground message received:", payload);
    });

    navigator.serviceWorker
      .register("/firebase-messaging-sw.js") // Path relative to root
      .then((registration) => {
        console.log("Service Worker registered:", registration);
      })
      .catch((error) => {
        console.error("Service Worker registration failed:-", error);
      });
  }, []);

  useEffect(() => {
    const handleBackgroundMessage = (event: MessageEvent) => {
      if (event.data.type === "BACKGROUND_MESSAGE") {
        const payload = event.data.payload;
        const isConversation = event.data.isConversation;
        console.log("BACKGROUND_MESSAGE received:", payload);

        if (!isConversation) {
          dispatch(setCallerDetails(payload?.data));
          if (!audioRef.current) {
            audioRef.current = new Audio(incomingRingtone);
            audioRef.current.loop = true;
          }
          audioRef.current.play().catch((err) => {
            console.warn("Could not play ringtone:", err);
          });
        } else {
          dispatch(setMessageNotification(payload));
        }
      }
    };

    navigator.serviceWorker.addEventListener(
      "message",
      handleBackgroundMessage
    );
  }, [dispatch]);

  useEffect(() => {
    if (!callerDetails && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [callerDetails]);

  if (loading) {
    return null;
  }

  return (
    <Suspense fallback={<Loader />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LanguageProvider>
            <ToastContainer />
            <ScrollToTop />
            <Router />
            {callerDetails && <IncomingCall />}
            {showAlert && (
              <Confirmation
                isOpen={showAlert}
                closeHandler={() => dispatch(setShowPermissionAlert())}
                showActionBtn={false}
                title="permission_access_title"
                alterMessage="permission_access"
                dontActionLabel="okay"
              />
            )}
            {messageNotification &&
              messageNotification?.data?.senderId !== currentUserId && (
                <Notification />
              )}
            <div id="portal-root"></div>
          </LanguageProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

export default App;
