import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import style from "../styles/index.module.css";

import Modal from "@/components/Modal";
import { InputField } from "@/components/InputField";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";

import { IButtonType, IButtonVariant } from "@/models/button";
import { IMobileVerify } from "@/models/onboarding";

import useIsMobile from "@/utils/hooks/useIsMobile";
import useIsTablet from "@/utils/hooks/useIsTablet";
import { useAppSelector } from "@/store/hooks";

interface IProps {
  isOpen: boolean;
  mobileSubmitHandler: (values: IMobileVerify) => void;
  closeHandler: () => void;
}

const VerifyPatientContact = (props: IProps) => {
  const { isLoading } = useAppSelector((state) => state.patientExistOrNot);
  const [mobileNumber, setMobileNumber] = useState<string>("");

  const { isOpen, mobileSubmitHandler, closeHandler } = props;

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const intl = useIntl();

  const handleChangeMobileNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    setMobileNumber(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mobileNumber.trim().length === 10) {
      mobileSubmitHandler({ mobileNo: mobileNumber });
    }
  };

  const mobileInputField = () => {
    return (
      <>
        <DialogTitle>
          <Typography
            variant={"h5"}
            fontWeight={500}
            display={{ lg: "block", xs: "none" }}
          >
            <FormattedMessage
              id={"admit_patient"}
              defaultMessage="headerNavigationText"
            />
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            display={{ lg: "none", xs: "flex" }}
          >
            <IconButton
              sx={{ color: "var(--black-900)" }}
              onClick={closeHandler}
            >
              <ArrowBackIosIcon sx={{ fontSize: 18 }} />
            </IconButton>
            <Typography variant="subtitle2" fontWeight={500}>
              <FormattedMessage id="admit_patient" />
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack gap={2.5}>
            <Typography component={"h3"} className={style.heading}>
              <FormattedMessage id={"enter_patient_contact"} />
            </Typography>
            <form>
              <InputField
                type="text"
                inputMode="numeric"
                inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                value={mobileNumber}
                onChange={handleChangeMobileNo}
                label={intl.formatMessage({
                  id: "mobileNumberText",
                  defaultMessage: "Mobile Number",
                })}
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "mobileNumberText",
                  defaultMessage: "Mobile Number",
                })}
                fullWidth
              />
            </form>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction={"row"}
            gap={2}
            alignItems={"flex-end"}
            width={{ xs: "100%", lg: "50%" }}
            px={2}
          >
            {!(isMobile || isTablet) && (
              <Button
                btnTrigger={closeHandler}
                variant={IButtonVariant.WHITE}
                type={IButtonType.SUBMIT}
                disabled={isLoading}
              >
                <FormattedMessage id={"cancel"} defaultMessage="Cancel" />
              </Button>
            )}
            <Button
              variant={
                mobileNumber.trim().length !== 10
                  ? IButtonVariant.GREY
                  : IButtonVariant.ORANGE
              }
              btnTrigger={handleSubmit}
              disabled={mobileNumber.trim().length !== 10 || isLoading}
              isLoading={isLoading}
            >
              <FormattedMessage id={"check_number"} />
            </Button>
          </Stack>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      {isMobile || isTablet ? (
        <Modal
          isOpen={isOpen}
          closeHandler={closeHandler}
          lineBottomBackground="white"
        >
          {mobileInputField()}
        </Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth="sm"
          isOpen={isOpen}
          closeHandler={closeHandler}
          paperProps={{
            sx: {
              pt: 2,
              pb: 2,
              maxWidth: "72rem",
            },
          }}
        >
          {mobileInputField()}
        </Dialog>
      )}
    </>
  );
};

export default VerifyPatientContact;
