import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputAdornment,
  Typography,
  Dialog as MUIDialog,
  Stack,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import style from "./index.module.css";

import GetImages from "@/components/GetImages";
import Modal from "@/components/Modal";
import DropDownSelect from "@/components/DropDownSelect";
import Button from "@/components/Button";
import { InputField } from "@/components/InputField";

import { IButtonVariant } from "@/models/button";
import {
  IAppointmentCreation,
  IAppointmetEnums,
  IDoctorAvailabilityPlatform,
  IDoctorConsultationSelection,
  IDoctorScheduleConsultationType,
  IDropDownSelectionType,
  IGender,
} from "@/models/doctor";
import {
  IPatientMemberPermissionEnums,
  IRolePermissionAccess,
  IModalKeyEnums,
  IUserPermssionEnums,
} from "@/models/permission";

import { IAPIStatusCode } from "@/utils/apis/APIEndpointConfig";
import { reserveAppointmentFromPatient } from "@/utils/apis/patient/doctor";
import { INotifyEnum, notify } from "@/utils/toaster";
import useIsMobile from "@/utils/hooks/useIsMobile";
import { getPermissionAccess, isClinic } from "@/utils";
import useIsTablet from "@/utils/hooks/useIsTablet";
import { storage } from "@/utils/Storage";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { makePatientAppointment } from "@/store/slices/doctor/appointment";
import { resetPatientDetailErrorMessages } from "@/store/slices/doctor/checkMobilePatientExistOrNot";
import { setShowPermissionAlert } from "@/store/slices/permission";

interface IProps {
  openScheduleModalHandler: (type: IAppointmetEnums, value?: string) => void;
  isScheduleCreateOpen: IAppointmentCreation;
  isOpen: boolean;
  backHandler: (value: IAppointmetEnums) => void;
  closeModalHandler: () => void;
  notExistPatient: string;
}
const EnterPatientDetail: React.FC<IProps> = (props) => {
  const {
    openScheduleModalHandler,
    isScheduleCreateOpen,
    isOpen,
    backHandler,
    notExistPatient,
    closeModalHandler,
  } = props;

  const dispatch = useAppDispatch();
  const makePatientAppointmentInfo = useAppSelector(
    (state) => state.appointment.makePatientAppointment
  );
  const { fechedPatientDetail, checkNumberError } = useAppSelector(
    (state) => state.patientExistOrNot
  );
  const { permission } = useAppSelector((state) => state.rolePermission);

  const intl = useIntl();

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const isClinicRole = isClinic();
  const doctor_name = storage.getSessionToken("doctor_name");
  const [gender, setGender] = useState<IGender>({
    isOpen: false,
    value: "",
  });

  const [patientDetail, setPatientDetail] = useState({
    name: "",
    age: "",
    isAppointmentBtnEnabled: true,
    changeMemberShow: false,
    memberId: "",
  });

  const saveSelectedValues = (value: string, type: IDropDownSelectionType) => {
    if (type === IDropDownSelectionType.GENDER) {
      setGender({ isOpen: !gender.isOpen, value: value });
    } else {
      const getNameByMemberId = fechedPatientDetail?.members?.filter(
        (member, index) => member.id === value
      )[0];
      setPatientDetail({
        ...patientDetail,
        memberId: value,
        name: getNameByMemberId?.name || "",
        age: getNameByMemberId?.age?.toString() || "",
        changeMemberShow: !patientDetail.changeMemberShow,
      });
      setGender({ ...gender, value: getNameByMemberId?.gender || "" });
    }
  };

  const mobileCheckHandler = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.USER,
      IUserPermssionEnums.SEARCH_PATIENT,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    if (checkNumberError?.statusCode === IAPIStatusCode.FORBIDDEN) {
      dispatch(resetPatientDetailErrorMessages());
      return;
    }
    openScheduleModalHandler(
      IAppointmetEnums.PATIENT_DETAIL,
      makePatientAppointmentInfo?.mobileNo
    );
  };

  const showListMemberHandler = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.PATIENT_MEMBER,
      IPatientMemberPermissionEnums.VIEW_PATIENT_MEMBER_LIST,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    setPatientDetail({
      ...patientDetail,
      changeMemberShow: !patientDetail.changeMemberShow,
    });
  };
  const handleReserveAppointmentFromDoctor = async () => {
    const reserveAppointmentPatientResponse =
      await reserveAppointmentFromPatient({
        patientId: fechedPatientDetail?.userId,
        ...(patientDetail?.memberId?.length > 0 && {
          memberId: patientDetail?.memberId,
        }),
        slotId: makePatientAppointmentInfo?.slotId,
        consultationType:
          makePatientAppointmentInfo?.type ===
          IDoctorAvailabilityPlatform.VIDEO_CALL
            ? IDoctorScheduleConsultationType.VIDEO_CALL
            : IDoctorScheduleConsultationType.IN_CLINIC,
        paymentAmount: makePatientAppointmentInfo?.fee,
        ...(makePatientAppointmentInfo?.type ===
          IDoctorAvailabilityPlatform.IN_CLINIC && {
          clinicId: makePatientAppointmentInfo?.clinicId,
        }),
        patientName: patientDetail.name,
        patientAge: patientDetail.age.toString(),
        patientGender: gender.value,
      });
    if ("result" in reserveAppointmentPatientResponse) {
      notify(INotifyEnum.SUCCESS, reserveAppointmentPatientResponse.message);
      openScheduleModalHandler(
        IAppointmetEnums.SUCCESS,
        reserveAppointmentPatientResponse.result.slotNo.toString()
      );
      dispatch(
        makePatientAppointment({
          ...makePatientAppointmentInfo,
          name: patientDetail.name,
          age: patientDetail.age.toString(),
          gender: gender.value,
        })
      );
    } else {
      notify(INotifyEnum.ERROR, reserveAppointmentPatientResponse.message);
    }
  };

  const backModalHandler = () => {
    backHandler(
      isScheduleCreateOpen.mobileCheck
        ? IAppointmetEnums.CREATION
        : IAppointmetEnums.MOBILE_CHECK
    );
  };

  const editMobileNoPatientExist = () => {
    openScheduleModalHandler(IAppointmetEnums.MOBILE_CHECK);
    dispatch(resetPatientDetailErrorMessages());
    setPatientDetail({ ...patientDetail, age: "", name: "" });
    setGender({ ...gender, value: "" });
  };

  const handleValidateMobileNo = (value: string) => {
    if (/^[0-9]{0,10}$/.test(value)) {
      dispatch(
        makePatientAppointment({
          ...makePatientAppointmentInfo,
          mobileNo: value,
        })
      );
    }
  };
  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value);
    if (value < 0) value = 0;
    else if (value > 123) value = 123;
    setPatientDetail({
      ...patientDetail,
      age: value.toString(),
    });
  };
  const EnterPatientDetailScreen = () => (
    <Grid item className={style.patientDetailContainer}>
      <Grid item className={style.header} onClick={backModalHandler}>
        <GetImages name="LeftArrowIcon" width="16" height="16" />
        <Typography component={"p"}>
          <FormattedMessage
            id="make_appointment"
            defaultMessage="Make an appointment"
          />
        </Typography>
      </Grid>
      <Grid item className={style.slotContainer}>
        <GetImages name="ScheduleSelectedSmallIcon" width="16" height="16" />
        <Typography component={"p"}>
          {makePatientAppointmentInfo?.dateSlotTime}
        </Typography>
      </Grid>

      <Grid item className={style.clinicAddressContainer}>
        <GetImages
          name={
            makePatientAppointmentInfo?.type ===
            IDoctorAvailabilityPlatform.VIDEO_CALL
              ? "VideoIcon"
              : "ClinicSmallIcon"
          }
          width="20"
          height="16"
        />
        <Typography component={"p"}>
          {makePatientAppointmentInfo?.type ===
          IDoctorAvailabilityPlatform.VIDEO_CALL
            ? IDoctorConsultationSelection.VIDEO_CONSULTATION
            : makePatientAppointmentInfo?.clinicName}
        </Typography>
      </Grid>
      <hr className={style.line} />
      <Typography component={"h6"}>
        <FormattedMessage
          id="enter_patient_contact"
          defaultMessage="Enter Patient Contact"
        />
      </Typography>
      <FormControl
        variant="outlined"
        fullWidth
        className={style.formPatientInputField}
      >
        <InputField
          type="text"
          inputMode="numeric"
          label={intl.formatMessage({
            id: "mobile_number",
            defaultMessage: "Mobile Number*",
          })}
          value={makePatientAppointmentInfo?.mobileNo}
          onChange={(event) => handleValidateMobileNo(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {notExistPatient && (
                  <Typography
                    component={"p"}
                    className={style.editMobileNumber}
                  >
                    <FormattedMessage id="check" defaultMessage="Check" />
                  </Typography>
                )}
                {isScheduleCreateOpen.patientAlreadyExist && (
                  <Typography
                    component={"p"}
                    className={style.editMobileNumber}
                    onClick={editMobileNoPatientExist}
                  >
                    <FormattedMessage id="edit" defaultMessage="edit" />
                  </Typography>
                )}
              </InputAdornment>
            ),
          }}
        />
        {checkNumberError?.statusCode === IAPIStatusCode.FORBIDDEN && (
          <Grid item>
            <Typography component={"p"} className={style.staffNoAvailService}>
              {checkNumberError?.message}
            </Typography>
          </Grid>
        )}
      </FormControl>
      {!isScheduleCreateOpen.mobileCheck && (
        <Grid item>
          <FormControl fullWidth className={style.formPatientInputField}>
            <InputField
              value={patientDetail.name}
              onChange={(event) =>
                setPatientDetail({
                  ...patientDetail,
                  name: event.target.value,
                })
              }
              label={intl.formatMessage({ id: "name", defaultMessage: "Name" })}
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "name",
                defaultMessage: "Name",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {fechedPatientDetail?.members &&
                      fechedPatientDetail?.members?.length > 1 && (
                        <Typography
                          component={"p"}
                          className={style.editMobileNumber}
                          onClick={showListMemberHandler}
                        >
                          <FormattedMessage
                            id="change"
                            defaultMessage="Change"
                          />
                        </Typography>
                      )}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth className={style.formPatientInputField}>
            <InputField
              type="number"
              value={patientDetail.age}
              onChange={handleAgeChange}
              label={intl.formatMessage({ id: "age", defaultMessage: "Age" })}
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "age",
                defaultMessage: "Age",
              })}
              fullWidth
            />
          </FormControl>
          <FormControl
            variant="outlined"
            fullWidth
            className={style.formPatientInputField}
          >
            <InputField
              label={intl.formatMessage({
                id: "gender",
                defaultMessage: "Gender",
              })}
              value={gender.value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      component={"p"}
                      className={style.editMobileNumber}
                      onClick={() =>
                        setGender({ ...gender, isOpen: !gender.isOpen })
                      }
                    >
                      <FormattedMessage id="select" defaultMessage="Select" />
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      )}
      {isScheduleCreateOpen.mobileCheck && (
        <Button
          variant={
            makePatientAppointmentInfo?.mobileNo?.length !== 10
              ? IButtonVariant.GREY
              : IButtonVariant.ORANGE
          }
          disabled={makePatientAppointmentInfo?.mobileNo?.length !== 10}
          btnTrigger={mobileCheckHandler}
        >
          <FormattedMessage
            id={
              checkNumberError?.statusCode === IAPIStatusCode.FORBIDDEN
                ? "save"
                : "check_number"
            }
            defaultMessage={
              checkNumberError?.statusCode === IAPIStatusCode.FORBIDDEN
                ? "Save"
                : "Check Number"
            }
          />{" "}
        </Button>
      )}
      {isScheduleCreateOpen.patientDetail && (
        <Button
          disabled={!patientDetail.isAppointmentBtnEnabled}
          variant={
            !patientDetail.isAppointmentBtnEnabled
              ? IButtonVariant.GREY
              : IButtonVariant.ORANGE
          }
          btnTrigger={handleReserveAppointmentFromDoctor}
        >
          <FormattedMessage id="save" defaultMessage="Save" />
        </Button>
      )}

      {gender.isOpen && (
        <DropDownSelect
          type={IDropDownSelectionType.GENDER}
          isOpen={gender.isOpen}
          closeHandler={() => setGender({ ...gender, isOpen: !gender.isOpen })}
          title="gender"
          values={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          selectedValue={gender.value}
          saveSelectedValues={saveSelectedValues}
        />
      )}
      {patientDetail.changeMemberShow && (
        <DropDownSelect
          type={IDropDownSelectionType.CHANGE_NAME}
          isOpen={patientDetail.changeMemberShow}
          closeHandler={() =>
            setPatientDetail({
              ...patientDetail,
              changeMemberShow: !patientDetail.changeMemberShow,
            })
          }
          title="change_member"
          values={
            fechedPatientDetail?.members &&
            fechedPatientDetail.members.map((member, index) => ({
              label: member.name,
              value: member.id,
            }))
          }
          selectedValue={patientDetail?.memberId}
          saveSelectedValues={saveSelectedValues}
        />
      )}
    </Grid>
  );

  const EnterPatientDetailScreenWeb = () => (
    <Stack justifyContent="center" alignItems="center" p={4} gap={4}>
      <Typography variant="h6">
        <FormattedMessage
          id="book_appointment"
          defaultMessage="Book an appointment"
        />
      </Typography>
      <Grid container gap={0.5} borderBottom={`1px solid #E0E0E0`} p={1}>
        <Grid item xs={12}>
          <Stack direction={"row"} gap={0.5} alignItems={"center"}>
            {isClinicRole ? (
              <>
                <GetImages name="DoctorOrangeIcon" width="20" height="16" />
                <Typography variant={"subtitle2"} color={"gray"}>
                  Dr {doctor_name}
                </Typography>
              </>
            ) : (
              <>
                <GetImages
                  name="ScheduleSelectedSmallIcon"
                  width="16"
                  height="16"
                />
                <Typography component={"p"}>
                  {makePatientAppointmentInfo?.dateSlotTime}
                </Typography>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} gap={0.5} alignItems={"center"}>
            <GetImages
              name={
                makePatientAppointmentInfo?.type ===
                IDoctorAvailabilityPlatform.VIDEO_CALL
                  ? "VideoIcon"
                  : "ClinicSmallIcon"
              }
              width="20"
              height="16"
            />
            <Typography variant={"subtitle2"} color={"gray"}>
              {makePatientAppointmentInfo?.type ===
              IDoctorAvailabilityPlatform.VIDEO_CALL
                ? IDoctorConsultationSelection.VIDEO_CONSULTATION
                : makePatientAppointmentInfo?.clinicName}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={500}>
            <FormattedMessage
              id="enter_patient_contact"
              defaultMessage="Enter Patient Contact"
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            className={style.formPatientInputField}
          >
            <InputField
              type="text"
              inputMode="numeric"
              label={intl.formatMessage({
                id: "mobile_number",
                defaultMessage: "Mobile Number*",
              })}
              value={makePatientAppointmentInfo?.mobileNo}
              onChange={(event) => handleValidateMobileNo(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {notExistPatient && (
                      <Typography
                        component={"p"}
                        className={style.editMobileNumber}
                      >
                        <FormattedMessage id="check" defaultMessage="Check" />
                      </Typography>
                    )}
                    {isScheduleCreateOpen.patientAlreadyExist && (
                      <Typography
                        component={"p"}
                        className={style.editMobileNumber}
                        onClick={editMobileNoPatientExist}
                      >
                        <FormattedMessage id="edit" defaultMessage="edit" />
                      </Typography>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {checkNumberError?.statusCode === IAPIStatusCode.FORBIDDEN && (
              <Grid item>
                <Typography
                  component={"p"}
                  className={style.staffNoAvailService}
                >
                  {checkNumberError?.message}
                </Typography>
              </Grid>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {!isScheduleCreateOpen.mobileCheck && (
            <Grid item>
              <FormControl fullWidth className={style.formPatientInputField}>
                <InputField
                  value={patientDetail.name}
                  onChange={(event) =>
                    setPatientDetail({
                      ...patientDetail,
                      name: event.target.value,
                    })
                  }
                  label={intl.formatMessage({
                    id: "name",
                    defaultMessage: "Name",
                  })}
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "name",
                    defaultMessage: "Name",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {fechedPatientDetail?.members &&
                          fechedPatientDetail?.members?.length > 1 && (
                            <Typography
                              component={"p"}
                              className={style.editMobileNumber}
                              onClick={showListMemberHandler}
                            >
                              <FormattedMessage
                                id="change"
                                defaultMessage="Change"
                              />
                            </Typography>
                          )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl fullWidth className={style.formPatientInputField}>
                <InputField
                  type="number"
                  value={patientDetail.age}
                  onChange={handleAgeChange}
                  label={intl.formatMessage({
                    id: "age",
                    defaultMessage: "Age",
                  })}
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "age",
                    defaultMessage: "Age",
                  })}
                  fullWidth
                />
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                className={style.formPatientInputField}
              >
                <InputField
                  label={intl.formatMessage({
                    id: "gender",
                    defaultMessage: "Gender",
                  })}
                  value={gender.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          component={"p"}
                          className={style.editMobileNumber}
                          onClick={() =>
                            setGender({ ...gender, isOpen: !gender.isOpen })
                          }
                        >
                          <FormattedMessage
                            id="select"
                            defaultMessage="Select"
                          />
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          )}
          {isScheduleCreateOpen.mobileCheck && (
            <Button
              variant={
                makePatientAppointmentInfo?.mobileNo?.length !== 10
                  ? IButtonVariant.GREY
                  : IButtonVariant.ORANGE
              }
              disabled={makePatientAppointmentInfo?.mobileNo?.length !== 10}
              btnTrigger={mobileCheckHandler}
            >
              <FormattedMessage
                id={
                  checkNumberError?.statusCode === IAPIStatusCode.FORBIDDEN
                    ? "save"
                    : "check_number"
                }
                defaultMessage={
                  checkNumberError?.statusCode === IAPIStatusCode.FORBIDDEN
                    ? "Save"
                    : "Check Number"
                }
              />{" "}
            </Button>
          )}
          {isScheduleCreateOpen.patientDetail && (
            <Button
              disabled={!patientDetail.isAppointmentBtnEnabled}
              variant={
                !patientDetail.isAppointmentBtnEnabled
                  ? IButtonVariant.GREY
                  : IButtonVariant.ORANGE
              }
              btnTrigger={handleReserveAppointmentFromDoctor}
            >
              <FormattedMessage id="save" defaultMessage="Save" />
            </Button>
          )}

          {gender.isOpen && (
            <DropDownSelect
              type={IDropDownSelectionType.GENDER}
              isOpen={gender.isOpen}
              closeHandler={() =>
                setGender({ ...gender, isOpen: !gender.isOpen })
              }
              title="gender"
              values={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
              selectedValue={gender.value}
              saveSelectedValues={saveSelectedValues}
            />
          )}

          {patientDetail.changeMemberShow && (
            <DropDownSelect
              type={IDropDownSelectionType.CHANGE_NAME}
              isOpen={patientDetail.changeMemberShow}
              closeHandler={() =>
                setPatientDetail({
                  ...patientDetail,
                  changeMemberShow: !patientDetail.changeMemberShow,
                })
              }
              title="change_member"
              values={
                fechedPatientDetail?.members &&
                fechedPatientDetail.members.map((member, index) => ({
                  label: member.name,
                  value: member.id,
                }))
              }
              selectedValue={patientDetail?.memberId}
              saveSelectedValues={saveSelectedValues}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );

  useEffect(() => {
    const shouldEnableButton =
      patientDetail.name.length > 0 &&
      patientDetail.age.toString().length > 0 &&
      gender.value.length > 0;

    if (patientDetail.isAppointmentBtnEnabled !== shouldEnableButton) {
      setPatientDetail({
        ...patientDetail,
        isAppointmentBtnEnabled: shouldEnableButton,
      });
    }
  }, [patientDetail.name, gender.value, patientDetail.age]);

  useEffect(() => {
    if (
      fechedPatientDetail?.age &&
      fechedPatientDetail?.name &&
      fechedPatientDetail?.gender
    ) {
      setPatientDetail({
        ...patientDetail,
        age: fechedPatientDetail?.age,
        name: fechedPatientDetail?.name,
      });
      setGender({ ...gender, value: fechedPatientDetail?.gender });
    }
  }, [fechedPatientDetail]);

  return (
    <>
      {isMobile || isTablet ? (
        <Modal isOpen={isOpen} closeHandler={closeModalHandler}>
          {EnterPatientDetailScreen()}
        </Modal>
      ) : (
        <MUIDialog
          open={isOpen}
          onClose={closeModalHandler}
          maxWidth="sm"
          fullWidth
        >
          {EnterPatientDetailScreenWeb()}
        </MUIDialog>
      )}
    </>
  );
};

export default EnterPatientDetail;
