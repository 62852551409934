import React, { useEffect, useState } from "react";
import { DialogContent, Grid, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import Modal from "@/components/Modal";

import style from "./index.module.css";

import Select from "@/components/Select";
import Button from "@/components/Button";
import GetImages from "@/components/GetImages";

import { IBed } from "@/models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { fetchHospitalRoomsListing } from "@/store/slices/doctor/ipDashboard";

import { calculateDayFromTargetDate, transformDateToDDMMYYYY } from "@/utils";
import useUpdateAdmitPatient from "@/utils/hooks/useUpdateAdmitPatient";
import useIsMobile from "@/utils/hooks/useIsMobile";
import useIsTablet from "@/utils/hooks/useIsTablet";
import Dialog from "@/components/Dialog";

interface IRoomNumber {
  roomId: string;
  roomBedId: string;
}
interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  closeHandlerParentModal: () => void;
}
const ShiftBed: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler, closeHandlerParentModal } = props;

  const [bedNumber, setBedNumber] = useState<IBed[]>([]);
  const [initial, setInitial] = useState<boolean>(true);
  const { updateAdmitPatientHandler } = useUpdateAdmitPatient();

  const {
    rooms: selectedHospitalRooms,
    admitPatientRooms: rooms,
    patientDetail,
  } = useAppSelector((state) => state.ipDashboard);
  const dispatch = useAppDispatch();
  const { id, name, admission_date, room_bed_id, hospital_room_id } =
    patientDetail;

  const [roomNumber, setRoomNumber] = useState<IRoomNumber>({
    roomId: hospital_room_id || "",
    roomBedId: room_bed_id || "",
  });

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const icuWardSelectionHandler = (roomId: string) => {
    const room = rooms?.allRooms?.filter((room) => room.id === roomId)[0];
    setRoomNumber({
      ...roomNumber,
      roomId: room?.id,
      ...(hospital_room_id?.length !== 0 &&
        room_bed_id?.length !== 0 && { roomBedId: room_bed_id }),
    });
    if (initial) {
      setBedNumber([
        ...(room?.beds || []),
        {
          id: patientDetail?.room_bed_id,
          number: patientDetail?.number,
        },
      ]);
      setInitial(false);
    } else setBedNumber(room?.beds || []);
  };

  const getRoomData = () => {
    let alreadyExist = false;
    let data =
      rooms?.allRooms?.map((room) => {
        if (room.id === hospital_room_id) alreadyExist = true;
        return {
          key: room.name,
          value: room.id,
        };
      }) || [];

    if (!alreadyExist) {
      const currentRoom = selectedHospitalRooms.allRooms.find(
        (room) => room.id === hospital_room_id
      );
      return [
        ...data,
        {
          key: currentRoom?.name || "",
          value: currentRoom?.id || "",
        },
      ];
    }
    return data;
  };
  const roomBedNumberHandler = (roomBedId: string) =>
    setRoomNumber({ ...roomNumber, roomBedId });

  const shiftBedHandler = () => {
    updateAdmitPatientHandler({
      id,
      hospital_room_id: roomNumber?.roomId,
      room_bed_id: roomNumber?.roomBedId,
    });
    closeHandler();
    closeHandlerParentModal();
  };

  const content = () => {
    return (
      <Grid item className={style.container}>
        <Grid item className={style.header} onClick={closeHandler}>
          <ArrowBackIosNewIcon fontSize={"inherit"} />{" "}
          <Typography component={"p"}>Shift Bed</Typography>
        </Grid>
        <Grid item className={style.patientDetail}>
          <Grid item>
            <Typography component={"p"} className={style.name}>
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component={"p"} className={style.admittedDateDuration}>
              <GetImages name="GreenDotIcon" width="8" height="6" />
              {transformDateToDDMMYYYY(admission_date)}(
              {calculateDayFromTargetDate(admission_date)})
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={style.icuWardContainer}>
          <Grid item>
            <Select
              value={roomNumber?.roomId}
              label="ICU/ Ward"
              data={getRoomData()}
              receivedSelectedValue={icuWardSelectionHandler}
            />
          </Grid>
          <Grid item className={style.changeBed}>
            <Select
              value={roomNumber?.roomBedId}
              label="Bed Number"
              data={bedNumber?.map((bed, index) => ({
                key: `Bed ${bed.number}`,
                value: bed.id,
              }))}
              receivedSelectedValue={roomBedNumberHandler}
            />
          </Grid>
        </Grid>
        <Button btnTrigger={shiftBedHandler}>Save</Button>
      </Grid>
    );
  };
  useEffect(() => {
    if (patientDetail.hospital_id) {
      dispatch(
        fetchHospitalRoomsListing({
          hospitalId: patientDetail.hospital_id,
          isAdmitPatient: true,
          available: true,
        })
      ).finally(() => {
        if (roomNumber?.roomId?.length !== 0) {
          icuWardSelectionHandler(roomNumber.roomId);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   if (roomNumber?.roomId?.length !== 0) {
  //     icuWardSelectionHandler(roomNumber.roomId);
  //   }
  // }, [rooms]);

  if (isMobile || isTablet) {
    return (
      <Modal isOpen={isOpen} closeHandler={closeHandler}>
        {content()}
      </Modal>
    );
  }

  return (
    <Dialog maxWidth="sm" fullWidth closeHandler={closeHandler}>
      <DialogContent>{content()}</DialogContent>
    </Dialog>
  );
};

export default ShiftBed;
