export const styles = {
  notification: {
    position: "fixed" as const,
    top: "2rem",
    right: "2rem",
    backgroundColor: "#323232",
    color: "var(--neutral-100)",
    padding: "1.5rem",
    borderRadius: "0.8rem",
    boxShadow: "0 0.4rem 0rgba(22, 10, 10, 0.1), 0.1)",
    zIndex: 1000,
    animation: "slide-in 0.3s ease-out",
    display: "flex",
    alignItems: "flex-start",
  },
  notificationContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& h4": {
      fontWeight: 600,
      fontSize: "1.6rem",
    },
    "& p": {
      fontWeight: 400,
      fontSize: "1.4rem",
    },
  },
  notificationClose: {
    marginLeft: "3.5rem",
    borderRadius: "50%",
    width: "2.5rem",
    height: "2.5rem",
    background: "var(--neutral-100)",
    border: "none",
    color: "var(--black-900)",
    fontSize: "2rem",
    cursor: "pointer",
  },
  slideInKeyframes: `
      @keyframes slide-in {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
      }
    `,
};
