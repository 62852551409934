import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  IHealthAssesmentQuestionDetail,
  IHealthAssessmentDetail,
  IHealthAssessmentQuestionSubmission,
  IHealthAssessmentQuestionSubmissionRequest,
} from "../../../models/ApiRequestResponse/patient";

import {
  getHealthAssessmentListApi,
  getHealthAssessmentQuestionApi,
  postHealthAssessmentQuestionSubmissionApi,
} from "../../../utils/apis/patient/healthAssessment";

import { RootState } from "../../store";

export const fetchAssessmentHealthList = createAsyncThunk(
  "patient/health-assessment",
  async ({ type }: { type: string }) => {
    const response = await getHealthAssessmentListApi(type);
    return response;
  }
);

export const fetchAssessmentHealthQuestionList = createAsyncThunk(
  "patient/health-assessment-question",
  async ({ healthAssessmentId }: { healthAssessmentId: string }) => {
    const response = await getHealthAssessmentQuestionApi(healthAssessmentId);
    return response;
  }
);

export const postAssessmentHealthQuestionResult = createAsyncThunk(
  "patient/health-assessment-submission",
  async (requestBody: IHealthAssessmentQuestionSubmissionRequest) => {
    const response = await postHealthAssessmentQuestionSubmissionApi(
      requestBody
    );
    return response;
  }
);

const initialState = {
  healthAssessmentDetail: {} as IHealthAssessmentDetail,
  healthAssessmentQuestion: {} as IHealthAssesmentQuestionDetail,
  healthAssessmentQuestionSubmission: {} as IHealthAssessmentQuestionSubmission,
  showSubmitButton: false as boolean,
  isAssessmentStarted: false as boolean,
  currentAssessment: "" as string,
  isAssessmentCompleted: false as boolean,
  score: 0 as number,
  questionIndex: 1 as number,
  isHealthAssessmentLoading: false as boolean,
  isAssessmentSubmittedLoading: false as boolean,
  isLoading: true as boolean,
};
const fetchHealthAssessmentSlice = createSlice({
  name: "fetchHealthAssessment",
  initialState,
  reducers: {
    setIsAssessmentStarted: (state, action) => {
      state.isAssessmentStarted = action.payload;
    },
    setCurrentAssessment: (state, action) => {
      state.currentAssessment = action.payload;
    },
    setIsAssessmentCompleted: (state) => {
      state.isAssessmentCompleted = true;
    },
    setShowSubmitButton: (state) => {
      state.showSubmitButton = true;
    },
    resetAssessmentState: (state) => {
      state.questionIndex = 1;
      state.score = 0;
      state.showSubmitButton = false;
      state.isAssessmentStarted = false;
      state.isAssessmentCompleted = false;
    },
    setQuestionIndex: (state) => {
      state.questionIndex += 1;
      if (
        state.healthAssessmentQuestion?.questions?.length ===
        state.questionIndex
      ) {
        state.showSubmitButton = true;
      }
    },
    setAddScore: (state, action) => {
      state.score += action.payload;
    },
    setSubtractScore: (state, action) => {
      state.score -= action.payload;
    },
    resetHealthAssessmentState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Health Assessment List
      .addCase(fetchAssessmentHealthList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAssessmentHealthList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.healthAssessmentDetail = action.payload.result;
        }
      })
      .addCase(fetchAssessmentHealthList.rejected, (state) => {
        state.isLoading = false;
      })
      // Health Assessment Question List
      .addCase(fetchAssessmentHealthQuestionList.pending, (state) => {
        state.isHealthAssessmentLoading = true;
      })
      .addCase(fetchAssessmentHealthQuestionList.fulfilled, (state, action) => {
        state.isHealthAssessmentLoading = false;
        if ("result" in action.payload) {
          state.healthAssessmentQuestion = action.payload.result;
        }
      })
      .addCase(fetchAssessmentHealthQuestionList.rejected, (state) => {
        state.isHealthAssessmentLoading = false;
      })
      // Health Assessment Question Submission
      .addCase(postAssessmentHealthQuestionResult.pending, (state) => {
        state.isAssessmentSubmittedLoading = true;
      })
      .addCase(
        postAssessmentHealthQuestionResult.fulfilled,
        (state, action) => {
          state.isAssessmentSubmittedLoading = false;
          if ("result" in action.payload) {
            state.healthAssessmentQuestionSubmission = action.payload.result;
          }
        }
      )
      .addCase(postAssessmentHealthQuestionResult.rejected, (state) => {
        state.isAssessmentSubmittedLoading = false;
      });
  },
});
const selectHealthAssessment = (state: RootState) => state.healthAssessment;

export const healthAssessmentDetails = createSelector(
  [selectHealthAssessment],
  (selectHealthAssessment) => selectHealthAssessment.healthAssessmentDetail
);

export const healthAssessmentLoader = createSelector(
  [selectHealthAssessment],
  (selectHealthAssessment) => selectHealthAssessment.isLoading
);

export const {
  setIsAssessmentStarted,
  setQuestionIndex,
  setAddScore,
  setSubtractScore,
  resetAssessmentState,
  setIsAssessmentCompleted,
  setShowSubmitButton,
  setCurrentAssessment,
  resetHealthAssessmentState,
} = fetchHealthAssessmentSlice.actions;
export default fetchHealthAssessmentSlice.reducer;
