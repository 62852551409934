import React from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import Button from "@/components/Button";

import style from "./index.module.css";

import { IButtonVariant } from "@/models/button";

interface IProps {
  title: string;
  desc: string;
}
const Banner: React.FC<IProps> = (props) => {
  const { title, desc } = props;
  return (
    <Grid item className={style.bannerContainer}>
      <Grid item className={style.bannerItem}>
        <Typography component={"h4"} className={style.bannerTitle}>
          {title}
        </Typography>
        <Typography component={"p"} className={style.bannerDesc}>
          {desc}
        </Typography>
        <Button variant={IButtonVariant.ORANGE_ROUNDED}>
          <FormattedMessage id="reach_out" defaultMessage="Reach out" />
        </Button>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
};

export default Banner;
