import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

import { IDocumentTypeEnum } from "../../models/doctor";

import useFirebaseMessaging from "../../utils/hooks/useFirebaseMessaging";
import { uploadFileToS3Api } from "../../utils/apis/doctor/prescription";
import { CHAT_LIST_TYPE } from "@/models/chat";

// Create Context
const FirebaseMessagingContext = createContext<any>(null);

// Provider Component
export const FirebaseMessagingProvider = ({ children }: PropsWithChildren) => {
  const {
    listType,
    channelList,
    handleChannel,
    messages,
    patientChatMediaList,
    channelId,
    handleSendMessage,
    markMessageAsRead,
    markAllMessagesAsRead,
    handleList,
    removeMemberFromChat,
    getConversationData,
  } = useFirebaseMessaging({
    chatListType: CHAT_LIST_TYPE.PATIENT,
  });

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleFileUpload = async (formData: FormData) => {
    setIsUploading(true);
    try {
      const uploadFileToS3Response = await uploadFileToS3Api(
        IDocumentTypeEnum.CHAT,
        formData
      );
      if ("result" in uploadFileToS3Response) {
        const { thumbnailUrl, url } = uploadFileToS3Response.result;
        return { thumbnail: thumbnailUrl, url };
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <FirebaseMessagingContext.Provider
      value={{
        messages,
        patientChatMediaList,
        channelList,
        isUploading,
        listType,
        channelId,
        handleFileUpload,
        handleChannel,
        handleSendMessage,
        markMessageAsRead,
        markAllMessagesAsRead,
        handleList,
        removeMemberFromChat,
        getConversationData,
      }}
    >
      {children}
    </FirebaseMessagingContext.Provider>
  );
};

// Custom Hook for Consuming Context
export const useFirebaseMessagingContext = () => {
  const context = useContext(FirebaseMessagingContext);
  if (!context) {
    throw new Error(
      "useFirebaseMessagingContext must be used within a FirebaseMessagingProvider"
    );
  }
  return context;
};
