import Image from "@/assets/images.svg";

interface IProps {
  name: string;
  height?: string;
  width?: string;
  fill?: string;
}

const GetImages: React.FC<IProps> = ({ name, height, width, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
    >
      <use href={Image + `#${name}`} />
    </svg>
  );
};

export default GetImages;
