import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { IHealthHubDiseasesResult } from "../../../../models/ApiRequestResponse/patient";

import SectionHeader from "../SectionHeader";
import DiseaseCard from "./DiseaseCard";
import SectionContainer from "../SectionContainer";

import style from "./index.module.css";

type DiseaseListSectionProps = {
  data: IHealthHubDiseasesResult;
};

const DiseaseListSection: React.FC<DiseaseListSectionProps> = ({ data }) => {
  const navigate = useNavigate();

  const handleDiseaseClick = (diseaseId: string) => {
    navigate("/health-hub/disease", {
      state: {
        diseaseId,
      },
    });
  };

  return (
    <SectionContainer>
      <Grid
        className={style.container}
        sx={{
          padding: { xs: "0 0.5rem", sm: "0.2rem 0", md: "1.2rem 0 0 0" },
          // ml: { xs: "1rem", sm: "4rem", md: "11rem" },
          maxWidth: { xs: "95%", sm: "100%" },
        }}
      >
        <Grid>
          <SectionHeader title={data.name} />
        </Grid>
        <Grid className={style.diseaseContainer}>
          {data.data.map((disease) => (
            <Grid
              className={style.diseaseCard}
              key={disease.id}
              onClick={() => handleDiseaseClick(disease.id)}
            >
              <DiseaseCard {...disease} imageUrl={disease.icon_url} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default DiseaseListSection;
